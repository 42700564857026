<template>
  <div class="note-taker-wrapper">
    <MessageScreen v-show="loading || errorLoading">
      <div class="loading-screen" v-if="errorLoading">
        <span>{{ errorLoadingMessage }}</span>
        <RoundedButton
          text="Go back"
          shadow
          @click="goToSessionBrowser"
          class="bottom"
        />
      </div>
      <div class="loading-screen" v-else>
        <span>Loading...</span>
        <ProgressSpinner class="spinner bottom" />
      </div>
    </MessageScreen>
    <div v-show="!loading && !errorLoading" class="note-taker panels-wrapper">
      <div
        class="left-wrapper"
        style="outline: none"
        @keydown="handleKeypress"
        tabindex="0"
      >
        <InfoPanel
          class="note-taking-info-panel"
          :isOpen="infoPanelOpen"
          :toggleInfoPanelOpen="toggleInfoPanelOpen"
          :goToSessionBrowser="goToSessionBrowser"
          :pageTitle="mediaName"
          :data="formatedInfoPanelSessionData"
          :editTitleData="{
            sessionId: this.$store.state.sessionDetails.id,
            sessionTitle: this.mediaName,
          }"
          :bookmarkList="bookmarkList"
          :pasteToNote="pasteToNote"
          @updateTitle="updateTitle"
          @removeBookmark="removeBookmark"
          @changeBookmarkColor="changeBookmarkColor"
          @updateCurrentTime="
            (val) => {
              if (this.audioComponent) {
                this.audioComponent.currentTime = val;
                this.audioComponent.play();
              }
              if (this.videoComponent) {
                this.videoComponent.currentTime = val;
                this.videoComponent.play();
              }
            }
          "
        ></InfoPanel>
      </div>
      <div class="content-panel" id="noteTakerContent">
        <div
          class="top-bar"
          style="outline: none"
          @keydown="handleKeypress"
          tabindex="0"
        >
          <div
            class="left-section"
            :class="infoPanelOpen && !isDSA ? 'side-panel-open' : ''"
          >
            <div
              class="session-name-wrapper"
              id="sessionNameWrapper"
              v-if="isDSA"
            >
              <span class="session-name"> {{ sessionCurrentName }}</span>
              <IconButton
                :icon="ChevronLeftWhiteIcon"
                alt=""
                xsmall
                class="session-name-chevron-button"
                v-tooltip.top="'Modify session name'"
                @click="toggleModifySessionNameMenu"
                aria-haspopup="true"
              />
              <OverlayPanel
                ref="sessionNameMenu"
                class="change-session-name-menu"
              >
                <div class="session-name-field">
                  <label for="sessionName">Session Name</label>
                  <InputText
                    id="sessionName"
                    type="text"
                    aria-describedby="session-name-help"
                    v-model="sessionPreviewName"
                  />
                </div>
                <div class="button-wrapper">
                  <RoundedButton
                    text="Cancel"
                    @click="cancelSessionName"
                    mediumwide
                  />
                  <RoundedButton
                    text="Save"
                    @click="saveSessionName"
                    :disabled="!validSessionName"
                    accent
                    mediumwide
                  />
                </div>
              </OverlayPanel>
            </div>
          </div>
          <PDFViewerControlBar
            v-if="pdfSession"
            :numPages="pdfViewerNumPages"
            :currentPageNumber="pdfViewerCurrentPage"
            :switchToPdfPage="switchToPdfPage"
            :overviewPanelVisible="pdfOverviewPanelVisible"
            :setOverviewPanelVisible="setPdfOverviewPanelVisible"
          />
          <AudioControlBar
            v-else-if="!pdfSession && audioComponent && !isDSA && !videoSession"
            :audioComponent="audioComponent"
            class="audio-control-bar"
            :transcriptAudioLength="
              this.audioComponent
                ? this.audioComponent.duration
                : this.videoComponent.duration
            "
            :audioVisualization="audioVisualization"
            :audioPaused="audioPaused"
            :audioVolume="audioVolume"
            :setPlaybackSpeed="setPlaybackSpeed"
            :playbackSpeed="playbackSpeed"
            :insertCurrentTimestamp="insertCurrentTimestamp"
            :mediaName="this.mediaName"
            :AUDIO_SKIP_AMOUNT="AUDIO_SKIP_AMOUNT_MEDIUM"
            :isInfoPanelOpen="infoPanelOpen"
            :addBookmark="insertBookmark"
            @updateVolume="
              (val) => {
                this.audioComponent.volume = val;
                this.audioVolume = val;
              }
            "
            @updateCurrentTime="
              (val) => {
                this.audioComponent.currentTime = val;
              }
            "
            @updateMuted="
              (val) => {
                this.audioComponent.muted = val;
              }
            "
            @updateShowAudioVisualization="
              (val) => {
                this.audioVisualization = val;
              }
            "
            @toggleAudioVisualization="addBookmarkListAudioVisualizer"
          />
          <MediaControlBar
            v-if="!pdfSession && !isDSA && (videoReady || videoComponent)"
            :mediaComponent="audioComponent || videoComponent"
            class="audio-control-bar"
            :mediaType="this.audioComponent ? 'audio' : 'video'"
            :audioVisualization="audioVisualization"
            :mediaPaused="audioPaused"
            :insertCurrentTimestamp="insertCurrentTimestamp"
            :mediaName="mediaName"
            :AUDIO_SKIP_AMOUNT="AUDIO_SKIP_AMOUNT_MEDIUM"
            :isInfoPanelOpen="infoPanelOpen"
            :addBookmark="insertBookmark"
            @updateCurrentTime="
              (val) => {
                if (this.audioComponent) {
                  this.audioComponent.currentTime = val;
                }
              }
            "
            @updateShowAudioVisualization="
              (val) => {
                this.audioVisualization = val;
              }
            "
            @toggleAudioVisualization="addBookmarkListAudioVisualizer"
          />
          <div class="right-section">
            <div class="button-with-dropdown">
              <IconButton
                v-if="!pdfSession"
                :icon="PanelsWhiteIcon"
                alt="Panels"
                xsmall
                class="text-formatting-button"
                v-tooltip.tocp="'Change Layout'"
              />
              <div class="dropdown-menu right">
                <button
                  class="dropdown-menu-button"
                  @click="
                    () => {
                      this.openTranscriptPanel();
                      this.openNotepadPanel();
                    }
                  "
                >
                  Transcript + Notes
                </button>
                <button
                  class="dropdown-menu-button"
                  @click="
                    () => {
                      this.openTranscriptPanel();
                      this.closeNotepadPanel();
                    }
                  "
                >
                  Transcript Only
                </button>
                <button
                  class="dropdown-menu-button"
                  @click="
                    () => {
                      this.closeTranscriptPanel();
                      this.openNotepadPanel();
                    }
                  "
                >
                  Notes Only
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="audio-visualization-bar"
          :class="audioVisualization ? 'show' : ''"
          style="outline: none"
          @keydown="handleKeypress"
          tabindex="0"
        >
          <AudioVisualizer
            v-if="audioComponent || videoComponent"
            :audioComponent="audioComponent || videoComponent"
            ref="audioVisualizer"
            @playbackAt="playbackAt"
          />
        </div>
        <div class="main">
          <PDFOverviewPanel
            class="pdf-overview-panel"
            v-if="pdfSession"
            :visible="pdfOverviewPanelVisible"
            :currentPageNumber="pdfViewerCurrentPage"
            :numPages="pdfViewerNumPages"
            :switchToPdfPage="switchToPdfPage"
            :pdfData="pdfData"
            @hide="this.pdfOverviewPanelVisible = false"
          />
          <div class="editors">
            <div
              v-if="pdfSession"
              class="pdf-viewer-panel panel note-taker-panel note-taker-editor-wrapper"
              ref="pdfPanel"
              :class="[{ collapsed: !isTranscriptOpen }]"
            >
              <div
                class="editor-type-header"
                style="outline: none"
                @keydown="handleKeypress"
                tabindex="0"
              >
                <span class="editor-type-header-text">
                  <img :src="TranscriptWhiteIcon" />
                  Document
                </span>
              </div>
              <div class="pdf-viewer">
                <vue-pdf-embed
                  ref="pdfViewer"
                  :source="pdfData"
                  :page="pdfViewerCurrentPage"
                  :textLayer="true"
                  @rendered="handlePdfRender"
                  :style="'scale:' + pdfViewerScale"
                />
              </div>
            </div>

            <div
              v-else
              class="note-taker-editor-wrapper note-taker-panel panel"
              :class="[{ collapsed: !isTranscriptOpen }]"
            >
              <portal-target name="above-transcript"> </portal-target>
              <div
                class="editor-type-header"
                style="outline: none"
                @keydown="handleKeypress"
                tabindex="0"
              >
                <div class="download-button-wrapper">
                  <span class="editor-type-header-text">
                    <img :src="TranscriptWhiteIcon" />
                    Transcript
                  </span>
                  <IconButton
                    :icon="DownloadGreyIcon"
                    alt="Download"
                    @click="saveToComputer(PANEL_TYPES.TRANSCRIPT)"
                    xxxsmall
                    class="download-button2"
                  />
                </div>
              </div>
              <AbstractSummary
                :class="[{ collapsed: !isTranscriptOpen }]"
                :abstractText="anthillSummary"
                v-if="currentUserSubscriptionDetails.isActive"
              />
              <TranscriptEditor
                v-if="savedTranscript"
                class="text-editor"
                :savedTranscript="savedTranscript"
                :class="[{ collapsed: !isTranscriptOpen }]"
                :id="this.$store.state.sessionDetails.id + '-transcript'"
                :handleKeypress="handleKeypress"
                :transcriptEditor="transcriptEditor"
                :setTranscriptEditor="setTranscriptEditor"
                :fontColour="fontColourTranscript"
                :fontSize="fontSizeTranscript"
                :fontBold="fontBoldTranscript"
                :fontFamily="fontFamilyTranscript"
                :lineHeight="lineHeightTranscript"
                :letterSpacing="letterSpacingTranscript"
                ref="transcriptEditorRef"
              />
              <BubbleMenu
                :editor="transcriptEditor"
                v-if="transcriptEditor"
                class="bubble-menu-popup"
                :should-show="shouldShow()"
              >
                <div class="color-buttons">
                  <button
                    class="color-button"
                    :style="{
                      backgroundColor: '#FFD362',
                    }"
                    @click="colourButtonClicked('#FFD362')"
                  />
                  <button
                    class="color-button"
                    :style="{
                      backgroundColor: '#FFA69A',
                    }"
                    @click="colourButtonClicked('#FFA69A')"
                  />
                  <button
                    class="color-button"
                    :style="{
                      backgroundColor: '#79DCFF',
                    }"
                    @click="colourButtonClicked('#79DCFF')"
                  />
                  <button
                    class="color-button"
                    :style="{
                      backgroundColor: '#02FADA',
                    }"
                    @click="colourButtonClicked('#02FADA')"
                  />
                  <button
                    class="color-button"
                    :style="{
                      backgroundColor: '#E993FF',
                    }"
                    @click="colourButtonClicked('#E993FF')"
                  />
                </div>
                <div class="table-menu-popup-buttons">
                  <button class="table-menu-popup-button" @click="copyText">
                    Copy
                    <img :src="CopyIcon" alt="icon" />
                  </button>
                  <button class="table-menu-popup-button" @click="addToNotes">
                    Add to Notes
                    <img :src="AddToNotesIcon" alt="icon" />
                  </button>
                  <button
                    class="table-menu-popup-button"
                    @click="deleteBookmark"
                  >
                    Delete
                    <img :src="TrashIcon" alt="icon" />
                  </button>
                </div>
              </BubbleMenu>
            </div>
            <div
              class="note-taker-editor-wrapper note-editor-wrapper note-taker-panel panel"
              :class="[{ collapsed: !isNotepadOpen }]"
            >
              <portal-target name="above-personal-note"> </portal-target>
              <div v-if="!isWorker" class="editor-type-header tabs">
                <div class="note-editor-selection-buttons" v-if="!pdfSession">
                  <button
                    class="editor-type-header-text clickable"
                    :class="{ 'active-editor-button': showProNotes }"
                    @click="showProNotes = true"
                  >
                    <img :src="NotesWhiteIcon" />
                    Requested Notes
                  </button>

                  <button
                    class="editor-type-header-text clickable"
                    :class="{
                      'active-editor-button': !showProNotes,
                    }"
                    @click="showProNotes = false"
                  >
                    <img :src="PencilWhiteIcon" />
                    Personal Notes
                  </button>
                </div>
                <div
                  v-else
                  class="editor-type-header"
                  style="outline: none"
                  @keydown="handleKeypress"
                  tabindex="0"
                >
                  <span class="editor-type-header-text">
                    <img :src="PencilWhiteIcon" />
                    Personal Notes
                  </span>
                </div>
              </div>
              <div
                class="text-formatting-bar-wrapper"
                v-if="!showProNotes || canShowProNote"
                :class="{
                  'disable-and-blur': disablePersonalNote && !showProNotes,
                }"
              >
                <TextFormattingBar
                  :editor="noteEditor"
                  :zoomFactor="zoomFactorNotes"
                  :setZoomFactor="setNotesZoomFactor"
                  :addImage="addNotesImage"
                  :showEmoji="showNotesEmoji"
                  :highlightedFontSize="highlightedFontSize"
                  :setHighlightedFontSize="setHighlightedFontSize"
                  :disabledAll="showProNotes"
                />
                <IconButton
                  :icon="DownloadGreyIcon"
                  alt="Download"
                  @click="saveToComputer(PANEL_TYPES.NOTES)"
                  xxxsmall
                  class="download-button"
                />
              </div>
              <NoteEditor
                v-if="
                  (audioComponent || pdfSession || videoComponent) &&
                  !showProNotes
                "
                ref="personalNote"
                class="text-editor note-editor"
                :class="[{ collapsed: !isNotepadOpen }]"
                :id="this.$store.state.sessionDetails.id"
                :noteEditor="noteEditor"
                :setNoteEditor="setNoteEditor"
                :audioComponent="audioComponent"
                :insertCurrentTimestamp="insertCurrentTimestamp"
                :editable="true"
                :zoomFactor="zoomFactorNotes"
                :isPersonalNote="true"
                :disablePersonalNote="disablePersonalNote"
                :showDisablePersonalNoteDialog="disablePersonalNote && this.videoElementDestination !== 'above-personal-note'"
                :centeredDisabledDialog="true"
                :setSubscribeVisible="setShowSubscriptionSignupDialog"
              />
              <ProNoteRequestStatus
                v-if="
                  (audioComponent || pdfSession || videoComponent) &&
                  showProNotes &&
                  !canShowProNote
                "
                :openRequestNoteForm="() => (orderFormVisible = true)"
              />
              <NoteEditor
                v-if="
                  (audioComponent || pdfSession || videoComponent) &&
                  showProNotes &&
                  canShowProNote
                "
                class="text-editor note-editor"
                :class="[{ collapsed: !isNotepadOpen }]"
                :id="this.$store.state.sessionDetails.id"
                :noteEditor="proNoteEditor"
                :setNoteEditor="setProNoteEditor"
                :audioComponent="audioComponent"
                :insertCurrentTimestamp="insertCurrentTimestamp"
                :editable="false"
                :zoomFactor="zoomFactorNotes"
                :showProNoteAlert="showProNoteAlert"
                @closeProNoteAlert="closeProNoteAlert()"
              />
            </div>

            <div
              class="study-assistant-wrapper study-assistant-panel panel note-taker-editor-wrapper"
              :class="[{ collapsed: !isStudyAssistantOpen }]"
              v-if="!isServiceProvider && !pdfSession"
            >
              <div
                class="editor-type-header"
                style="outline: none"
                @keydown="handleKeypress"
                tabindex="0"
              >
                <span class="editor-type-header-text"> Study Assistant </span>
              </div>
              <StudyAssistant
                :questionsAnswersList="studyAssistantQuestionsAnswersList"
                :pushToQuestionsAnswersList="
                  pushToStudyAssistantQuestionsAnswersList
                "
                :quizList="anthillQuizList"
                :summarizeList="anthillSummarizeList"
                :keywordsList="anthillKeywordsList"
                :recommendationsList="anthillRecommendationsList"
                :pasteToNote="pasteToNote"
                :tipsString="anthillTips"
                :class="[{ collapsed: !isStudyAssistantOpen }]"
              />
            </div>
          </div>
        </div>
        <div class="bottom-bar">
          <IconButton
            v-if="!pdfSession"
            :icon="MagicWandLight"
            alt="Toggle Study Assistant"
            @click="
              () => {
                this.toggleStudyAssistantPanel();
              }
            "
            big
            class="toggle-study-assistant-button"
          />
          <RoundedSquareButton
            v-if="false"
            :image="FeedbackBubblePurpleIcon"
            imageAlt="Provide feedback"
            disabled
            :onClick="() => {}"
            tall
            v-tooltip.top="'Provide feedback'"
          />
        </div>
      </div>
    </div>
    <Dialog
      class="coming-soon-dialog"
      :header="false"
      :closable="false"
      closeOnEscape
      v-model:visible="displayComingSoonDialog"
      :modal="true"
    >
      <div class="coming-soon-dialog-iconwrapper">
        <IconButton
          :icon="CloseGreyIcon"
          class="close-button"
          alt="Close"
          :onclick="
            () => {
              this.displayComingSoonDialog = false;
            }
          "
          xsmall
        />
      </div>
      <div class="coming-soon-dialog-header">Coming soon!</div>
      <div class="coming-soon-dialog-body">
        This feature is still in the works.<br />
        Thank you for your patience.
      </div>
      <div class="coming-soon-dialog-buttonwrapper">
        <Button
          :onclick="() => (this.displayComingSoonDialog = false)"
          :size="large"
          label="OK"
          text
          rounded
          class="coming-soon-dialog-buttonwrapper-button"
        />
      </div>
    </Dialog>
    <SubscriptionSignup
      :visible="showSubscriptionSignupDialog"
      :setVisible="setShowSubscriptionSignupDialog"
    />
  </div>
  <Toast
    position="bottom-center"
    :style="{ marginBottom: '10rem' }"
    group="bc"
    :closeButton="null"
  >
    <template #message="slotProps">
      <img :src="BookmarkFilledIcon" alt="BookmarkFilled" />
      <div>
        {{ slotProps.message.summary }}
      </div>
    </template>
  </Toast>
  <RequestNotesOrderForm
    :visible="orderFormVisible"
    v-if="!isWorker"
    :recordingTitle="$store.state.sessionDetails.title"
    :durationHMS="
      convertMillisecondsToHMS($store.state.sessionDetails.mediaUploadData.duration)
    "
    :durationMilliseconds="
      $store.state.sessionDetails.mediaUploadData.duration?.toString()
    "
    :recordingUploadId="$store.state.sessionDetails.mediaUploadData.id"
    :setOrderFormVisible="setOrderFormVisible"
    @updateStandbySessionsMenuItem="updateStandbySessionsMenuItem()"
    ref="orderForm"
    @updateSessionList="updateProNoteRequest"
  ></RequestNotesOrderForm>
  <portal :to="videoElementDestination">
    <Transition name="slide">
      <div
        class="note-taker-video-wrapper"
        id="video-wrapper"
        :class="{ 'note-taker-video-collapse': toggleVideoTile }"
        v-if="videoSession"
      >
        <video-player
          ref="videoPlayer"
          id="video"
          class="note-taker-video"
          :src="videoUrl"
          :controls="false"
          :loop="false"
          :volume="0.6"
          @enterpictureinpicture="setToggleVideoTile(true)"
          @leavepictureinpicture="setToggleVideoTile(false)"
          @mounted="onMountedVideoPlayer"
          @unmounted="onUnmountedVideoPlayer"
        />
        <IconButton
          v-if="videoComponent && videoComponent.requestPictureInPicture"
          class="pip-mode-button"
          :class="{ 'note-taker-video-collapse': toggleVideoTile }"
          alt="Minimize - Enter Picture-In-Picture Mode"
          @click="setPIPMode"
          :icon="MinimizeIcon"
          small
        />
        <IconButton
          class="pip-mode-button position-toggle-button"
          :class="{ 'note-taker-video-collapse': toggleVideoTile }"
          @click="toggleVideoPosition"
          alt="Change Video Tile Position"
          :icon="
            this.videoElementDestination === 'above-transcript'
              ? PositionTopRightIcon
              : PositionTopLeftIcon
          "
          small
        />
      </div>
    </Transition>
  </portal>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
import TranscriptEditor from "../components/NoteTaking/TranscriptEditor.vue";
import NoteEditor from "../components/NoteTaking/NoteEditor.vue";
import IconButton from "../components/IconButton.vue";
import Session from "../components/Helpers/Session";
import AudioControlBar from "../components/NoteTaking/AudioControlBar";
import MediaControlBar from "../components/NoteTaking/MediaControlBar";
import RoundedSquareButton from "../components/RoundedSquareButton";
import BackArrowIcon from "../assets/images/BackArrow.svg";
import BookmarkIcon from "../assets/images/Bookmark.svg";
import ChevronLeftWhiteIcon from "../assets/images/Chevron-left-white.svg";
import PanelsWhiteIcon from "../assets/images/Panels-white.svg";
import ExportUploadWhiteIcon from "../assets/images/Export-upload-white.svg";
import LightbulbPurpleIcon from "../assets/images/Lightbulb-purple.svg";
import AudioVisualizationExample from "../assets/images/AudioVisualizationExample.svg";
import ArrowRightBlackIcon from "../assets/images/Arrow-right-black.svg";
import DownloadGreyIcon from "../assets/images/Download-grey.svg";
import MagicWandLight from "../assets/images/Magic-wand-light.svg";
import FeedbackBubblePurpleIcon from "../assets/images/Feedback-bubble-purple.svg";
import TranscriptWhiteIcon from "../assets/images/Transcript-white.svg";
import NotesWhiteIcon from "../assets/images/Notes-white.svg";
import PencilWhiteIcon from "../assets/images/Pencil-white.svg";
import MessageScreen from "../components/MessageScreen.vue";
import RoundedButton from "../components/RoundedButton";
import TextFormattingBar from "../components/NoteTaking/TextFormattingBar";
import StudyAssistant from "../components/NoteTaking/StudyAssistant";
import RequestNotesOrderForm from "../components/Orders/RequestNotesOrderForm";
import {
  ACCOUNT_TYPES,
  ACCOUNT_TEAMS,
  dbConstants,
  editorConstants,
  STUDY_ASSISTANT_PROMPT_TYPES,
  NOTES_PANEL_DEFAULTS,
  HIGHLIGHT_COLOR_MAP,
  ORDER_STATUS,
} from "../assets/constants";
import Averta from "../assets/fonts/Averta-Regular.otf";
import {
  convertDateToHoursMinutes,
  convertDateToMonthDayYear,
  convertMillisecondsToHMS,
  convertSecondsToMS,
  formatDate,
  getCurrentUserSubscriptionDetails,
  getTimeCodeFromNum,
} from "../assets/helpers_legacy";
import { makeMPServerRequest } from "../assets/helpers";
import AWS from "aws-sdk";
import axios from "axios";
import { BubbleMenu } from "@tiptap/vue-3";
import { TextSelection } from "prosemirror-state";
import { ref } from "vue";
import CloseGreyIcon from "../assets/images/Close-grey.svg";
import PDFViewerControlBar from "../components/NoteTaking/PDFViewerControlBar";
import PDFOverviewPanel from "../components/NoteTaking/PDFOverviewPanel";
import VuePdfEmbed from "vue-pdf-embed";
import _ from "lodash";
import { downloadFile } from "../assets/downloadFile.js";
import SubscriptionSignup from "../components/Onboarding/SubscriptionSignup.vue";
import ProNoteRequestStatus from "../components/NoteTaking/ProNoteRequestStatus.vue";
import AudioVisualizer from "../components/NoteTaking/AudioVisualizer.vue";
import TrashIcon from "../assets/images/Trash-white.svg";
import CopyIcon from "../assets/images/Copy-white.svg";
import AddToNotesIcon from "../assets/images/AddToNotes-white.svg";
import BookmarkFilledIcon from "../assets/images/BookmarkFilled.svg";
import PositionTopRightIcon from "../assets/images/PositionTopRight.svg";
import PositionTopLeftIcon from "../assets/images/PositionTopLeft.svg";
import MinimizeIcon from "../assets/images/Reduce.svg";
import { VideoPlayer } from "@videojs-player/vue";
import AbstractSummary from "../components/NoteTaking/AbstractSummary";
import "video.js/dist/video-js.css";

export default {
  extends: Session,

  components: {
    InfoPanel,
    TranscriptEditor,
    NoteEditor,
    AudioControlBar,
    MediaControlBar,
    IconButton,
    MessageScreen,
    RoundedButton,
    TextFormattingBar,
    StudyAssistant,
    BubbleMenu,
    RoundedSquareButton,
    PDFViewerControlBar,
    PDFOverviewPanel,
    VuePdfEmbed,
    SubscriptionSignup,
    RequestNotesOrderForm,
    ProNoteRequestStatus,
    AudioVisualizer,
    VideoPlayer,
    AbstractSummary,
  },

  data() {
    return {
      pdfSession: this.$store.state.sessionDetails.sessionType === "document",

      PANEL_TYPES: {
        TRANSCRIPT: "transcript",
        NOTES: "notes",
      },
      AUDIO_SKIP_AMOUNT_SMALL: 3,
      AUDIO_SKIP_AMOUNT_MEDIUM: 10,
      AUDIO_SKIP_AMOUNT_LARGE: 60,
      VOLUME_ADJUST_AMOUNT: 0.1,
      PLAYBACK_SPEED_ADJUST_AMOUNT: 0.25,

      editorConstants: editorConstants,
      BackArrowIcon,
      BookmarkIcon,
      ChevronLeftWhiteIcon,
      PanelsWhiteIcon,
      ExportUploadWhiteIcon,
      LightbulbPurpleIcon,
      ArrowRightBlackIcon,
      AudioVisualizationExample,
      DownloadGreyIcon,
      MagicWandLight,
      FeedbackBubblePurpleIcon,
      CloseGreyIcon,
      TranscriptWhiteIcon,
      NotesWhiteIcon,
      PencilWhiteIcon,
      TrashIcon,
      CopyIcon,
      AddToNotesIcon,
      BookmarkFilledIcon,
      PositionTopRightIcon,
      PositionTopLeftIcon,
      MinimizeIcon,

      convertMillisecondsToHMS,
      convertDateToMonthDayYear,
      convertDateToHoursMinutes,
      Averta,
      isNotepadOpen: true,
      isTranscriptOpen: true,
      isSidePanelOpen: true,
      isStudyAssistantOpen: true,
      loading: true,
      errorLoading: false,
      errorLoadingMessage: "",
      MIN_LOADING_TIME: 1000,
      minLoadingTimeElapsed: false,
      audioVisualization: false,
      audioComponent: null,
      infoPanelOpen: false,
      shouldCloseInfoPanel: false,
      activeSidePanel: null,
      audioPaused: true,
      audioVolume: 1,
      playbackSpeed: 1,
      savedTranscript: null,
      noteEditor: null,
      transcriptEditor: null,
      sessionCurrentName: "Session Name",
      sessionPreviewName: "Session Name",
      transcriptAudioPlayer: ref(null),
      transcriptAudioCanvas: ref(null),
      mediaControlsFloating: true,
      backupNotesInterval: null,
      uploadingFileToBeehive: false,
      panelToSave: "",
      zoomFactorNotes: 1,
      fontColourTranscript: editorConstants.WHITE,
      fontBoldTranscript: editorConstants.DEFAULT_FONT_BOLD,
      fontFamilyTranscript: editorConstants.AVERTA,
      fontSizeTranscript: editorConstants.DEFAULT_FONT_SIZE,
      lineHeightTranscript: 1.4,
      letterSpacingTranscript: 0,
      highlightedFontSize: NOTES_PANEL_DEFAULTS.FONT_SIZE,
      showProNotes: false,
      proNoteEditor: null,
      anthillData: null,
      anthillSummary: "",

      // PDF Viewer variables
      pdfData: this.$store.state.sessionDetails.documentUrl || undefined,
      pdfViewerCurrentPage: null,
      pdfViewerNumPages: 0,
      pdfOverviewPanelVisible: false,
      pdfViewerInitialWidth: null,
      pdfViewerScale: 1,
      isInitialPdfRender: true,

      // Video Session variables
      videoUrl: this.$store.state.sessionDetails.videoUrl,
      videoSession: this.$store.state.sessionDetails.sessionType === "video",
      toggleVideoTile: false,
      videoComponent: null,
      videoDuration: -1,
      // either "above-transcript" or "above-personal-note"
      videoElementDestination: "above-transcript",
      videoState: null,
      videoReady: false,

      transcriptTimestampFrequency: 4,
      counter: 0,

      studyAssistantQuestionsAnswersList: [
        {
          text:
            "Hi! 👋 I'm your study assistant. " +
            "Let me know what you're looking for.",
          type: "",
          timestamp: new Date(),
        },
        {
          prompt: STUDY_ASSISTANT_PROMPT_TYPES.QUIZ_AND_TEST_PREP,
          type: "selectable",
          timestamp: new Date(),
        },
        {
          prompt: STUDY_ASSISTANT_PROMPT_TYPES.STUDY_MATERIAL_ANALYSIS,
          type: "selectable",
          timestamp: new Date(),
        },
      ],

      displayComingSoonDialog: false,
      anthillQuizList: undefined,
      anthillSummarizeList: undefined,
      anthillKeywordsList: undefined,
      anthillRecommendationsList: undefined,
      anthillTips: undefined,

      mediaName: "",

      currentUserSubscriptionDetails: {},

      showSubscriptionSignupDialog: false,
      orderFormVisible: false,
      showProNoteAlert: this.$store.state.sessionDetails.status === "done",
      bookmarkList: [],
      bookmarkTracker: {},
      formatedInfoPanelSessionData: [],

      jumpToBookmark: -1,
      audioDuration: -1,
    };
  },

  watch: {
    async transcriptTimestampFrequency() {
      await this.setTranscriptText();
      this.$refs.transcriptEditorRef.transcriptEditor.commands.setContent(
        this.savedTranscript
      );
    },

    jumpToBookmark: function (newVal) {
      if (
        newVal >= 0 &&
        this.bookmarkList &&
        this.bookmarkList.length !== 0 &&
        (this.audioComponent || this.videoComponent)
      ) {
        const bookmark =
          this.$store.state.sessionDetails.uploadDetails.content?.captions
            ?.results?.lines[newVal];
        if (this.audioComponent) {
          this.audioComponent.currentTime = bookmark.start_ms / 1000;
        }
        if (this.videoComponent) {
          this.videoComponent.currentTime = bookmark.start_ms / 1000;
        }
        this.jumpToBookmark = -1;
      }
    },

    audioDuration: {
      handler(newVal, oldVal) {
        if (oldVal < 0) {
          const durationString = convertMillisecondsToHMS(newVal).split(":");
          const durationFormated =
            `${durationString[0] !== "00" ? durationString[0] + " hr " : ""}` +
            `${durationString[1] !== "00" ? durationString[1] + " min " : ""}` +
            `${durationString[2]} sec`;
          this.formatedInfoPanelSessionData.push({
            title: "Duration",
            info: [
              {
                text: durationFormated,
                type: "primary",
              },
            ],
          });
        }
      },
      once: true,
    },

    audioComponent: {
      handler(newVal, oldVal) {
        if (newVal && !oldVal) {
          newVal.addEventListener("play", () => {
            this.audioPaused = false;
          });
          newVal.addEventListener("pause", () => {
            this.audioPaused = true;
          });
        }
      },
      once: true,
    },
  },

  computed: {
    isServiceProvider() {
      switch (this.$store.state.currentUser.role.toLowerCase()) {
        case "worker":
        case "service_provider":
          return true;
        default:
          return false;
      }
    },

    validSessionName() {
      return this.sessionPreviewName.trim().length > 0;
    },

    isPaused() {
      return this.audioComponent.paused;
    },

    highlightedTranscriptText() {
      if (this.transcriptEditor) {
        const state = this.transcriptEditor.state;
        const word = state.doc.textBetween(
          Math.min(state.selection.$head.pos, state.selection.$anchor.pos),
          Math.max(state.selection.$head.pos, state.selection.$anchor.pos),
          " "
        );
        return word;
      } else {
        return "";
      }
    },

    isDSA() {
      return this.$store.state.currentUser.team === ACCOUNT_TEAMS.DSA;
    },

    isWorker() {
      return this.$store.state.currentUser.role === ACCOUNT_TYPES.WORKER;
    },

    isRecordingSessionType() {
      return this.$store.state.sessionDetails.sessionType === "recording";
    },

    isDocumentSessionType() {
      return this.$store.state.sessionDetails.sessionType === "document";
    },

    canShowProNote() {
      return (
        this.$store.state.sessionDetails.status === "completed" ||
        this.$store.state.sessionDetails.status === "done"
      );
    },

    disablePersonalNote() {
      return (
        !this.currentUserSubscriptionDetails.isActive && !this.isServiceProvider
      );
    },
  },

  async created() {
    this.resizeHandler = _.debounce(this.resizeHandler, 20);

    this.currentUserSubscriptionDetails =
      await getCurrentUserSubscriptionDetails();
  },

  mounted() {
    if (this.$store.state.sessionDetails.title) {
      this.mediaName = this.$store.state.sessionDetails.title;
    } else if (this.$store.state.sessionDetails.mediaUploadData) {
      this.mediaName = this.$store.state.sessionDetails.mediaUploadData.id;
    } else {
      this.mediaName = "No title";
    }

    if (this.isDocumentSessionType) {
      // Document sessions don't need to load audio or Anthill data
      this.initializePdfData(this.$store.state.sessionDetails.documentUrl);
    } else if (this.videoSession) {
      this.initializeVideoData();
      this.getAnthillData();
    } else {
      if (this.isRecordingSessionType) {
        this.initializeRecordingSession();
      } else {
        this.getAudio();
      }
      this.getAnthillData();
    }

    this.backupNotesInterval = setInterval(() => {
      this.backupNotes();
    }, 10000);

    this.formatedInfoPanelSessionData = [];

    // SP side doesn't show session type so sessionType will be undefined
    if (this.$store.state.sessionDetails.sessionType) {
      this.formatedInfoPanelSessionData.push({
        title: "Session type",
        info: [
          {
            text:
              this.$store.state.sessionDetails.sessionType[0].toUpperCase() +
              this.$store.state.sessionDetails.sessionType.slice(1),
            type: "primary",
          },
        ],
      });
    }

    this.formatedInfoPanelSessionData.push(
      {
        title: "Last modified",
        info: [
          {
            text: formatDate(
              this.$store.state.sessionDetails.updatedAt ||
                // Beehive and MP server format their data slightly differently
                this.$store.state.sessionDetails.updated_at,
              this.$store.state.sessionDetails.updatedAt ||
                this.$store.state.sessionDetails.updated_at,
              null,
              false,
              true
            ),
            type: "primary",
          },
        ],
      },
      {
        title: "Created",
        info: [
          {
            text: formatDate(
              this.$store.state.sessionDetails.createdAt ||
                this.$store.state.sessionDetails.created_at,
              this.$store.state.sessionDetails.createdAt ||
                this.$store.state.sessionDetails.created_at,
              null,
              false,
              true
            ),
            type: "primary",
          },
        ],
      }
    );
  },

  unmounted() {
    clearInterval(this.backupNotesInterval);
  },

  methods: {
    /**** PDF Viewer methods ****/
    initializePdfData(data) {
      this.pdfData = data;
    },

    handlePdfRender() {
      if (this.isInitialPdfRender) {
        this.pdfViewerNumPages = this.$refs.pdfViewer.pageCount;
        this.pdfViewerCurrentPage = 1;
        this.isInitialPdfRender = false;
        this.loading = false;
      }

      // Have to reset scale and width calculations on re-render in case window width changed
      setTimeout(() => {
        // Small delay to let the vue-embed-pdf fully render before calculating width
        this.pdfViewerInitialWidth = this.$refs.pdfViewer.$el.clientWidth;
      }, 200);

      this.pdfViewerScale = 1;
    },

    resizeHandler() {
      this.pdfViewerScale =
        (this.$refs.pdfPanel.clientWidth - 80) / this.pdfViewerInitialWidth;
    },

    switchToPdfPage(page) {
      if (page < 1) {
        this.pdfViewerCurrentPage = 1;
      } else if (page > this.pdfViewerNumPages) {
        this.pdfViewerCurrentPage = this.pdfViewerNumPages;
      } else {
        this.pdfViewerCurrentPage = page;
      }
    },

    setPdfOverviewPanelVisible(bool) {
      this.pdfOverviewPanelVisible = bool;
    },
    /**** End of PDF Viewer methods ****/

    /**** Video Ssession methods ****/
    initializeVideoData() {
      this.setTranscriptText();
    },

    onMountedVideoPlayer(videoElementParameter) {
      const { video } = videoElementParameter;
      this.videoComponent = video;
      this.loading = false;
      this.videoReady = true;
      if (this.videoState) {
        video.currentTime = this.videoState.currentTime;
        if (this.videoState.paused) {
          video.pause();
        } else {
          video.play();
        }
      }

      if (this.videoDuration < 0) {
        video.addEventListener("loadedmetadata", () => {
          this.videoDuration = this.videoComponent.duration * 1000;
          this.$refs.audioVisualizer.updateBookmarkList(this.bookmarkList);
          const durationString = convertMillisecondsToHMS(
            this.videoDuration
          ).split(":");
          const durationFormated =
            `${durationString[0] !== "00" ? durationString[0] + " hr " : ""}` +
            `${durationString[1] !== "00" ? durationString[1] + " min " : ""}` +
            `${durationString[2]} sec`;
          this.formatedInfoPanelSessionData.push({
            title: "Duration",
            info: [
              {
                text: durationFormated,
                type: "primary",
              },
            ],
          });
        });
      }
      this.loading = false;
    },

    toggleVideoPosition() {
      // save video state before
      this.videoState = {};
      this.videoState.currentTime = this.videoComponent.currentTime;
      this.videoState.paused = this.videoComponent.paused;
      this.videoComponent.pause();
      this.videoComponent = null;
      this.videoElementDestination =
        this.videoElementDestination === "above-transcript"
          ? "above-personal-note"
          : "above-transcript";
      this.videoSession = false;
      setTimeout(() => {
        this.videoSession = true;
      }, 100);
    },

    // collapse video tile when video enters PIP mode
    setToggleVideoTile(val) {
      this.toggleVideoTile = val;
    },

    setPIPMode() {
      if (this.videoComponent && this.videoComponent.requestPictureInPicture) {
        this.videoComponent.requestPictureInPicture();
      }
    },
    /**** End of Video Session methods ****/

    updateTimestampFrequency(n) {
      this.transcriptTimestampFrequency = n;
    },

    async setTranscriptText() {
      await this.getBookmarks();
      let transcriptHtml = "";
      const sessionData = this.$store.state.sessionDetails.uploadDetails;
      const captionLines = sessionData.content?.captions?.results?.lines;

      if (!captionLines) {
        this.savedTranscript = "";
        return;
      }

      this.bookmarkList.forEach((bookmark) => {
        let lineIndex =
          captionLines.findIndex((line) => line.start_ms > bookmark.timestamp) -
          1;
        if (lineIndex < 0) {
          // cannot find, try edge cases
          if (captionLines[0].start_ms > bookmark.timestamp) {
            lineIndex = 0;
          } else {
            lineIndex = captionLines.length - 1;
          }
        }
        this.bookmarkTracker[lineIndex] ||
          (this.bookmarkTracker[lineIndex] = []);
        this.bookmarkTracker[lineIndex].push(bookmark);
        bookmark.text = captionLines[lineIndex].text;
        bookmark.paragraphTimestamp = captionLines[lineIndex].start_ms;
        bookmark.paragraphIndex = lineIndex;
      });
      captionLines.forEach((element, index) => {
        this.counter++;
        const bookmarks = this.bookmarkTracker[index] || null;
        let text = element.text;
        if (bookmarks !== null) {
          const bookmark = bookmarks[bookmarks.length - 1];
          text = `
            <mark
              data-color="${HIGHLIGHT_COLOR_MAP[bookmark.color]}"
            >
              ${text}
            </mark>
          `;
        }
        if (this.counter % this.transcriptTimestampFrequency === 0) {
          transcriptHtml += `<span><small>${
            element.start.split(".")[0]
          }:</small></span><p id="${index}" class="${index}">${text}</p>`;
        } else {
          transcriptHtml += `<p id="${index}" class="${index}">${text}</p>`;
        }
      });
      this.savedTranscript = transcriptHtml;
      this.count = 0;
    },

    editBookmarkContent(timeStamp, content) {
      const transcriptHTML = this.transcriptEditor.getHTML();
      const sessionData = this.$store.state.sessionDetails.uploadDetails;

      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(transcriptHTML, "text/html");
      const selectedP = htmlDoc.getElementsByTagName("p");
      const paragraphIndex =
        sessionData.content.captions.results.lines.findIndex((line) => {
          return line.start_ms <= timeStamp && line.end_ms >= timeStamp;
        });
      const paragraph = [...selectedP].find((element) => {
        return parseInt(element.id) === paragraphIndex;
      });
      paragraph.innerHTML = content;
      this.savedTranscript = htmlDoc.documentElement.outerHTML;
      this.transcriptEditor.commands.setContent(this.savedTranscript);
    },

    addBookmarkListAudioVisualizer(open) {
      open && this.$refs.audioVisualizer.updateBookmarkList(this.bookmarkList);
    },

    initializeRecordingSession() {
      // Load transcript
      this.setTranscriptText();

      // Load recording audio
      makeMPServerRequest(
        "self/uploads/" +
          this.$store.state.sessionDetails.mediaUploadData.id +
          "/get-presigned-url",
        "GET",
        "json",
        null
      )
        .then((data) => {
          this.audioComponent = new Audio();
          this.audioComponent.src = data.data;
          this.audioComponent.addEventListener("loadedmetadata", () => {
            this.audioDuration = this.audioComponent.duration * 1000;
          });
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.errorLoading = true;
          this.errorLoadingMessage = "Error loading audio";
          console.log("Error loading audio: " + error);
        });
    },

    async getAudio() {
      try {
        if (this.$store.state.sessionDetails.bundledCaptioningId) {
          // Bundled session, so get the audio & transcript stored in S3
          const getAudioParams = {
            Key: "Lecture-example-right-hand-rule.mp3",
            Bucket: process.env.VUE_APP_AWS_NOTETAKING_BUCKET,
            Expires: 86400,
          };

          const getTranscriptParams = {
            Key:
              this.$store.state.sessionDetails.bundledCaptioningId +
              dbConstants.CAPTIONING_TRANSCRIPT_S3_FILEPATH,
            Bucket: process.env.VUE_APP_AWS_NOTETAKING_BUCKET,
          };

          const s3 = new AWS.S3({
            region: "us-east-2",
            accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
          });

          let getAudioPromise = new Promise((resolve) => {
            const presignedAudioUrl = s3.getSignedUrl(
              "getObject",
              getAudioParams
            );

            this.audioComponent = new Audio();
            this.audioComponent.src = presignedAudioUrl;
            this.audioComponent.addEventListener("loadedmetadata", () => {
              this.audioDuration = this.audioComponent.duration * 1000;
            });
            resolve("Audio loaded");
          });

          let getTranscriptPromise = new Promise((resolve, reject) => {
            s3.getObject(getTranscriptParams, (err, data) => {
              this.savedTranscript = JSON.parse(data.Body);

              resolve("Transcript loaded");
            });

            setTimeout(() => {
              reject("Request timeout");
            }, 10000);
          });

          Promise.all([getAudioPromise, getTranscriptPromise]).then(() => {
            this.loading = false;
          });
        } else {
          // Not bundled, so load transcript/audio from anthill

          this.savedTranscript = "";
          const anthillApiHeaders = {
            API_KEY: process.env.VUE_APP_ANTHILL_KEY,
          };

          const anthillEndpoint =
            process.env.VUE_APP_ANTHILL_TRANSCRIPT_API +
            this.$store.state.sessionDetails.anthillTranscriptId;

          const beehiveFalconEndpoint =
            "https://beehive.habitatlearn.com/api/falcon_link?id=" +
            this.$store.state.sessionDetails.id +
            "&source=mp";

          let getAudioPromise = new Promise((resolve, reject) => {
            axios
              .get(beehiveFalconEndpoint, {
                withCredentials: false,
              })
              .then((res) => {
                this.audioComponent = new Audio();
                this.audioComponent.src = res.data[0].audio_file.url;
                this.audioComponent.addEventListener("loadedmetadata", () => {
                  this.audioDuration = this.audioComponent.duration * 1000;
                });
                resolve("Audio loaded");
              })
              .catch((error) => {
                console.error(error);
                reject("Request error");
              });
            setTimeout(() => {
              reject("Request timeout");
            }, 15000);
          });

          let getTranscriptPromise = new Promise((resolve, reject) => {
            axios
              .get(anthillEndpoint, {
                headers: anthillApiHeaders,
                withCredentials: false,
              })
              .then((res) => {
                const rawTranscriptData = JSON.parse(res.data.content.raw);
                let transcriptText = "";
                for (let i = 0; i < rawTranscriptData.length; i++) {
                  transcriptText =
                    transcriptText + " " + rawTranscriptData[i].text;
                }
                this.savedTranscript = transcriptText;
                resolve("Transcript loaded");
              })
              .catch((err) => {
                console.error(err);
                reject("Request error");
              });
            setTimeout(() => {
              reject("Request timeout");
            }, 10000);
          });

          Promise.all([getAudioPromise, getTranscriptPromise])
            .then(() => {
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
              this.errorLoading = true;
              this.errorLoadingMessage = "Error loading transcript/audio";
            });
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.errorLoading = true;
        this.errorLoadingMessage = "Error retrieving transcript/audio";
      }
    },

    async getAnthillData() {
      if (
        this.$store.state.sessionDetails.mediaUploadData &&
        this.$store.state.sessionDetails.mediaUploadData.id
      ) {
        const mpAnthillDataEnpoint =
          process.env.VUE_APP_MP_SERVER_API_URL +
          `self/uploads/${this.$store.state.sessionDetails.mediaUploadData.id}/anthill`;
        try {
          const mpAnthillResponse = await axios.get(mpAnthillDataEnpoint, {
            withCredentials: false,
            headers: {
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("tokenMP")).accessToken,
            },
          });
          if (
            mpAnthillResponse.status !== 200 ||
            mpAnthillResponse.data.status !== "success"
          ) {
            console.error("Could not retrieve MP data");
          } else {
            const anthillData = mpAnthillResponse.data.data;
            this.anthillQuizList = anthillData.servicesCompleted.includes(
              "questions"
            )
              ? anthillData.content.questions.results
              : undefined;
            this.anthillSummarizeList = anthillData.servicesCompleted.includes(
              "highlights"
            )
              ? anthillData.content.highlights.results
              : undefined;
            const anthillSummaryExists =
              anthillData.servicesCompleted.includes("summary");
            if (anthillSummaryExists) {
              this.anthillKeywordsList =
                anthillData.content.summary.results.keywords;
            } else {
              this.anthillKeywordsList = anthillData.servicesCompleted.includes(
                "entities"
              )
                ? Object.keys(anthillData.content.entities.results.entities)
                : undefined;
            }
            this.anthillRecommendationsList =
              anthillData.servicesCompleted.includes("recommendations")
                ? anthillData.content.recommendations.results
                : undefined;
            this.anthillTips = anthillData.servicesCompleted.includes("tutor")
              ? anthillData.content.tutor.results
              : undefined;
            this.anthillSummary = anthillData.servicesCompleted.includes("summary")
              ? anthillData.content.summary.results.summary
              : 'no summary available';
          }
        } catch (error) {
          console.error("Could not retrieve MP data");
        }
      }
    },

    insertCurrentTimestamp() {
      let state = this.noteEditor.state;
      const dispatch = this.noteEditor.view.dispatch;

      let selectionHead = state.selection.$head.pos;
      let selectionAnchor = state.selection.$anchor.pos;

      const timestamp = getTimeCodeFromNum(this.audioComponent.currentTime);

      this.noteEditor
        .chain()
        .focus()
        .selectTextblockStart()
        .setHighlight()
        .insertContent(timestamp)
        .run();

      this.noteEditor.chain().focus().unsetHighlight().insertContent(" ").run();

      state = this.noteEditor.state;

      // The total size of inserted text
      const shiftAmount = timestamp.length + 1;

      const selection = new TextSelection(
        state.doc.resolve(selectionHead + shiftAmount),
        state.doc.resolve(selectionAnchor + shiftAmount)
      );

      let transaction = state.tr.setSelection(selection);
      if (dispatch) dispatch(transaction);
    },

    showNotesEmoji(emoji) {
      this.noteEditor.chain().focus().setEmoji(emoji.id).run();
    },

    setNotesZoomFactor(zoomFactor) {
      this.zoomFactorNotes = zoomFactor;
    },

    addNotesImage() {
      const url = window.prompt("URL");

      if (url) {
        this.noteEditor.chain().focus().setImage({ src: url }).run();
      }
    },

    saveSessionName() {
      this.sessionCurrentName = this.sessionPreviewName;
      this.$refs.sessionNameMenu.hide();
    },

    cancelSessionName() {
      this.$refs.sessionNameMenu.hide();
    },

    toggleModifySessionNameMenu(event) {
      this.$refs.sessionNameMenu.toggle(event);
    },

    /******* Study Assistant methods *******/
    pushToStudyAssistantQuestionsAnswersList(item) {
      this.studyAssistantQuestionsAnswersList.push(item);
    },
    /******* End of Study Assistant methods ********/

    saveToComputer(panelToSave) {
      let editorToSave;
      let typeSuffix;
      if (panelToSave === this.PANEL_TYPES.TRANSCRIPT) {
        editorToSave = this.transcriptEditor;
        typeSuffix = "Transcript";
      } else {
        if (this.showProNotes) {
          editorToSave = this.proNoteEditor;
          typeSuffix = "ProNotes";
        } else {
          editorToSave = this.noteEditor;
          typeSuffix = "PersonalNotes";
        }
      }

      const docHTML = editorToSave.getHTML();

      downloadFile(docHTML, this.mediaName, typeSuffix);
    },

    // Backup notes to localstorage
    backupNotes() {
      if (!this.noteEditor) {
        // Before noteEditor is initialized
        return;
      }
      let savedNotes = JSON.parse(localStorage.getItem("savedNotes"));
      if (!savedNotes) {
        savedNotes = {};
      }
      savedNotes[this.$store.state.sessionDetails.id] =
        this.noteEditor.getJSON();
      localStorage.setItem("savedNotes", JSON.stringify(savedNotes));
    },

    setTranscriptEditor(transcriptEditor) {
      this.transcriptEditor = transcriptEditor;
    },

    async retrieveOrderId() {
      let myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        process.env.VUE_APP_MP_SERVER_API_URL +
          `self/sessions/${this.$store.state.sessionDetails.id}`,
        requestOptions
      );

      const sessionData = await response.json();

      // Filter for valid order and prioritize it over invalid orders
      const nonErrorOrder = sessionData.data.orders.find(
        (order) => !ORDER_STATUS.ERROR.includes(order.orderStatus)
      );
      const errorOrder = sessionData.data.orders.find((order) =>
        ORDER_STATUS.ERROR.includes(order.orderStatus)
      );

      return nonErrorOrder
        ? nonErrorOrder.id
        : errorOrder
        ? errorOrder.id
        : null;
    },

    async downloadOrderIdJSONText(orderId) {
      let myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        process.env.VUE_APP_MP_SERVER_API_URL +
          `self/orders/${orderId}/download`,
        requestOptions
      );

      const orderText = await response.json();

      return orderText;
    },

    setHighlightedFontSize(fontSize) {
      this.highlightedFontSize = fontSize;
    },

    async setNoteEditor(noteEditor) {
      this.noteEditor = noteEditor;
      this.noteEditor.on("selectionUpdate", ({ editor }) => {
        this.highlightedFontSize = editor.getAttributes("textStyle").fontSize;
      });
    },

    async setProNoteEditor(proNoteEditor) {
      this.proNoteEditor = proNoteEditor;
      let noteEditorContent = "";
      let orderId;

      // Order was set and passed through from Standby table
      if (this.$store.state.sessionDetails.order) {
        orderId = this.$store.state.sessionDetails.order.id;
      } else {
        try {
          orderId = await this.retrieveOrderId();
        } catch {
          (err) => {
            console.log(err);
          };
        }
      }

      let orderText;
      if (!orderId) {
        this.$store.commit("setSessionDetails", {
          ...this.$store.state.sessionDetails,
          status: "no-value",
        });
      } else {
        orderText = await this.downloadOrderIdJSONText(orderId);
        if (orderText.data) {
          const raw = JSON.stringify({
            tiptapJSON: JSON.parse(orderText.data.notes),
          });

          const html = await makeMPServerRequest(
            "utils/convert-tiptap-json-to-html",
            "POST",
            "text",
            raw
          );

          noteEditorContent = html;
        } else {
          this.$store.commit("setSessionDetails", {
            ...this.$store.state.sessionDetails,
            status: "no-value",
          });
        }
      }

      // //convertSecondsToMS
      this.proNoteEditor.commands.setContent(noteEditorContent);
    },

    pasteToNote(text) {
      try {
        this.noteEditor.chain().focus().insertContent(text).run();
      } catch (e) {
        console.error(e);
      }
    },

    togglePausePlayAudio() {
      if (this.audioComponent.paused) {
        this.audioComponent.play();
        this.audioPaused = false;
      } else {
        this.audioComponent.pause();
        this.audioPaused = true;
      }
    },

    skipBackward(amt) {
      this.audioComponent.currentTime = this.audioComponent.currentTime - amt;
    },

    skipForward(amt) {
      this.audioComponent.currentTime = this.audioComponent.currentTime + amt;
    },

    volumeUp(amt) {
      this.audioComponent.volume = Math.min(
        1,
        this.audioComponent.volume + amt
      );
      this.audioVolume = this.audioComponent.volume;
    },

    volumeDown(amt) {
      this.audioComponent.volume = Math.max(
        0,
        this.audioComponent.volume - amt
      );
      this.audioVolume = this.audioComponent.volume;
    },

    slowDownPlaybackSpeed(amt) {
      this.audioComponent.playbackRate = Math.max(
        0.25,
        this.audioComponent.playbackRate - amt
      );
      this.playbackSpeed = this.audioComponent.playbackRate;
    },

    speedUpPlaybackSpeed(amt) {
      this.audioComponent.playbackRate = Math.min(
        2,
        this.audioComponent.playbackRate + amt
      );
      this.playbackSpeed = this.audioComponent.playbackRate;
    },

    setPlaybackSpeed(val) {
      this.audioComponent.playbackRate = val;
      this.playbackSpeed = this.audioComponent.playbackRate;
    },

    handleKeypress(ev) {
      if (ev.key === " ") {
        this.togglePausePlayAudio();
      } else if (ev.key === "ArrowLeft") {
        if (ev.shiftKey) {
          this.skipBackward(this.AUDIO_SKIP_AMOUNT_MEDIUM);
        } else if (ev.ctrlKey || ev.metaKey) {
          this.skipBackward(this.AUDIO_SKIP_AMOUNT_LARGE);
        } else {
          this.skipBackward(this.AUDIO_SKIP_AMOUNT_SMALL);
        }
      } else if (ev.key === "ArrowRight") {
        if (ev.shiftKey) {
          this.skipForward(this.AUDIO_SKIP_AMOUNT_MEDIUM);
        } else if (ev.ctrlKey || ev.metaKey) {
          this.skipForward(this.AUDIO_SKIP_AMOUNT_LARGE);
        } else {
          this.skipForward(this.AUDIO_SKIP_AMOUNT_SMALL);
        }
      } else if (ev.key === "ArrowUp") {
        if (ev.ctrlKey || ev.metaKey) {
          this.volumeUp(this.VOLUME_ADJUST_AMOUNT);
        }
      } else if (ev.key === "ArrowDown") {
        if (ev.ctrlKey || ev.metaKey) {
          this.volumeDown(this.VOLUME_ADJUST_AMOUNT);
        }
      } else if (ev.key === "-") {
        this.slowDownPlaybackSpeed(this.PLAYBACK_SPEED_ADJUST_AMOUNT);
      } else if (ev.key === "=") {
        this.speedUpPlaybackSpeed(this.PLAYBACK_SPEED_ADJUST_AMOUNT);
      }
    },

    toggleInfoPanelOpen() {
      this.infoPanelOpen = !this.infoPanelOpen;

      // If the user manually toggles the info panel, don't want to save this state
      this.shouldCloseInfoPanel = false;
    },
    toggleStudyAssistantPanel() {
      this.isStudyAssistantOpen = !this.isStudyAssistantOpen;
    },

    toggleTranscriptOpen() {
      this.isTranscriptOpen = !this.isTranscriptOpen;
    },

    closeTranscriptPanel() {
      this.isTranscriptOpen = false;
    },

    openTranscriptPanel() {
      this.isTranscriptOpen = true;
    },

    toggleNotepadOpen() {
      this.isNotepadOpen = !this.isNotepadOpen;
    },

    closeNotepadPanel() {
      this.isNotepadOpen = false;
    },

    openNotepadPanel() {
      this.isNotepadOpen = true;
    },

    toggleSidePanelOpen() {
      this.isSidePanelOpen = !this.isSidePanelOpen;
    },

    goToSessionBrowser() {
      this.$router.push("/standby");
    },

    changeFontColourTranscript(hex) {
      this.fontColourTranscript = hex;
    },

    toggleFontBoldTranscript() {
      this.fontBoldTranscript = !this.fontBold;
    },

    changeFontFamilyTranscript(family) {
      this.fontFamilyTranscript = family;
    },

    changeFontSizeTranscript(size) {
      this.fontSizeTranscript = size;
    },

    changeLineHeightTranscript(lineHeight) {
      this.lineHeightTranscript = Math.round(lineHeight * 10) / 10;
    },

    changeLetterSpacingTranscript(spacing) {
      this.letterSpacingTranscript = spacing;
    },

    setShowSubscriptionSignupDialog(bool) {
      this.showSubscriptionSignupDialog = bool;
    },

    setOrderFormVisible(val) {
      this.orderFormVisible = val;
    },

    updateProNoteRequest() {
      this.$store.commit("setSessionDetails", {
        ...this.$store.state.sessionDetails,
        status: "requested",
      });
    },

    closeProNoteAlert() {
      this.showProNoteAlert = false;
    },
    updateTitle(newTitle) {
      this.mediaName = newTitle;
      this.$store.commit("setSessionDetails", {
        ...this.$store.state.sessionDetails,
        title: newTitle,
      });
    },

    /// Bookmark methods
    async colourButtonClicked(color) {
      const index =
        this.transcriptEditor.state.selection.$anchor.parent.attrs.id;
      const bookmark = this.bookmarkList.find((bookmark) => {
        return bookmark.paragraphIndex === parseInt(index);
      });
      if (color === bookmark.color) {
        return;
      }
      bookmark.color = color;
      let myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        color: color,
      });

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        process.env.VUE_APP_MP_SERVER_API_URL +
          `self/uploads/${this.$store.state.sessionDetails.mediaUploadData.id}/` +
          `bookmarks/${bookmark.id}`,
        requestOptions
      );
      const bookmarkData = await response.json();
      if (bookmarkData.status === "success") {
        this.changeBookmarkColor(bookmark);
      }
    },

    async getBookmarks() {
      let myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );
      myHeaders.append("Content-Type", "application/json");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        process.env.VUE_APP_MP_SERVER_API_URL +
          `self/uploads/${this.$store.state.sessionDetails.mediaUploadData.id}/bookmarks`,
        requestOptions
      );
      const bookmarkData = await response.json();
      this.bookmarkList = bookmarkData.data;
    },

    removeBookmark(bookmark) {
      const index = this.bookmarkList.indexOf(bookmark);
      this.bookmarkList.splice(index, 1);
      this.$store.commit("setSessionDetails", {
        ...this.$store.state.sessionDetails,
        bookmarks: this.bookmarkList,
      });
      this.$refs.audioVisualizer?.updateBookmarkList(this.bookmarkList);
      if (bookmark.paragraphIndex < 0) {
        return;
      }
      // check if there are other bookmarks on the paragraph
      const bookmarks = this.bookmarkTracker[bookmark.paragraphIndex];
      const bookmarkIndex = bookmarks.indexOf(bookmark);
      bookmarks.splice(bookmarkIndex, 1);
      let text = bookmark.text;
      if (bookmarks.length !== 0) {
        const nextBookmark = bookmarks[bookmarks.length - 1];

        text = `<mark data-color="${HIGHLIGHT_COLOR_MAP[nextBookmark.color]}">
            ${text}
          </mark>`;
      }
      const transcriptHTML = this.transcriptEditor.getHTML();
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(transcriptHTML, "text/html");
      const selectedP = htmlDoc.getElementsByTagName("p");
      const paragraphIndex = bookmark.paragraphIndex;

      const paragraph = [...selectedP].find((element) => {
        return parseInt(element.id) === paragraphIndex;
      });
      if (!paragraph) {
        return;
      }

      paragraph.innerHTML = `${text}`;
      this.savedTranscript = htmlDoc.documentElement.outerHTML;
      this.transcriptEditor.commands.setContent(this.savedTranscript);
    },

    changeBookmarkColor(bookmark) {
      this.$store.commit("setSessionDetails", {
        ...this.$store.state.sessionDetails,
        bookmarks: this.bookmarkList,
      });
      this.$refs.audioVisualizer?.updateBookmarkList(this.bookmarkList);
      if (bookmark.paragraphIndex < 0) {
        return;
      }
      const transcriptHTML = this.transcriptEditor.getHTML();
      const sessionData = this.$store.state.sessionDetails.uploadDetails;

      const timeStamp = bookmark.timestamp;
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(transcriptHTML, "text/html");
      const selectedP = htmlDoc.getElementsByTagName("p");
      const paragraphIndex =
        sessionData.content.captions.results.lines.findIndex((line) => {
          return line.start_ms <= timeStamp && line.end_ms >= timeStamp;
        });
      const paragraph = [...selectedP].find((element) => {
        return parseInt(element.id) === paragraphIndex;
      });

      if (!paragraph) {
        return;
      }

      paragraph.innerHTML = `<mark data-color="${
        HIGHLIGHT_COLOR_MAP[bookmark.color]
      }">
        ${bookmark.text}
      </mark>`;
      this.savedTranscript = htmlDoc.documentElement.outerHTML;
      this.transcriptEditor.commands.setContent(this.savedTranscript);
    },

    async insertBookmark() {
      let currentTimeStamp;

      if (this.videoComponent) {
        currentTimeStamp = Math.floor(this.videoComponent.currentTime * 1000);
      } else {
        currentTimeStamp = Math.floor(this.audioComponent.currentTime * 1000);
      }

      const checkIndex = this.bookmarkList.findIndex(
        (bookmark) => bookmark.timestamp === currentTimeStamp
      );

      if (checkIndex != -1) {
        return;
      }

      const transcriptHTML = this.transcriptEditor.getHTML();
      const sessionData = this.$store.state.sessionDetails.uploadDetails;
      const captionLines = sessionData.content.captions.results.lines;
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(transcriptHTML, "text/html");
      const selectedP = htmlDoc.getElementsByTagName("p");
      let paragraphIndex =
        captionLines.findIndex((line) => {
          return line.start_ms > currentTimeStamp;
        }) - 1;

      if (paragraphIndex < 0) {
        // check edge cases
        if (currentTimeStamp < captionLines[0].start_ms) {
          paragraphIndex = 0;
          // check the beginning
        } else {
          paragraphIndex = captionLines.length - 1;
        }
      }

      let myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        timestamp: currentTimeStamp,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        process.env.VUE_APP_MP_SERVER_API_URL +
          `self/uploads/${this.$store.state.sessionDetails.mediaUploadData.id}/bookmarks`,
        requestOptions
      );
      const bookmarkData = await response.json();
      if (bookmarkData.status !== "success") {
        console.error(bookmarkData);
        return;
      }
      const newBookmark = bookmarkData.data;
      newBookmark.paragraphIndex = paragraphIndex;
      newBookmark.text = captionLines[paragraphIndex].text;
      newBookmark.paragraphTimestamp = captionLines[paragraphIndex].start_ms;
      const paragraph = [...selectedP].find((element) => {
        return parseInt(element.id) === paragraphIndex;
      });
      paragraph.innerHTML = `<mark
        data-color="${HIGHLIGHT_COLOR_MAP[newBookmark.color]}">
        ${paragraph.innerHTML}
      </mark>`;
      this.savedTranscript = htmlDoc.documentElement.outerHTML;
      this.transcriptEditor.commands.setContent(this.savedTranscript);
      this.bookmarkTracker[paragraphIndex] ||
        (this.bookmarkTracker[paragraphIndex] = []);
      this.bookmarkTracker[paragraphIndex].push(newBookmark);

      this.bookmarkList.push(newBookmark);
      this.bookmarkList.sort(
        (bookmark1, bookmark2) => bookmark1.timestamp - bookmark2.timestamp
      );
      this.$store.commit("setSessionDetails", {
        ...this.$store.state.sessionDetails,
        bookmarks: this.bookmarkList,
      });
      this.$refs.audioVisualizer?.updateBookmarkList(this.bookmarkList);
      this.$toast.add({
        summary: `${convertSecondsToMS(currentTimeStamp / 1000)} Bookmarked!`,
        life: 3000,
        group: "bc",
      });
    },

    shouldShow() {
      // props obj = { editor, view, state, oldState, from, to }
      return (props) => {
        const { selection } = props.state;
        const currentNode =
          selection.$anchor.nodeBefore || selection.$anchor.nodeAfter;
        // looking for paragraph elements with id - from anthilll -
        // and has been highlighted
        const isBookmark =
          selection.$anchor.parent.attrs.id !== null &&
          currentNode.marks.length > 0;
        if (isBookmark) {
          this.jumpToBookmark = parseInt(selection.$anchor.parent.attrs.id);
        }
        return (
          selection.$anchor.parent.attrs.id !== null &&
          currentNode.marks.length > 0
        );
      };
    },

    addToNotes() {
      const text =
        this.transcriptEditor.state.selection.$anchor.parent.content.content[0]
          .text;
      this.pasteToNote(`<p>${text}</p>`);
    },

    copyText() {
      try {
        const text =
          this.transcriptEditor.state.selection.$anchor.parent.content
            .content[0].text;
        navigator.clipboard.writeText(text);
      } catch (error) {
        console.error(error);
      }
    },

    async deleteBookmark() {
      const index =
        this.transcriptEditor.state.selection.$anchor.parent.attrs.id;
      const bookmark = this.bookmarkList.find((bookmark) => {
        return bookmark.paragraphIndex === parseInt(index);
      });
      let myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );
      myHeaders.append("Content-Type", "application/json");

      let requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        process.env.VUE_APP_MP_SERVER_API_URL +
          `self/uploads/${this.$store.state.sessionDetails.mediaUploadData.id}/` +
          `bookmarks/${bookmark.id}`,
        requestOptions
      );
      const bookmarkData = await response.json();
      if (bookmarkData.status === "success") {
        this.removeBookmark(bookmark);
      }
    },

    playbackAt(time) {
      this.audioComponent.currentTime = time;
      this.audioComponent.play();
    },
  },
};
</script>

<style src="pdfvuer/dist/pdfvuer.css"></style>
<style lang="scss">
$top-bar-height: 6.4rem;
$audio-visualization-bar-height: 40px;
$bottom-bar-height: 8rem;
$border: 0.1rem solid map-get($component-colours, border-light-faint);
$panel-header-height: 3.2rem;

.note-taker-wrapper {
  height: 100%;
  width: 100%;

  .note-taker {
    .left-wrapper {
      position: relative;

      .side-panel {
        background-color: map-get($component-colours, chat-background);
        color: map-get($component-colours, chat-font-primary);
        min-width: $side-panel-width;
        position: absolute;
        top: 0;
        left: 0;
        width: $side-panel-width;
        height: 100%;
        z-index: 2;

        @media only screen and (max-width: 1000px) {
          min-width: $side-panel-width-mobile;
          width: $side-panel-width-mobile;
        }

        ::selection {
          background-color: map-get(
            $component-colours,
            font-background-colour-highlighted
          );
        }
        ::-moz-selection {
          background-color: map-get(
            $component-colours,
            font-background-colour-highlighted
          );
        }
      }
    }

    .content-panel {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      min-width: 0;

      .top-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: $top-bar-height;
        width: 100%;
        border-bottom: $border;
        padding: 0 2.4rem;

        @media only screen and (max-width: 950px) {
          padding: 0 1.6rem;
        }

        .left-section {
          display: flex;
          align-items: center;
          min-width: 13.2rem;

          @media only screen and (max-width: 1000px) {
            display: none;
          }

          &.side-panel-open {
            @media only screen and (max-width: 1250px) {
              display: none;
            }
          }

          .session-name-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;

            .session-name {
              @include section-header-2;
              margin-left: 1.6rem;
            }

            .session-name-field {
              display: flex;
              flex-direction: column;
            }

            .session-name-chevron-button {
              img {
                transform: rotate(-90deg);
              }
            }
          }
        }

        .right-section {
          display: flex;
          align-items: center;

          > * {
            margin-left: 1.6rem;
          }
        }
      }

      .audio-visualization-bar {
        width: 100%;
        height: 0;
        border-bottom: $border;
        border-bottom-width: 0;
        transition: height 0.4s, border-bottom-width 0s 0.4s;
        overflow: auto;
        display: flex;
        align-items: center;
        overflow-y: hidden;

        &.show {
          height: $audio-visualization-bar-height;
          // border-bottom: 0.1rem solid map-get($component-colours, note-taker-border);
          border-bottom-width: 0.1rem;

          transition: height 0.4s, border-bottom-width 0.4s 0s;
        }

        img {
          // padding: 2rem 2.4rem;
          // height: 100%;
          // width: 100%;
          // object-fit: cover;
          // object-position: left;
        }

        audio {
          position: absolute;
          bottom: 0;
          z-index: 1000;
          left: 50%;
          transform: translateX(50%);
        }

        .audio-control-bar {
          position: absolute;
          bottom: 2.4rem;
          z-index: 1000;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .main {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        height: calc(
          100vh - #{$top-bar-height} - #{$audio-visualization-bar-height} - #{$bottom-bar-height}
        );
        position: relative;

        .pdf-overview-panel {
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          margin-top: -0.8rem;
          position: absolute;
        }

        .collapse-button {
          position: absolute;
          background-color: map-get($colours, accent-purple-3);
          padding: 1rem;
          transition: opacity 0.2s;
          height: auto;
          z-index: 2;
        }

        .left-button {
          border-radius: 1.8rem 0 0 1.8rem;
          right: 0;
        }

        .right-button {
          border-radius: 0 1.8rem 1.8rem 0;
          left: 0;
        }

        .note-taker-side-panel {
          border-right: 0.1rem solid
            map-get($component-colours, note-taker-border-dark);
          position: relative;
        }

        .editors {
          background: map-get($component-colours, background-primary);
          display: flex;
          flex-direction: row;
          flex-grow: 1;
          overflow: auto;
          max-width: 100%;

          .editor-type-header {
            padding: 0 1.6rem;
            // border-bottom: $border;
            align-self: stretch;
            text-align: center;
            height: $panel-header-height;
            min-height: $panel-header-height;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.tabs {
              padding: 0;
            }

            .editor-type-header-text {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-size: 1.2rem;
              font-weight: bold;
              letter-spacing: 0.015rem;
              color: map-get($colours, grey-1);

              img {
                width: 1.6rem;
                height: 1.6rem;
                margin-top: -0.2rem;
                margin-right: 0.4rem;
              }

              &.clickable {
                background: transparent;
                color: rgba(255, 255, 255, 0.8);
              }
            }

            .note-editor-selection-buttons {
              display: flex;
              flex-direction: row;
              width: 100%;
              height: 100%;

              > * {
                justify-content: center;
                flex: 1;
                border-bottom: 0.1rem solid
                  map-get($colours, translucent-white-10);
                transition: border-color 300ms ease;
              }

              .active-editor-button {
                border-color: map-get($colours, grey-1);
                color: #e8e8e8;
              }
            }
          }

          .download-button {
            position: absolute;
            right: 1.2rem;
            top: 1.2rem;
          }

          .download-button2 {
            margin-right: 1.2rem;
          }

          > *:not(:first-child) {
            border-left: $border;
          }

          .pdf-viewer-panel {
            // align-items: center;

            .pdf-viewer {
              flex-grow: 1;
              padding: 4rem;
              overflow: auto;

              .vue-pdf-embed {
                transform-origin: top left;
              }
            }
          }

          .note-taker-editor-wrapper {
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: auto;

            .text-formatting-bar-wrapper {
              // padding: 0.8rem 1.6rem 0;
              padding: 0.4rem 2.8rem 0 1.6rem;
              z-index: 1;
              position: relative;
            }

            .text-editor-wrapper {
              transition: opacity 0.2s 0.2s;
              // height: calc(100% - #{$panel-header-height});
              height: auto;
              flex: 1;
              overflow: auto;

              &.collapsed {
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.2s;
              }
            }

            .note-editor {
              height: calc(100% - #{$bottom-bar-height});

              .text-editor {
                width: auto;
                * :not(a) {
                  color: white !important;
                }
              }
            }

            .disable-personal-note-dialog {
              top: 4.2rem;
            }
          }

          .study-assistant-wrapper {
            width: 75%;
            height: 100%;
            display: flex;
            flex-direction: column;
            transition: all 0.4s;
            transition-property: width, min-width;
            min-width: 30rem;

            &.collapsed {
              width: 0;
              min-width: 0;
              transition: all 0.4s 0.1s;
              transition-property: width, min-width, max-width;
            }
          }
        }
      }
      .bottom-bar {
        bottom: 0;
        background-color: #53435f;
        display: flex;
        flex-direction: row;
        height: $bottom-bar-height;

        .toggle-study-assistant-button {
          margin-top: 1.6rem;
          margin-left: 1.6rem;
          margin-bottom: 2.4rem;
          img {
            border-radius: 8px;
            width: 4rem;
            height: 4rem;
          }
        }
      }
    }

    .dropdown-menu {
      margin-bottom: 1rem;
      z-index: 1000;
    }
  }
}

.note-taker-panel {
  transition: all 0.4s;
  transition-property: width, min-width;
  min-width: 44rem;

  &.collapsed {
    width: 0;
    min-width: 0;
    transition: all 0.4s 0.1s;
    transition-property: width, min-width, max-width;
  }
}

.note-taker-editor-wrapper {
  width: 100%;
}

.note-taker-side-panel {
  min-width: 36rem;
}

.p-overlaypanel.change-session-name-menu {
  border-radius: 1.4rem;
  overflow: hidden;
  transform: translateX(-50%);

  .p-overlaypanel-content {
    padding: 1.6rem 2.4rem;

    .session-name-field {
      display: flex;
      flex-direction: column;
      padding-bottom: 1.6rem;

      input {
        width: 30rem;
      }

      label {
        @include body-2;
        color: map-get($colours, grey-5);
        text-transform: uppercase;
      }
    }

    .button-wrapper {
      padding-top: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      > *:not(:first-child) {
        margin-left: 1.6rem;
      }
    }
  }
}

.transcript-highlight-menu {
  background-color: map-get($colours, accent-purple-3);
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  font-family: $font-secondary;
  border-radius: 0.6rem;

  > * {
    padding: 0.8rem 1.6rem;
  }

  button {
    background-color: transparent;
    text-align: left;
    color: map-get($colours, white);

    &:hover {
      background-color: map-get($colours, accent-purple-4);
    }
  }

  .colour-option {
    border-radius: 50%;
    height: 1.6rem;
    width: 1.6rem;
    margin: 0.8rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.coming-soon-dialog {
  .p-dialog-content {
    background-color: map-get($colours, accent-purple);
    color: map-get($colours, white);
    width: 50rem;
    border-radius: 1.2rem;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .p-dialog-header {
    display: none;
  }
  // align-items: center;

  .coming-soon-dialog-header {
    display: flex;
    justify-content: center;
    font-size: 2.4rem;
    height: 32;
    font-family: $font-accent;
    font-weight: bold;
    padding-bottom: 2.4rem;
  }

  .coming-soon-dialog-iconwrapper {
    display: flex;
    justify-content: flex-end;
    padding: 1.6rem 1.6rem 0;
  }

  .coming-soon-dialog-body {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1.4rem;
  }

  .coming-soon-dialog-buttonwrapper {
    display: flex;
    justify-content: center;

    .coming-soon-dialog-buttonwrapper-button {
      background-color: map-get($colours, accent-purple-2);
      height: 5.6rem;
      width: 16.8rem;
      border-radius: 0.8rem;
      // height: 5rem;
      // width: 10rem;
    }
    margin: 4rem 0;
  }
}

.note-type-toggle-button {
  .p-button-label {
    margin-top: 0.2rem;
  }
}

.p-togglebutton.p-button {
  border-radius: 5rem;
  height: 3rem;
  margin-top: 1rem;
  background: #877594;
  color: #ffffff;
}

.p-togglebutton.p-button:focus.p-highlight {
  background: #877594;
  color: #ffffff;
}

.p-togglebutton.p-button:focus {
  background: #877594;
  color: #ffffff;
}

.p-togglebutton.p-button.p-highlight {
  background: #877594;
  color: #ffffff;
}

.pro-note-alert {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
}

.bubble-menu-popup {
  background-color: map-get($colours, accent-purple-3);
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  font-family: $font-secondary;
  border-radius: 0.6rem;
  width: 22.4rem;
  height: 17.6rem;
  .color-buttons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
    .color-button {
      border-radius: 50%;
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .table-menu-popup-button {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
    padding: 0.8rem 1.6rem;
    font-size: 1.6rem;
    color: white;
  }
}

.note-taker-video-wrapper {
  width: 100%;
  height: 32vh;
  padding: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: map-get($colours, accent-purple-2);
  // background: blue;
  // transition: height 0.5s, border-bottom-width 0s 0.5s;
  .note-taker-video {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    cursor: pointer;

    video {
      width: 100%;
      height: 100%;
      border-radius: 1rem;
    }
  }

  .pip-mode-button {
    position: absolute;
    top: 2.5rem;
    right: 4.5rem;
    width: 2.4rem;
    height: 2.4rem;
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
   
    border-radius: 50%;
    color: red;
    font-weight: bold;
    line-height: 2.5rem;
    text-align: center;
  }

  .position-toggle-button {
    right: 10rem;
  }

  /* On hovering the video, show the button */
  // note-taker-video:hover {
  &:hover {
    .pip-mode-button {
      opacity: 1;
      z-index: 100;
    }

    video {
      filter: brightness(50%);
    }
  }
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(200%);
  opacity: 0;
}

.note-taker-video-collapse {
  display: none;
}

.download-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
</style>
