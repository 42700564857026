<template>
  <div class="panels-wrapper" id="drop-panel">
    <InfoPanel
      :isOpen="infoPanelOpen"
      :toggleInfoPanelOpen="toggleInfoPanelOpen"
      sessionBrowserPage
      class="info-panel"
      :openNewSessionModal="setNewSessionModalVisible"
    />
    <div v-if="!uploadInProgress && !uploadComplete && !uploadFailed" class="text-instructions">
      <div class="upload-icon"><img :src="UploadWhite" /></div>
      <div class="header">Drag and drop or attach your file</div>
      <p class="subtext">
        Upload your audio recording for transcription, document, video file.
      </p>
      <p class="supported-formats">
        Supported file formats: MP3, WAV, M4A, PDF, MP4, WEBM, and AVI
      </p>
      <FileUpload
        mode="basic"
        name="demo[]"
        accept=".m4a,.aac,.caf,.wav,.mp3,.pdf,.mp4,.webm,.avi"
        customUpload
        @uploader="onUpload($event)"
        class="choose-file-button"
        chooseLabel="Choose file"
        :auto="true"
      />
    </div>
    <Card v-show="uploadInProgress" class="upload-progess-card">
      <template #title>
        <div class="title-text">Uploading...</div>
      </template>
      <template #content>
        <div class="filename">{{ fileName }}</div>
      </template>
      <template #footer>
        <ProgressBar
          class="progress-bar"
          :value="uploadProgess"
          :showValue="false"
        />
        <span class="bytes-progress">{{ progressMessage }} </span>
      </template>
    </Card>
    <RetryCard
      v-if="uploadFailed"
      :file-name="fileName"
      :errorMessage="uploadFailureMessage"
      :progressMessage="progressMessage"
      :retryCount="retryCount"
      @cancel="onCancel"
      @retryUpload="retryUpload"
    />
    <div v-if="uploadComplete && isAudio" class="upload-complete-view">
      <div class="transcribing-header">Transcribing...0%</div>
      <div class="transcribing-subtext">
        Please wait a moment while we prepare your transcript.
      </div>
      <img :src="OnboardingRecordNotesImage" class="record-notes-image" />
      <div class="transcribing-subtext">We’ll notify you when it’s ready.</div>
      <div class="transcribing-subtext">
        Feel free to close the app and come back later.
      </div>
    </div>
    <div v-if="uploadComplete && !isAudio" class="upload-complete-view">
      <div class="transcribing-header">Finished Uploading</div>
      <div class="transcribing-subtext">Finished uploading document</div>
      <img :src="OnboardingRecordNotesImage" class="record-notes-image" />
      <div class="transcribing-subtext">Document Ready</div>
    </div>
    <Toast></Toast>
  </div>
  <NewSessionModal
    :visible="newSessionModalVisible"
    :setVisible="setNewSessionModalVisible"
  />
</template>
<script>
import InfoPanel from "../components/InfoPanel";
import UploadWhite from "../assets/images/Upload-white.svg";
import OnboardingRecordNotesImage from "../assets/images/Onboarding-record-notes.png";
import {
  completeMultipartUpload,
  createMultipartUploadURL,
  createSession,
  createUpload,
  uploadDocument,
  eventEmitter,
} from "../assets/uploadFile";
import { formatBytes } from "../assets/helpers_legacy";
import NewSessionModal from "../components/NewSessionModal.vue";
import RetryCard from "../components/Upload/RetryCard.vue";
import { RECORDING_UPLOAD_STEP } from "../assets/constants";

export default {
  components: {
    InfoPanel,
    NewSessionModal,
    RetryCard,
  },

  data() {
    return {
      infoPanelOpen: false,
      uploadProgess: 0,
      uploadInProgress: false,
      uploadComplete: false,
      progressBytes: 0,
      totalFileSize: null,
      fileName: "",

      duration: null,
      fileExtension: "",
      progressMessage: "",
      allowedFileTypes: [
        "mp3",
        "m4a",
        "webm",
        "wav",
        "caf",
        "aac",
        "pdf",
        "mp4",
        "avi",
      ],
      completedUploadParts: null,
      xhr: null,
      isAudio: true,
      newSessionModalVisible: false,

      UploadWhite,
      OnboardingRecordNotesImage,

      // default to true to err on the side of better UX
      userCanUploadAudio: true,
      
      uploadFailed: false,
      uploadProgressData: {},
      currentMediaUploadStep: undefined,
      uploadFailureMessage: "",
      file: null,

      retryCount: 0,
    };
  },

  methods: {
    onUpload(event) {
      this.file = event.files[0];
      if (this.file.type.includes("application/pdf")) {
        uploadDocument(this.file.name, "application/pdf", this.file, "mp-document", null)
      } else {
        this.uploadMedia(this.file.name, this.file.type, this.file);
      }
    },

    toggleInfoPanelOpen() {
      this.infoPanelOpen = !this.infoPanelOpen;
    },

    async getDuration(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (e) => {
          const ctx = new AudioContext();
          const audioArrayBuffer = e.target.result;
          ctx.decodeAudioData(
            audioArrayBuffer,
            (data) => {
              this.duration = parseInt(data.duration * 1000);
              resolve();
            },
            (error) => {
              console.error(error);
            }
          );
        };
      });
    },

    formatFileName(fileName) {
      const fileExtension = fileName.split(".").pop();
      if (fileName <= 50) {
        this.fileName = fileName;
      } else {
        const fileExtionIndex = fileName.lastIndexOf(".");
        let fileNameWithoutExtension = fileName
          .substring(0, fileExtionIndex)
          .substring(0, 50 - (fileExtension.length + 1));
        this.fileName = fileNameWithoutExtension + "." + fileExtension;
      }
      return fileExtension;
    },

    async uploadMedia(filePath, mimeType, file) {
      this.uploadInProgress = true;

      this.progressMessage = "Getting file duration...";

      try {
        await this.getDuration(filePath);
      } catch (error) {
        console.error(error);
        // will default to 0 if the function produces an error
        // the duration will be corrected by the converter function
        this.duration = 0;
      }

      const fullFileName = filePath.substring(filePath.lastIndexOf("/") + 1);
      const fileExtension = this.formatFileName(fullFileName);

      if (mimeType === "audio/x-m4a") {
        mimeType = "audio/m4a";
      }

      const uploadType = mimeType.includes("video")
        ? "mp-video"
        : "mp-desktop-recording";

      const uploadSequence = [
        {
          func: createSession,
          step: RECORDING_UPLOAD_STEP.CreateSession,
          returnValueKey: "sessionId",
          progressMessage: "Creating new empty project...",
          errorMessage: "Failed to create a session",
          args: {
            recordingTitle: this.fileName,
          }
        },
        {
          func: createUpload,
          step: RECORDING_UPLOAD_STEP.CreateUpload,
          returnValueKey: "uploadId",
          progressMessage: "Creating upload information...",
          errorMessage: "Failed to create an upload entry",
          args: {
            recordingDuration: this.duration,
            multipart: true,
            fileExt: fileExtension,
            mimeType: mimeType,
            uploadType: uploadType,
            uploadMetaDataObject: this.uploadProgressData,
          }
        },
        {
          func: createMultipartUploadURL,
          step: RECORDING_UPLOAD_STEP.CreateMultipartUploadURL,
          returnValueKey: "multipartUploadURL",
          progressMessage: "Getting file ready...",
          errorMessage: "Failed to create upload URL",
          args: {
            uploadMetaDataObject: this.uploadProgressData,
          }
        },
        {
          func: this.uploadToS3,
          step: RECORDING_UPLOAD_STEP.UploadToS3,
          returnValueKey: null,
          progressMessage: "Creating upload information...",
          errorMessage: "Failed to upload file to storage",
          args: {
            file: file,
            uploadMetaDataObject: this.uploadProgressData,
          },
        },
        {
          func: completeMultipartUpload,
          step: RECORDING_UPLOAD_STEP.CompleteMultipartUpload,
          returnValueKey: null,
          progressMessage: "Creating upload information...",
          errorMessage: "Failed to finalize upload",
          args: {
            uploadMetaDataObject: this.uploadProgressData,
          },
        },
      ]

      let currentStep = this.currentMediaUploadStep ?? RECORDING_UPLOAD_STEP.CreateSession;

      while (currentStep <= RECORDING_UPLOAD_STEP.CompleteMultipartUpload) {
        const {
          func, 
          step,
          returnValueKey,
          progressMessage,
          args,
          errorMessage,
        } = uploadSequence[currentStep];

        try {
          if (returnValueKey) {
            this.uploadProgressData[returnValueKey] = await func(args);
          } else {
            await func(args);
          }
          this.progressMessage = progressMessage;
          currentStep += 1;
        } catch (error) {
          console.log(`Failed at step ${currentStep} - ${error}`);
          this.currentMediaUploadStep = step;
          this.uploadFailureMessage = errorMessage;
          this.uploadInProgress = false;
          this.uploadFailed = true;
          return;
        }
      }

      if (uploadType === 'mp-desktop-recording') {
        this.uploadInProgress = false;
        this.uploadComplete = true;
      } else {
        const videoElement = document.createElement('video');
        const videoURL = URL.createObjectURL(file);
        videoElement.src = videoURL;
        videoElement.addEventListener('loadedmetadata', async () => {
          const url = `${process.env.VUE_APP_MP_SERVER_API_URL}self/uploads/${this.uploadProgressData.uploadId}`

          const duration =  Math.ceil(videoElement.duration * 1000);

          let myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
          );
          myHeaders.append("Content-Type", "application/json");
          const response = await fetch(url, {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({ duration }),
          })

          if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`)
          }

          await response.json()
          this.uploadInProgress = false;
          this.uploadComplete = true;
          URL.revokeObjectURL(videoURL);
        });
      }
    },

    async uploadToS3({url, file}) {
      return new Promise((resolve, reject) => {
        this.xhr.onreadystatechange = () => {
          if (this.xhr.readyState === 4) {
            if (this.xhr.status === 200) {
              let etag = this.xhr.getResponseHeader("ETag");

              let parts = [
                {
                  partNumber: 1,
                  eTag: etag.replace(/['"]+/g, ""),
                },
              ];
              this.completedUploadParts = parts;
              this.uploadProgressData.completedUploadParts = [{
                eTag: parts[0].eTag,
                partNumber: parts[0].partNumber,
              }];
              resolve(this.xhr);
            } else {
              reject(this.xhr);
            }
          }
        };

        this.xhr.upload.onprogress = (progress) => {
          if (!this.totalFileSize) {
            this.totalFileSize = formatBytes(progress.total);
          }
          this.progressBytes = formatBytes(progress.loaded);
          this.progressMessage =
            this.progressBytes + " / " + this.totalFileSize;
          this.uploadProgess = parseInt(
            (progress.loaded * 100) / progress.total
          );
        };

        this.xhr.open("PUT", url ?? this.uploadProgressData.multipartUploadURL);
        this.xhr.send(file);
      });
    },

    addDragAndDropEventListeners() {
      document
        .getElementById("drop-panel")
        .addEventListener("drop", (event) => {
          if (!this.uploadComplete) {
            document.getElementById("drop-panel").style.backgroundColor =
              "#53435f";
            event.preventDefault();
            event.stopImmediatePropagation();

            if (event.dataTransfer.files.length > 1) {
              this.$toast.add({
                severity: "info",
                summary: "Oops",
                detail: "Please upload one recording at a time.",
                life: 3000,
              });
              return;
            }

            this.file = event.dataTransfer.files[0];

            if (
              !this.file.type.includes("audio") &&
              !this.file.type.includes("video") &&
              !this.file.type.includes("application/pdf")
            ) {
              this.$toast.add({
                severity: "info",
                summary: "Oops",
                detail:
                  "That doesn't appear to be an audio/pdf file. Please check the file format.",
                life: 3000,
              });
              return;
            }
            let fileExt = this.file.name.split(".").pop().toLowerCase();

            if (!this.allowedFileTypes.includes(fileExt)) {
              this.$toast.add({
                severity: "info",
                summary: "Oops",
                detail:
                  "Please choose a file with one of the following formats: mp3, wav, webm, caf, aac, or pdf",
                life: 3000,
              });
              return;
            }

            if (this.file.type.includes("application/pdf")) {
              this.uploadDocument(this.file.name, "application/pdf", this.file);
            } else {
              this.uploadMedia(this.file.name, this.file.type, this.file);
            }
          }
        });

      document
        .getElementById("drop-panel")
        .addEventListener("dragover", (e) => {
          e.preventDefault();
          e.stopPropagation();
        });

      document
        .getElementById("drop-panel")
        .addEventListener("dragstart", (event) => {
          event.dataTransfer.clearData();
        });

      document
        .getElementById("drop-panel")
        .addEventListener("dragenter", () => {
          document.getElementById("drop-panel").style.backgroundColor =
            "#6d577d";
        });

      document
        .getElementById("drop-panel")
        .addEventListener("dragleave", () => {
          document.getElementById("drop-panel").style.backgroundColor =
            "#53435f";
        });
    },

    setNewSessionModalVisible(visible) {
      this.newSessionModalVisible = visible;
    },

    retryUpload() {
      this.retryCount += 1;
      this.uploadFailed = false;
      this.uploadFailureMessage = "";
      this.uploadMedia(this.file.name, this.file.type, this.file);
    },

    onCancel() {
      this.uploadFailed = false;
      this.uploadFailureMessage = "";
      this.currentMediaUploadStep = undefined;
      this.file = null;
      this.uploadProgress = 0;
      this.uploadInProgress = false;
      this.fileName = 0;
      this.totalFileSize = 0;
      this.duration = 0;
      this.fileExtension = "";
      this.progressMessage = "";
      this.completedUploadParts = "";
    },
  },

  created() {
    eventEmitter.on("uploadInProgress", (uploadInProgress) => {
      this.uploadInProgress = uploadInProgress;
    });
    eventEmitter.on("updateProgressMessage", (progressMessage) => {
      this.progressMessage = progressMessage;
    });
    eventEmitter.once("uploadComplete", () => (this.uploadComplete = true));
  },

  mounted() {
    this.xhr = new XMLHttpRequest();

    this.addDragAndDropEventListeners();
  },

  beforeUnmount() {
    eventEmitter.removeAllListeners("uploadInProgress");
    eventEmitter.removeAllListeners("updateProgressMessage");
  },
};
</script>
<style lang="scss">
.panels-wrapper {
  .text-instructions {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    height: 50vh;

    .upload-icon {
      width: 4.98rem;
      height: 4.98rem;
      background-color: rgba(59, 44, 71, 0.78);
      border-radius: 50%;
      margin: 0 auto;
      padding-top: 0.7rem;
      margin-top: 25vh;
      margin-bottom: 2.71rem;
    }

    .header {
      font-family: Averta;
      font-size: 1.9rem;
      font-weight: bold;
      margin-bottom: 2.4rem;
    }
    .subtext {
      font-size: 1.4rem;
    }
    .supported-formats {
      font-size: 1.3rem;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 4rem;
    }
    .choose-file-button {
      // display: inline;
      background-color: #877594;
      color: white;
      font-size: 1.6rem;
      width: 16.8rem;
      height: 5.6rem;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 1.4rem;
      white-space: nowrap;
      border: none;
      outline: none;
      position: relative;
      border-radius: 0.8rem;
      .p-button-icon {
        font-size: 2rem;
      }
    }
  }
  .upload-progess-card {
    width: 45.4rem;
    height: 14.8rem;
    background-color: #3b2c47;
    margin: 0 auto;
    margin-top: 35vh;
    color: #f4f4f4;
    padding-left: 2rem;
    padding-right: 2rem;

    .title-text {
      text-align: center;

      font-size: 1.6rem;
      margin-top: 1.2rem;
    }

    .filename {
      font-family: Manrope;
      font-size: 1.6rem;
      margin-bottom: -1rem;
    }

    .progress-bar {
      width: 100%;
      height: 0.6rem;
      margin: 0 auto;
    }
    .p-progressbar {
      background-color: #d1d1d1;
      border-radius: 0.6rem;
    }
    .p-progressbar-determinate .p-progressbar-value-animate {
      transition: width 500ms ease-in-out;
    }
    .p-progressbar .p-progressbar-value {
      background-color: #53435f;
    }

    .bytes-progress {
      font-family: Manrope;
      font-size: 1.2rem;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .upload-complete-view {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    margin-top: 25vh;

    .transcribing-header {
      font-family: Averta;
      font-size: 2rem;
      font-weight: bold;
    }
    .transcribing-subtext {
      font-size: 1.8rem;
      margin-top: 1.6rem;
      color: rgba(255, 255, 255, 0.8);
    }
    img {
      width: 30rem;
    }
  }
}
</style>
