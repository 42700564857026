<template>
  <Card
    class="upload-retry-card"
    :class="props.retryCount > RETRY_LIMIT ? 'upload-retry-card-contact-height' : ''"
  >
    <template #title>
      <div class="upload-retry-card-title">
        File upload failed
      </div>
    </template>
    <template #content>
      <div class="upload-retry-card-filename">
        {{ props.fileName }}
      </div>
      <div class="upload-retry-card-error" >
        <div>
          <img :src="ErrorOutline"/>
        </div>
        <div>
          {{ "Error: " + props.errorMessage }}
        </div>
      </div>
      <div
        v-if="props.retryCount > RETRY_LIMIT"
        class="upload-retry-card-contact"
      >
        {{ "Please contact support if you are experiencing continuous upload issues" }}
      </div>
    </template>
    <template #footer>
      <div
        class="upload-retry-card-buttons"
      >
        <button
          class="upload-retry-card-buttons-button"
          @click="onCancel" 
        >
          Cancel
        </button>
        <button
          class="upload-retry-card-buttons-button upload-retry-card-buttons-retry"
          @click="onClickRetry"
        >
          Retry
        </button>
      </div>
    </template>
    
  </Card>
</template>

<script setup lang="ts">
import { RETRY_LIMIT } from '../../assets/constants';
const ErrorOutline = require("../../assets/images/ErrorOutlineRed.svg") as string;


const props = defineProps({
  fileName: {
    type: String,
    required: true,
  },
  errorMessage: {
    type: String,
    required: true,
  },
  totalFileSize: {
    type: String,
  },
  progressBytes: {
    type: String,
  },
  retryCount: {
    type: Number,
    default: 0,
  }
})

const emit = defineEmits(["retryUpload", "cancel"])

function onCancel() {
  emit("cancel");
}

function onClickRetry() {
  emit("retryUpload");
}

</script>

<style lang="scss">
.upload-retry-card {
  width: 46.4rem;
  height: 20.6rem;
  margin: 0 auto;
  margin-top: 35vh;
  background-color: #f5dee2;
  padding-left: 2rem;
  padding-right: 2rem;

  .upload-retry-card-title {
    width: auto;
    display: flex;
    justify-content: center;
    margin: 1.6rem 0;
    font-size: 1.6rem;
  }

  .upload-retry-card-filename {
    margin: 1.6rem 0 0;
    padding: 0;
    color: #191919;
    font-size: 1.4rem;
    font-family: $font-secondary;
  }

  .upload-retry-card-error {
    padding: 0;
    color: #b00020;
    width: auto;
    height: 1.6rem;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    margin: 0.4rem 0;

    img {
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 0.27rem;
      margin-top: 0.2rem;
    }
  }

  .upload-retry-card-buttons {
    display: flex;
    justify-content: flex-end;
    width: auto;
    .upload-retry-card-buttons-button {
      height: 3.2rem;
      width: 7.9rem;
      margin: 0 1.2rem;
      font-family: $font-secondary;
      border-radius: 0.8rem;
    }

    .upload-retry-card-buttons-retry {
      color: #ffffff;
      background-color: map-get($colours, accent-purple-3);
    }
  }
  .upload-retry-card-contact {
    font-size: 1.2rem;
  }
}

.upload-retry-card-contact-height {
  height: 22rem;
}
</style>
