<template>
  <div class="session-filters-wrapper">
    <div class="filters-header">
      <button
        v-if="!isPersonal"
        @click="FiltersToggle = !FiltersToggle"
        class=""
      >
        <svg
          width="18"
          height="12"
          viewBox="0 0 18 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 11C7 11.5523 7.44772 12 8 12H10C10.5523 12 11 11.5523 11 11C11 10.4477 10.5523 10 10 10H8C7.44772 10 7 10.4477 7 11ZM1 0C0.447716 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2H17C17.5523 2 18 1.55228 18 1C18 0.447715 17.5523 0 17 0H1ZM3 6C3 6.55228 3.44772 7 4 7H14C14.5523 7 15 6.55228 15 6C15 5.44772 14.5523 5 14 5H4C3.44772 5 3 5.44772 3 6Z"
            fill="currentColor"
          />
        </svg>
        Filters
        <i :class="{ toggled: FiltersToggle }" class="pi pi-chevron-down" />
      </button>
      <span class="p-input-icon-left filter-input">
        <i class="pi pi-search" />
        <InputText
          v-model="TableFilter['name'].value"
          placeholder="Search..."
        />
      </span>
    </div>
    <Transition name="slide">
      <div v-if="FiltersToggle">
        <div class="filters-body">
          <div v-if="this.FilterFields.includes('status')" class="col-1">
            <p class="display">Status</p>
            <div class="checkbox-wrapper status-wrapper">
              <div class="checkbox">
                <Checkbox
                  v-model="TableFilter['status'].value"
                  inputId="statusAll"
                  name="statusAll"
                  value="All"
                />
                <label for="statusAll" class="">All</label>
              </div>
              <div class="checkbox">
                <Checkbox
                  v-model="TableFilter['status'].value"
                  inputId="statusActive"
                  name="statusActive"
                  value="Active"
                />
                <label for="statusActive" class="">Active</label>
              </div>
              <div class="checkbox">
                <Checkbox
                  v-model="TableFilter['status'].value"
                  inputId="statusPending"
                  name="statusPending"
                  value="Pending"
                />
                <label for="statusPending" class="">Pending</label>
              </div>
              <div class="checkbox">
                <Checkbox
                  v-model="TableFilter['status'].value"
                  inputId="statusDone"
                  name="statusDone"
                  value="Done"
                />
                <label for="statusDone" class="">Done</label>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div v-show="false">
              <p class="display">Course</p>
              <div class="multiselect-wrapper">
                <MultiSelect
                  aria-label="Course search"
                  v-model="SelectedCourses"
                  :options="courses"
                  placeholder="Search courses"
                  optionLabel="name"
                  filter
                />
              </div>
            </div>
            <p class="display">Date</p>
            <div class="checkbox-wrapper">
              <div class="checkbox">
                <RadioButton
                  v-model="TableFilter['date'].value"
                  inputId="dateAll"
                  name="dateAll"
                  value="All"
                />
                <label for="dateAll" class="">All</label>
              </div>
              <div class="checkbox">
                <RadioButton
                  v-model="TableFilter['date'].value"
                  inputId="dateToday"
                  name="dateToday"
                  value="Today"
                />
                <label for="dateToday" class="">Today</label>
              </div>
              <div class="checkbox">
                <RadioButton
                  v-model="TableFilter['date'].value"
                  inputId="dateTomorrow"
                  name="dateTomorrow"
                  value="Tomorrow"
                />
                <label for="dateTomorrow" class="">Tomorrow</label>
              </div>
              <div class="checkbox">
                <RadioButton
                  v-model="TableFilter['date'].value"
                  inputId="dateNextWeek"
                  name="Next week"
                  value="NextWeek"
                />
                <label for="dateNextWeek" class="">Next week</label>
              </div>
              <div class="checkbox">
                <RadioButton
                  v-model="TableFilter['date'].value"
                  inputId="dateCustom"
                  name="Custom date"
                  value="Custom"
                />
                <label for="dateCustom" class="">Custom</label>
              </div>
            </div>
            <div class="date-wrapper">
              <Calendar
                :disabled="TableFilter['date'].value != 'Custom'"
                v-model="DateFrom"
                showIcon
                dateFormat="dd/mm/yy"
              />
              <hr />
              <Calendar
                :disabled="TableFilter['date'].value != 'Custom'"
                v-model="DateTo"
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
          </div>
          <div class="col-1">
            <p class="display">Session Types</p>
            <div class="checkbox-wrapper">
              <div class="checkbox">
                <Checkbox
                  v-model="TableFilter['type'].value"
                  inputId="TypeAll"
                  name="TypeAll"
                  value="All"
                />
                <label for="TypeAll" class="">All</label>
              </div>
              <div class="checkbox">
                <Checkbox
                  v-model="TableFilter['type'].value"
                  inputId="TypeCaptioning"
                  name="TypeCaptioning"
                  value="caption"
                />
                <label for="TypeCaptioning" class="">Captioning</label>
              </div>
              <div class="checkbox">
                <Checkbox
                  v-model="TableFilter['type'].value"
                  inputId="TypeNoteTaking"
                  name="TypeNoteTaking"
                  value="note"
                />
                <label for="TypeNoteTaking" class="">Note Taking</label>
              </div>
            </div>
            <div v-show="false">
              <p class="display">Service Providers</p>
              <div class="multiselect-wrapper">
                <MultiSelect
                  aria-label="Service providers search"
                  v-model="SelectedUsers"
                  :options="users"
                  placeholder="Search names"
                  optionLabel="name"
                  filter
                />
              </div>
            </div>
          </div>
          <div class="col button-wrapper">
            <RoundedButton
              @click="clearFilters()"
              lessRound
              text="Reset"
              slim
              dark
            />
            <!-- <RoundedButton @click="applyFilters()" lessRound slim accent text="Apply" /> -->
          </div>
        </div>
      </div>
    </Transition>
  </div>
  <hr size="1px" />
  <DataTable
    :loading="loader"
    v-model:filters="TableFilter"
    :globalFilterFields="FilterFields"
    breakpoint="0px"
    class="session-table"
    :value="sessions"
    sortField="sortFieldValue"
    :sortOrder="-1"
    :paginator="true"
    :rowsPerPageOptions="[5, 10, 20]"
    :rows="5"
    selectionMode="single"
    v-model:selection="selectedSession"
    @rowSelect="onRowSelect"
    dataKey="id"
    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink"
    currentPageReportTemplate="{first} - {last} of {totalRecords}"
  >
    <template #loading>
      <div style="padding: 2rem"></div>
    </template>
    <template #empty>
      <div class="empty-message">
        <img aria-label="Options" :src="InfoIcon" alt="Options" />
        <div v-if="dataError" class="error-message">
          <p>An error occured!</p>
        </div>
        <div v-else-if="sessions.length === 0" class="error-message">
          <p>You have no notes or recordings yet.</p>
          <p>Click “+ New” to make some!</p>
        </div>
        <div v-else class="error-message">
          <p>No search results found</p>
          <p>We can't find any item matching your search.</p>
        </div>
      </div>
      <span></span>
    </template>
    <Column
      v-for="col of columns"
      :icon="col.icon"
      :sortable="col.sortable"
      :class="col.class"
      :key="col.id"
      :field="col.field"
      :dataType="col.dataType"
      :header="col.icon ? '' : col.header"
      :filter="col.filter"
    >
      <template v-if="col.header === 'Options'" #header>
        <img aria-label="Options" :src="SettingsFilledWhite" alt="Options" />
      </template>
      <template v-if="col.header === 'Type'" #body="col">
        <span class="icon-wrapper">
          <img
            aria-label="Session Type"
            :src="getType(col.data.type)"
            alt="Session Type"
          />
        </span>
      </template>
      <template v-else-if="col.header === 'Notes Status'" #body="col">
        <div
          class="tag"
          :class="col.data.status"
          style="max-width: min-content"
        >
          {{ col.data.status }}
        </div>
      </template>
      <template v-else-if="col.header === 'Options'" #body="col">
        <IconButton
          :icon="ThreeDotsWhiteIcon"
          :alt="'Settings'"
          v-tooltip.top="'Settings'"
          xsmall
          @click="(e) => toggle(e, col.data.menuProps)"
          aria-haspopup="true"
          aria-controls="menus"
        />
        <Menu
          class="table-menu-popup"
          ref="menu"
          id="actions_menu"
          :model="PopupMenus"
          :popup="true"
        />
      </template>
      <template v-else #body="col">
        <div class="cell-content">
          {{ col.data[col.field] }}
        </div>
      </template>
    </Column>
  </DataTable>
  <RequestNotesOrderForm
    :visible="orderFormVisible"
    :recordingTitle="sessionTitle"
    :durationHMS="durationHMS"
    :durationMilliseconds="durationMilliseconds"
    :recordingUploadId="recordingUploadId"
    :setOrderFormVisible="setOrderFormVisible"
    @hide="clearOrderFormValues()"
    @updateStandbySessionsMenuItem="updateStandbySessionsMenuItem()"
    ref="orderForm"
  ></RequestNotesOrderForm>
  <TitleChangeModal
    :sessionId="selectedId"
    :sessionTitle="selectedTitle"
    :visible="titleChangeModalVisible"
    :setVisible="setTitleChangeModal"
    @updateSessionList="updateStandbySessionsMenuItem()"
  />
</template>

<script>
import { FilterMatchMode, FilterService } from "primevue/api";
import RoundedButton from "../components/RoundedButton";
import IconButton from "../components/IconButton";
import RecordingWhiteIcon from "../assets/images/RecordingWhiteIcon.svg";
import FileWhiteIcon from "../assets/images/FileWhiteIcon.svg";
import ThreeDotsWhiteIcon from "../assets/images/ThreeDotsWhiteIcon.svg";
import SettingsFilledWhite from "../assets/images/Settings-filled-white.svg";
import FolderWhiteIcon from "../assets/images/FolderWhiteIcon.svg";
import InfoIcon from "../assets/images/InfoIcon.svg";
import { ACCOUNT_TYPES, ORDER_STATUS } from "../assets/constants";
import RequestNotesOrderForm from "./Orders/RequestNotesOrderForm";
import { convertMillisecondsToHMS, formatDate } from "../assets/helpers_legacy";
import TitleChangeModal from "../components/TitleChangeModal.vue";
// import { session } from "electron";
const FILTER_STATUS = "FILTER_STATUS";
const FILTER_DATE = "FILTER_DATE";
const FILTER_TYPE = "FILTER_TYPE";
export default {
  data() {
    return {
      FiltersToggle: false,
      TableFilter: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        type: { value: null, matchMode: FILTER_TYPE },
        status: { value: null, matchMode: FILTER_STATUS },
        date: { value: "All", matchMode: FILTER_DATE },
      },
      selectedSession: null,
      FilterToday: new Date(),
      DateFrom: null,
      DateTo: null,
      FilterFields: [],
      RecordingWhiteIcon,
      FileWhiteIcon,
      ThreeDotsWhiteIcon,
      SettingsFilledWhite,
      FolderWhiteIcon,
      InfoIcon,
      sessions: [],
      columns: [],
      PopupMenus: [],
      loader: true,
      dataError: false,
      orderFormVisible: false,
      sessionTitle: "",
      durationHMS: "",
      durationMilliseconds: "",
      recordingUploadId: "",
      orderSubmitted: false,
      orders: null,
      menuItem: null,
      titleChangeModalVisible: false,
      selectedTitle: "",
      selectedId: "",
    };
  },
  components: {
    RoundedButton,
    IconButton,
    RequestNotesOrderForm,
    TitleChangeModal,
  },

  computed: {
    isPersonal() {
      return this.$store.state.currentUser.role === ACCOUNT_TYPES.PERSONAL;
    },
  },

  methods: {
    openTitleChangeModal(elem) {
      this.selectedTitle = elem.title || elem.name;
      this.selectedId = elem.id;
      this.setTitleChangeModal(true);
    },

    onRowSelect(event) {
      this.open(
        event.data.elem,
        event.data.name,
        event.data.status ||
          event.data.elem.status ||
          event.data.elem.task_status,
        event.data.statusDetail,
        event.data.order
      );
    },

    filterDate(start, end, value) {
      if (!start || !end) {
        return false;
      }
      let s = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        0,
        0,
        0
      );
      let e = new Date(
        end.getFullYear(),
        end.getMonth(),
        end.getDate(),
        23,
        59,
        59
      );
      if (value.getTime() >= s.getTime() && value.getTime() <= e.getTime()) {
        return true;
      } else return false;
    },
    getStatus(status) {
      if (!status) {
        return "error";
      }
      switch (status.toLowerCase()) {
        case "na":
          return "neutral";
        case "ok":
        case "class ended":
        case "completed":
          return "success";
        case "ex":
        case "pending":
          return "progress";
        case "on hold":
        case "cancelled - exam or quiz":
        case "cancelled - holiday":
          return "warning";
        case "cancelled - student does not need captions":
        case "cancelled - summary notes only":
        case "cancelled - no class":
        case "cancelled - course ended already":
        case "cancelled - discontinued":
        case "cancelled - reading week":
        default:
          return "error";
      }
    },
    getType(type) {
      if (!type) {
        return RecordingWhiteIcon;
      }
      switch (type.toString().toLowerCase().trim()) {
        case "folder":
          return FolderWhiteIcon;
        case "notes":
        case "live note taking":
          return FileWhiteIcon;
        case "recording":
        case "instant live captioning":
        case "live captioning":
        case "audio/m4a":
        case "audio/aac":
        default:
          return RecordingWhiteIcon;
      }
    },
    toggle(event, elem) {
      this.menuItem = elem[2];
      this.PopupMenus = elem;
      this.$refs.menu[0].toggle(event);
    },
    clearFilters() {
      this.TableFilter["name"].value = null;
      this.TableFilter["status"].value = null;
      this.TableFilter["date"].value = "All";
      this.TableFilter["type"].value = null;
      this.DateFrom = null;
      this.DateTo = null;
    },
    async setData({ captioner_data, notetaker_data, user_data }) {
      let sessions = [];

      if (notetaker_data) {
        notetaker_data.forEach((elem) => {
          const title = elem.course_name ?? elem.media_name ?? "No Title!";
          sessions.push({
            num: sessions.length,
            id: elem.id,
            type: elem.media_file_type,
            name: title,
            dueDate: formatDate(elem.deadline, elem.deadline),
            date: formatDate(elem.created_at, elem.created_at),
            time: formatDate(
              elem.actual_handling_start_time,
              elem.actual_handling_end_time
            ),
            endTime: new Date(elem.actual_handling_end_time).toLocaleString(),
            lastModified: formatDate(elem.updated_at, elem.updated_at),
            sortFieldValue: elem.created_at,
            status: elem.task_status,
            elem: elem,
            menuProps: [
              {
                label: "Open",
                command: () => {
                  this.open(elem, title, elem.task_status, null, null);
                },
              },
              {
                label: "Download file",
                command: () => {
                  this.downloadFile(elem);
                },
                visible: false,
              },
              {
                label: "Copy Session ID",
                command: () => {
                  this.copySessionId(elem.id);
                },
              },
              {
                label: "Give feedback on this file",
                command: () => {
                  this.giveFeedback();
                },
                visible: false,
              },
            ],
          });
        });
      }
      if (captioner_data) {
        captioner_data.forEach((elem) => {
          const title = elem.name ?? "No title!";
          sessions.push({
            num: sessions.length,
            id: elem.id,
            type: elem.service_type,
            name: title,
            date: formatDate(
              elem.start_time,
              elem.start_time,
              elem.end_time,
              true
            ),
            dueDate: "N/A",
            time: formatDate(elem.start_time, elem.start_time, elem.end_time),
            endTime: new Date(elem.end_time).toLocaleString("en-CA"),
            lastModified: formatDate(elem.updated_at, elem.updated_at),
            sortFieldValue: elem.start_time,
            status: elem.status,
            elem: elem,
            menuProps: [
              {
                label: "Open",
                command: () => {
                  this.open(elem, title, elem.status, null, null);
                },
              },
              {
                label: "Download file",
                command: () => {
                  this.downloadFile(elem);
                },
                visible: false,
              },
              {
                label: "Copy Session ID",
                command: () => {
                  this.copySessionId(elem.id);
                },
              },
              {
                label: "Give feedback on this file",
                command: () => {
                  this.giveFeedback();
                },
                visible: false,
              },
            ],
          });
        });
      }
      if (user_data) {
        await this.retrieveOrderList();
        user_data.forEach((elem) => {
          const matchingOrders = this.ordersList.data.filter(
            (order) => order.sessionId === elem.id
          );
          const nonErrorOrder = matchingOrders.find(
            (order) => !ORDER_STATUS.ERROR.includes(order.orderStatus)
          );
          const errorOrder = matchingOrders.find((order) =>
            ORDER_STATUS.ERROR.includes(order.orderStatus)
          );
          // Match any order without error before considering any errored out orders
          const matchingOrder = nonErrorOrder ? nonErrorOrder : errorOrder;
          const orderStatus = matchingOrder ? matchingOrder.orderStatus : null;
          let status = "no-value";
          if (ORDER_STATUS.IN_PROGRESS.includes(orderStatus)) {
            status = "in progress";
          } else if (ORDER_STATUS.COMPLETE.includes(orderStatus)) {
            status = "done";
          } else if (ORDER_STATUS.REQUESTED.includes(orderStatus)) {
            status = "requested";
          } else if (ORDER_STATUS.ERROR.includes(orderStatus)) {
            status = "error";
          }
          let orderAlreadyRequested =
            this.ordersList.data.filter(
              (x) =>
                x.sessionId === elem.id &&
                (ORDER_STATUS.IN_PROGRESS.includes(x.orderStatus) ||
                  ORDER_STATUS.COMPLETE.includes(x.orderStatus) ||
                  ORDER_STATUS.REQUESTED.includes(x.orderStatus) ||
                  ORDER_STATUS.ERROR.includes(x.orderStatus))
            ).length > 0;

          const isDocSessionType =
            elem.uploads && elem.uploads.type === "mp-document";

          const isInstantSessionType =
            elem.uploads && elem.uploads.type === "mp-instant-session";

          const title = elem.title ?? "No title!";

          sessions.push({
            num: sessions.length,
            id: elem.id,
            type: isInstantSessionType ? "instant live captioning" : null,
            name: title,
            date: formatDate(elem.updatedAt, elem.updatedAt),
            lastModified: formatDate(elem.updatedAt, elem.updatedAt),
            sortFieldValue: elem.updatedAt,
            status: status,
            statusDetail: orderStatus,
            elem: elem,
            order: matchingOrder,
            menuProps: [
              {
                label: "Open",
                command: () => {
                  this.open(elem, title, status, orderStatus, matchingOrder);
                },
              },
              {
                label: "Download file",
                command: () => {
                  this.downloadFile(elem);
                },
                visible: false,
              },
              {
                label: orderAlreadyRequested
                  ? "Notes Requested"
                  : "Request Professional Notes",
                command: () => {
                  this.requestNotes(elem);
                },
                visible: !isDocSessionType && !isInstantSessionType,
                disabled: orderAlreadyRequested,
              },
              {
                label: "Copy Session ID",
                command: () => {
                  this.copySessionId(elem.id);
                },
              },
              {
                label: "Rename",
                command: () => {
                  this.openTitleChangeModal(elem);
                },
              },
              {
                label: "Give feedback on this file",
                command: () => {
                  this.giveFeedback();
                },
                visible: false,
              },
            ],
          });
        });
      }
      this.sessions = sessions;
      this.loader = false;
    },
    copySessionId(e) {
      try {
        navigator.clipboard.writeText(e);
      } catch (error) {
        console.log(error);
      }
    },
    downloadFile(e) {
      console.log(e, "DownloadFile");
    },
    giveFeedback(e) {
      console.log(e, "Feedback");
    },
    open(data, title, status, statusDetail, order) {
      this.$store.commit("setSessionDetails", {
        title: title,
        status: status,
        statusDetail: statusDetail,
        order: order,
        ...data,
      });
      this.$router.push({
        name: "SessionInitializer",
      });
    },
    requestNotes(data) {
      if (data.uploads === undefined) {
        this.$refs.orderForm.orderSubmissionErrorMessage =
          "Whoops! There is no upload associated with this session";
        this.orderFormVisible = true;
      } else {
        const mediaUploadData = data.uploads.find(
          (upload) => upload.type !== "mp-supplementary"
        );
        this.durationHMS = convertMillisecondsToHMS(mediaUploadData.duration);
        this.durationMilliseconds = mediaUploadData.duration.toString();
        this.sessionTitle = data.title;
        this.recordingUploadId = mediaUploadData.id;
        this.orderFormVisible = true;
      }
    },
    setOrderFormVisible(val) {
      this.orderFormVisible = val;
    },
    clearOrderFormValues() {
      this.$refs.orderForm.orderSubmitted = false;
      this.$refs.orderForm.step = 0;
      this.$refs.orderForm.orderSubmissionErrorMessage = "";
    },
    async retrieveOrderList() {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        process.env.VUE_APP_MP_SERVER_API_URL + "self/orders/",
        requestOptions
      );

      const ordersList = await response.json();

      this.ordersList = ordersList;
    },
    updateStandbySessionsMenuItem() {
      let myHeaders;
      let graphql;
      let requestOptions;
      let sessionsHeaders;
      let sessionRequestOptions;

      switch (this.$store.state.currentUser.role.toLowerCase().trim()) {
        case "worker":
        case "service_provider":
          myHeaders = new Headers();
          myHeaders.append("content-type", "application/json");
          myHeaders.append(
            "x-hasura-admin-secret",
            process.env.VUE_APP_HASURA_ADMIN_SECRET
          );
          graphql = JSON.stringify({
            query: `query GetSessionsInfo($uid: uuid!,$note_taker: Boolean!,$captioner: Boolean!){
                                calendar_tasks(where: {assigned_id: {_eq: $uid}}) @include(if:$captioner){
                                    start_time
                                    end_time
                                    labels
                                    name
                                    progress_flag
                                    service_type
                                    status
                                    id
                                    updated_at
                                }
                                notetaking_tasks(where: {assignee: {_eq: $uid}}) @include(if:$note_taker) {
                                    actual_handling_end_time
                                    actual_handling_start_time
                                    actual_handling_time
                                    created_at
                                    course_name
                                    media_name
                                    deadline
                                    media_file_type
                                    task_status
                                    updated_at
                                    id
                                }
                            }`,
            variables: {
              uid: this.$store.state.currentUser.uid,
              note_taker:
                !!this.$store.state.currentUser.servicesProvided &&
                (this.$store.state.currentUser.servicesProvided
                  .toString()
                  .toLowerCase()
                  .includes("note_taking") ||
                  this.$store.state.currentUser.servicesProvided
                    .toString()
                    .toLowerCase()
                    .includes("live_note")),
              captioner:
                !!this.$store.state.currentUser.servicesProvided &&
                this.$store.state.currentUser.servicesProvided
                  .toString()
                  .toLowerCase()
                  .includes("live_captioning"),
            },
          });
          requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: graphql,
            redirect: "follow",
          };
          fetch(process.env.VUE_APP_HASURA_URL, requestOptions)
            .then((response) => response.text())
            .then((result) => {
              let data = JSON.parse(result);
              switch (this.$store.state.currentUser.role.toLowerCase()) {
                case "worker":
                case "service_provider":
                  this.setData({
                    captioner_data: data.data?.calendar_tasks
                      ? data.data.calendar_tasks
                      : null,
                    notetaker_data: data.data?.notetaking_tasks
                      ? data.data.notetaking_tasks
                      : null,
                  });
                  break;
                default:
                  this.dataError = true;
                  break;
              }
            })
            .catch((error) => {
              console.log(error);
              this.dataError = true;
              this.loader = false;
            });
          break;
        case "student":
        case "normal":
        case "personal":
        case "supervisor":
        case "org user":
        case "owner":
          sessionsHeaders = new Headers();
          sessionsHeaders.append("content-type", "application/json");
          sessionsHeaders.append(
            "Authorization",
            `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
          );
          sessionRequestOptions = {
            method: "GET",
            headers: sessionsHeaders,
            redirect: "follow",
          };
          fetch(
            process.env.VUE_APP_MP_SERVER_API_URL + "self/sessions/list",
            sessionRequestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              let data = JSON.parse(result);
              this.setData({ user_data: data.data });
            })
            .catch((error) => {
              console.log(error);
              this.dataError = true;
              this.loader = false;
            });
          break;
        default:
          this.dataError = true;
          this.loader = false;
          return;
      }
    },
    setTitleChangeModal(val) {
      this.titleChangeModalVisible = val;
    },
  },
  created() {
    switch (this.$store.state.currentUser.role.toLowerCase().trim()) {
      case "worker":
      case "service_provider":
        if (
          (this.$store.state.currentUser.servicesProvided &&
            this.$store.state.currentUser.servicesProvided
              .toString()
              .toLowerCase()
              .includes("live_captioning")) ||
          this.$store.state.currentUser.team
            .toLowerCase()
            .includes("captioning")
        ) {
          this.columns = [
            //TODO: A11y for columns and icon type
            { field: "type", header: "Type", class: "small" },
            { field: "name", header: "Title", sortable: true, filter: true },
            { field: "date", header: "Booking Time and Date", sortable: true },
            {
              field: "lastModified",
              header: "Last modified",
              sortable: true,
              dataType: "date",
            },
            {
              field: "status",
              header: "Notes Status",
              sortable: true,
              filter: true,
            },
            { field: "num", header: "Options", icon: true, class: "small" },
          ];
          this.FilterFields = ["name", "date", "lastModified", "status"];
          if (
            (this.$store.state.currentUser.servicesProvided &&
              this.$store.state.currentUser.servicesProvided
                .toString()
                .toLowerCase()
                .includes("live_note")) ||
            (this.$store.state.currentUser.servicesProvided &&
              this.$store.state.currentUser.servicesProvided
                .toString()
                .toLowerCase()
                .includes("note_taking"))
          ) {
            this.columns.splice(2, 0, {
              field: "dueDate",
              header: "Due Date and Time",
              sortable: true,
            });
            this.FilterFields.splice(1, 0, "dueDate");
          }
        } else if (
          (this.$store.state.currentUser.servicesProvided &&
            this.$store.state.currentUser.servicesProvided
              .toString()
              .toLowerCase()
              .includes("live_note")) ||
          (this.$store.state.currentUser.servicesProvided &&
            this.$store.state.currentUser.servicesProvided
              .toString()
              .toLowerCase()
              .includes("note_taking"))
        ) {
          this.columns = [
            { field: "type", header: "Type", class: "small" },
            { field: "name", header: "Title", sortable: true, filter: true },
            { field: "dueDate", header: "Due Date and Time", sortable: true },
            { field: "date", header: "Booking Time and Date", sortable: true },
            {
              field: "lastModified",
              header: "Last modified",
              sortable: true,
              dataType: "date",
            },
            {
              field: "status",
              header: "Notes Status",
              sortable: true,
              filter: true,
            },
            { field: "num", header: "Options", icon: true, class: "small" },
          ];
          this.FilterFields = [
            "name",
            "dueDate",
            "date",
            "lastModified",
            "status",
          ];
        }
        break;
      case "student":
      case "normal":
      case "personal":
      case "supervisor":
      case "owner":
      default:
        this.columns = [
          { field: "type", header: "Type", class: "small" },
          { field: "name", header: "Title", sortable: true, filter: true },
          {
            field: "lastModified",
            header: "Last modified",
            sortable: true,
          },
          {
            field: "status",
            header: "Notes Status",
            sortable: true,
            filter: true,
          },
          { field: "num", header: "Options", icon: true, class: "small" },
        ];
        this.FilterFields = ["name", "date", "lastModified", "status"];
    }
  },
  mounted() {
    FilterService.register(FILTER_DATE, (value, filter) => {
      if (filter === undefined || filter === null || filter.length === 0) {
        return true;
      }
      if (value === undefined || value === null) {
        return false;
      }
      if (filter.toString().toLowerCase().includes("all")) return true;
      else {
        switch (filter.toString().toLowerCase()) {
          case "today":
            return (
              this.FilterToday.toDateString() === new Date(value).toDateString()
            );
          case "tomorrow":
            var tomorrow = this.FilterToday.getTime() + 86400000;
            return (
              new Date(tomorrow).toDateString() ===
              new Date(value).toDateString()
            );
          case "nextweek":
            var week = this.FilterToday.getTime() + 604800000;
            return this.filterDate(
              this.FilterToday,
              new Date(week),
              new Date(value)
            );
          case "custom":
            if (!this.DateFrom && !this.DateTo) return false;
            else
              return this.filterDate(
                this.DateFrom,
                this.DateTo,
                new Date(value)
              );
          default:
            return false;
        }
      }
    });
    FilterService.register(FILTER_STATUS, (value, filter) => {
      if (filter === undefined || filter === null || filter.length === 0) {
        return true;
      }
      if (value === undefined || value === null) {
        return false;
      }
      if (filter.toString().toLowerCase().includes("all")) return true;
      else return filter.toString().toLowerCase().includes(value.toLowerCase());
    });
    FilterService.register(FILTER_TYPE, (value, filter) => {
      if (filter === undefined || filter === null || filter.length === 0) {
        return true;
      }
      if (value === undefined || value === null) {
        return false;
      }
      if (filter.toString().toLowerCase().includes("all")) return true;
      else
        return value
          .toString()
          .toLowerCase()
          .includes(filter.toString().toLowerCase());
    });
    switch (this.$store.state.currentUser.role.toLowerCase().trim()) {
      case "worker":
      case "service_provider":
        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        myHeaders.append(
          "x-hasura-admin-secret",
          process.env.VUE_APP_HASURA_ADMIN_SECRET
        );
        var graphql = JSON.stringify({
          query: `query GetSessionsInfo($uid: uuid!,$note_taker: Boolean!,$captioner: Boolean!){
                                calendar_tasks(where: {assigned_id: {_eq: $uid}}) @include(if:$captioner){
                                    start_time
                                    end_time
                                    labels
                                    name
                                    progress_flag
                                    service_type
                                    status
                                    id
                                    updated_at
                                }
                                notetaking_tasks(where: {assignee: {_eq: $uid}}) @include(if:$note_taker) {
                                    actual_handling_end_time
                                    actual_handling_start_time
                                    actual_handling_time
                                    created_at
                                    course_name
                                    media_name
                                    deadline
                                    media_file_type
                                    task_status
                                    updated_at
                                    id
                                }
                            }`,
          variables: {
            uid: this.$store.state.currentUser.id,
            note_taker:
              !!this.$store.state.currentUser.servicesProvided &&
              (this.$store.state.currentUser.servicesProvided
                .toString()
                .toLowerCase()
                .includes("note_taking") ||
                this.$store.state.currentUser.servicesProvided
                  .toString()
                  .toLowerCase()
                  .includes("live_note")),
            captioner:
              !!this.$store.state.currentUser.servicesProvided &&
              this.$store.state.currentUser.servicesProvided
                .toString()
                .toLowerCase()
                .includes("live_captioning"),
          },
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: graphql,
          redirect: "follow",
        };
        fetch(process.env.VUE_APP_HASURA_URL, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            let data = JSON.parse(result);
            switch (this.$store.state.currentUser.role.toLowerCase()) {
              case "worker":
              case "service_provider":
                this.setData({
                  captioner_data: data.data?.calendar_tasks
                    ? data.data.calendar_tasks
                    : null,
                  notetaker_data: data.data?.notetaking_tasks
                    ? data.data.notetaking_tasks
                    : null,
                });
                break;
              default:
                this.dataError = true;
                break;
            }
          })
          .catch((error) => {
            console.log(error);
            this.dataError = true;
            this.loader = false;
          });
        break;
      case "student":
      case "normal":
      case "personal":
      case "supervisor":
      case "org user":
      case "owner":
        var sessionsHeaders = new Headers();
        sessionsHeaders.append("content-type", "application/json");
        sessionsHeaders.append(
          "Authorization",
          `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
        );
        var sessionRequestOptions = {
          method: "GET",
          headers: sessionsHeaders,
          redirect: "follow",
        };
        fetch(
          process.env.VUE_APP_MP_SERVER_API_URL + "self/sessions/list",
          sessionRequestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            let data = JSON.parse(result);
            this.setData({ user_data: data.data });
          })
          .catch((error) => {
            console.log(error);
            this.dataError = true;
            this.loader = false;
          });
        break;
      default:
        this.dataError = true;
        this.loader = false;
        return;
    }
  },
};
</script>

<style lang="scss">
.p-input-icon-left,
.p-input-icon-right {
  > i {
    margin-top: unset;
    transform: translateY(-50%);
  }
}

.session-table {
  margin-bottom: 2.4rem;

  .p-datatable-header {
    border-radius: 6px 6px 0 0;
    background-color: map-get($colours, accent-purple-3);
    border: unset;

    .p-input-icon-left > i:first-of-type {
      color: map-get($colours, white);
    }

    .p-inputtext {
      width: 100%;
      height: 40px;
      border: unset;
      outline: unset;
      border-radius: unset;
      background: map-get($colours, accent-purple-2);
      color: map-get($colours, white);

      &:focus,
      &:focus-visible {
        box-shadow: unset;
      }

      &::placeholder {
        color: map-get($colours, grey-1);
      }
    }
  }

  .p-datatable-wrapper {
    border-radius: 6px 6px 0 0;
    overflow: auto;

    .p-datatable-table {
      min-width: 100rem;
      width: 100%;
      table-layout: auto;
    }

    .p-datatable-thead {
      .p-sortable-column {
        &:hover {
          color: map-get($colours, white);
        }

        .p-column-header-content {
          justify-content: space-between;
          min-width: max-content;

          .p-sortable-column-icon {
            color: map-get($colours, white);
          }
        }
      }

      tr {
        th {
          color: map-get($colours, white);
          background-color: map-get($colours, accent-purple-3);
          border-color: map-get($colours, translucent-white-15);
          padding: 1.6rem;
          text-transform: uppercase;
          font-weight: 600;
          @include section-header-3;

          &.small {
            width: 7.5rem;
          }
        }
      }
    }

    .p-datatable-tbody {
      tr {
        &:hover {
          td {
            background-color: map-get($colours, accent-purple-3);
          }
        }

        td {
          color: map-get($colours, white);
          background-color: map-get($colours, accent-purple-4);
          border-color: map-get($colours, translucent-white-15);
          padding: 1.6rem;
          @include body-2;

          .cell-content {
            min-width: 20rem;
          }
        }
        &.p-datatable-emptymessage {
          td {
            background-color: map-get($colours, accent-purple-2);
          }
          .empty-message {
            padding: 5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
              width: 4.8rem;
              height: 4.8rem;
            }

            div {
              text-align: center;

              p:first-of-type {
                @include h3;
              }
            }
          }
        }
      }
    }
  }

  .icon-wrapper {
    display: inline-block;
    padding: 1rem;
    border-radius: 0.8rem;
    background: map-get($colours, accent-purple-2);
    width: 4.4rem;
    height: 4.4rem;

    img {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  .p-paginator {
    border: unset;
    border-radius: 0 0 6px 6px;
    background: map-get($colours, accent-purple-4);
    padding: unset;
    height: 5.6rem;
    justify-content: end;
    align-items: center;

    .p-dropdown {
      background: unset;
      border: unset;
      box-shadow: unset;
      margin: unset;
      padding: 0rem 1.6rem;

      .p-dropdown-label {
        font-size: 1.4rem;
        padding: unset;
        align-self: center;
      }

      > * {
        color: map-get($colours, white);
      }
    }

    .p-paginator-current {
      min-width: 9.6rem;
      margin: unset;
      padding: 1.6rem;
      color: map-get($colours, white);
    }

    button.p-link {
      width: 5.6rem;
      margin: unset;
      color: map-get($colours, white);

      &:hover:not(:disabled):not(.p-disabled),
      &:focus-visible:not(:disabled):not(.p-disabled) {
        color: map-get($colours, white);
      }
    }

    > * {
      position: relative;
      overflow: visible;
    }

    > *:not(:last-child)::after {
      content: "";
      position: absolute;
      height: 5.6rem;
      width: 1px;
      top: -1.3rem;
      right: 0;
      border-right: solid 1px map-get($colours, accent-purple-3);
    }
  }
}

.badge {
  background: map-get($colours, grey-4);
  color: map-get($colours, black);
  padding: 4px 8px;
  border-radius: 8px;
  text-transform: uppercase;
  @include caption-small;
  font-weight: 600;

  &.success {
    background: map-get($colours, turquoise);
  }

  &.error {
    background: map-get($colours, red);
  }

  &.warning {
    background: map-get($colours, carmine-red);
  }

  &.progress {
    background: map-get($colours, accent-purple);
  }
}

.tag {
  font-size: 1rem;
  text-align: center;
  border-radius: 0.4rem;
  min-width: min-content;
  background-color: #fbd3ac;
  // background: map-get($colours, grey-4);
  // color: map-get($colours, black);
  color: #303030;
  padding: 4px 8px;
  text-transform: uppercase;
  @include caption-small;
  font-weight: 600;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;

  &.done,
  &.completed {
    background: #00c9af;
  }
  &.requested,
  &[class*="pending"] {
    background: rgba(255, 255, 255, 0.6);
  }
  &.error,
  &[class*="cancelled"] {
    background: #fbd5d3;
    color: #b00020;
  }
  &.no-value {
    visibility: hidden;
  }
  // &.ready {

  // }
}

.slide-enter-active {
  transition: all 0.35s ease-in;
}

.slide-leave-active {
  transition: all 0.45s cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave-from {
  max-height: 650px;
  overflow: hidden;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.session-filters-wrapper {
  margin: 4rem 0rem;
  border-radius: 12px;
  border: solid 1px map-get($colours, accent-purple-4);
  overflow: hidden;

  .filters-header {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    height: 40px;
    justify-content: stretch;

    > * {
      height: 40px;
    }

    button {
      flex: 0 1 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
      width: 140px;
      height: 100%;
      border: unset;
      outline: unset;
      background: map-get($colours, accent-purple-3);
      color: map-get($colours, white);
      border-right: solid 1px map-get($colours, accent-purple-4);

      &:focus-visible {
        background: map-get($colours, accent-purple-2);
      }

      .pi-chevron-down {
        transition: all 0.35s ease-in-out;

        &.toggled {
          transform: scaleY(-100%);
        }
      }
    }

    .filter-input {
      flex: 1 1 auto;

      > i:first-of-type {
        color: map-get($colours, white);
        font-size: 1.5rem;
        margin: unset;
        transform: translateY(-50%);
      }

      .p-inputtext {
        width: 100%;
        height: 40px;
        border: unset;
        outline: unset;
        border-radius: unset;
        background: map-get($colours, accent-purple-3);
        color: map-get($colours, white);

        &:focus,
        &:focus-visible {
          background: map-get($colours, accent-purple-2);
          box-shadow: unset;
        }

        &::placeholder {
          color: map-get($colours, grey-4);
        }
      }
    }
  }

  .filters-body {
    min-height: 450px;
    padding: 3rem;
    background: map-get($colours, accent-purple-3);
    display: flex;
    flex-wrap: wrap;
    gap: 2rem 0rem;
    border-top: solid 1px map-get($colours, accent-purple-4);

    @media screen and (max-width: 650px) {
      > div[class*="col"] {
        flex: 1 0 auto;
        width: 100%;
        padding: unset;
      }

      padding: 2.5rem;
      gap: 2rem;
    }

    .col {
      flex: 1 0 100%;
      width: 100%;
    }

    .col-1 {
      flex: 0 0 auto;
      width: 25%;
      padding: 0rem 2rem;
    }

    .col-2 {
      flex: 0 0 auto;
      width: 50%;
      padding: 0rem 2rem;
    }

    .display {
      @include body-1;
      text-transform: uppercase;
      color: map-get($colours, grey-4);
      margin-bottom: 2rem;
    }

    .checkbox-wrapper {
      display: flex;
      gap: 2rem;
      flex-wrap: wrap;

      &.status-wrapper {
        gap: 2rem 0rem;

        > * {
          flex: 0 0 50%;
        }
      }

      .checkbox {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        label {
          @include body-1;
        }
      }
    }

    .date-wrapper {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      margin-top: 2rem;

      > * {
        flex: 0 1 auto;
      }

      hr {
        flex: 0 1 auto;
        width: 2rem;
        margin: unset;
      }
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.8rem;
    }

    .multiselect-wrapper {
      width: 100%;

      .p-multiselect {
        max-width: 100%;
        width: 100%;

        .p-multiselect-label {
          color: map-get($colours, black);
        }
      }
    }
  }
}

hr {
  margin: 3.2rem auto;
  border-color: map-get($colours, grey-1);
}

.table-menu-popup {
  width: fit-content;

  &.p-menu-overlay {
    background-color: map-get($colours, accent-purple-2);
    .p-menu-separator {
      border-color: map-get($colours, accent-purple-1);
      margin-left: 1.1rem;
      margin-right: 1.1rem;
    }

    .p-menuitem {
      .p-menuitem-link {
        .p-menuitem-icon,
        .p-menuitem-text {
          @include body-2;
          color: map-get($colours, white);
        }

        &:hover,
        &:focus-visible {
          background-color: map-get($colours, accent-purple-3);
        }
      }
    }
  }
}
</style>
