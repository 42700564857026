<template>
  <div class="editor" v-if="editor">
    <!-- Will be using this in the future -->
    <!-- <div class="top-bar"></div> -->
    <div class="editor-type-header">
      <span class="editor-type-header-text">
        <img :src="CaptionWhiteIcon" />
        Caption
      </span>
      <IconButton
        :icon="DownloadGreyIcon"
        alt="Download"
        xxxsmall
        class="download-button"
        @click="saveToComputer()"
      />
    </div>
    <div
      class="editor-content-wrapper"
      :style="{
        color: fontColour,
        fontWeight: fontBold ? 'bold' : 'normal',
        fontFamily: fontFamily,
        fontSize: fontSize + 'px',
        lineHeight: lineHeight,
        letterSpacing: letterSpacing + 'px',
      }"
      ref="editorContentWrapper"
    >
      <editor-content
        :editor="editor"
        class="editor-content"
        @scroll.passive="this.handleScroll"
        ref="editor"
      />
      <div
        v-if="this.$store.state.currentUser.team !== 'DSA Instant Service Team'"
        class="resize-handle-wrapper"
        ref="resizer"
        @mousedown="initResize"
      >
        <div class="resize-handle" />
        <img
          :src="ResizeArrows"
          draggable="false"
          class="resize-arrows-image"
        />
        <img
          :src="ResizeHandle"
          draggable="false"
          class="resize-handle-image"
          :class="draggingResizeHandle ? 'dragging' : ''"
        />
        <div class="gradient" :class="isScrolledToBottom ? 'hide' : ''" />
      </div>
    </div>
    <div class="editor__status" v-if="status != 'connected'">offline</div>
    <ConfirmDialog />
    <div class="button-bar">
      <div class="left-section">
        <RoundedSquareButton
          v-if="
            this.$store.state.currentUser.team !== 'DSA Instant Service Team'
          "
          :image="ChatIcon"
          imageAlt="Chat"
          :onClick="toggleChatOpen"
          class="toggle-chat-button"
          :class="numTotalUnreadMessages > 0 ? '' : 'hide-badge'"
          v-badge="numTotalUnreadMessages"
          tall
          v-tooltip.top="'Chat'"
        />
        <RoundedSquareButton
          :image="FontFormatIcon"
          imageAlt="Font format"
          :onClick="toggleOpenMenu"
          tall
          v-tooltip.top="'Font format menu'"
        />
        <RoundedSquareButton
          :image="autoScrollMode ? AutoscrollPurpleIcon : AutoscrollBlackIcon"
          imageAlt="Autoscroll"
          :onClick="
            () => {
              this.autoScrollMode = !this.autoScrollMode;
            }
          "
          tall
          v-tooltip.top="
            autoScrollMode ? 'Turn autoscroll off' : 'Turn autoscroll on'
          "
        />
        <RoundedSquareButton
          :image="
            sttType === editorConstants.STT_TYPES.DEEPGRAM_DIARIZATION
              ? SpeakerDetectionPurpleIcon
              : SpeakerDetectionBlackIcon
          "
          imageAlt="Speaker detection"
          :onClick="toggleSpeakerDiarization"
          tall
          v-tooltip.top="'Speaker detection'"
        />
      </div>
      <div class="right-section">
        <RoundedButton
          text="Start"
          v-show="!sttInProgress"
          lessRound
          bold
          morePadding
          reverse
          @click="startSTT"
          v-tooltip.left="'Start speech-to-text audio capture'"
        />
        <div class="pause-end-timer-button" v-show="sttInProgress">
          <span class="timer"
            ><Stopwatch ref="editorStopwatch"></Stopwatch
          ></span>
          <button
            class="pause-button"
            @click="toggleSTT"
            v-tooltip="
              isSTTRunning ? 'Pause audio capture' : 'Resume audio capture'
            "
          >
            {{ this.isSTTRunning ? "Pause" : "Resume" }}
          </button>
          <div class="divider" />
          <button
            class="end-button"
            @click="endSTT"
            v-tooltip="'End current audio capture'"
          >
            End
          </button>
        </div>
      </div>
      <OverlayPanel
        ref="ffm"
        :dismissable="true"
        class="font-format-menu-panel"
      >
        <FontFormatMenu
          :changeFontColour="changeFontColour"
          :toggleFontBold="toggleFontBold"
          :changeFontFamily="changeFontFamily"
          :changeFontSize="changeFontSize"
          :parentFontSize="fontSize"
          :parentFontFamily="fontFamily"
          :changeLineHeight="changeLineHeight"
          :changeLetterSpacing="changeLetterSpacing"
          :parentLineHeight="lineHeight"
          :parentLetterSpacing="letterSpacing"
        />
      </OverlayPanel>
    </div>
    <EndSessionModal
      :showDialog="showEndSessionDialog"
      @updateShow="
        (val) => {
          this.showEndSessionDialog = val;
        }
      "
      @endSTTSession="
        () => {
          this.sttInProgress = false;
        }
      "
      ref="endSessionModalRef"
    />
  </div>
</template>

<script>
import { EditorContent } from "@tiptap/vue-3";
import { TextSelection } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import Highlight from "@tiptap/extension-highlight";
import RoundedSquareButton from "./RoundedSquareButton";
import RoundedButton from "./RoundedButton";
import FontFormatMenu from "./FontFormatMenu";
import TextEditor from "./Helpers/TextEditor";
import EndSessionModal from "./EndSessionModal";
import IconButton from "./IconButton";
import Stopwatch from "./Stopwatch";

import FontFormatIcon from "../assets/images/FontFormat.svg";
import PeaceSignIcon from "../assets/images/Peace.svg";
import ChatIcon from "../assets/images/Chat.svg";
import DictionarySearchIcon from "../assets/images/DictionarySearch.svg";
import RecordingDotRedIcon from "../assets/images/Recording-dot-red.svg";
import StopSquareRedIcon from "../assets/images/Stop-square-red.svg";
import LightbulbPurpleIcon from "../assets/images/Lightbulb-purple.svg";
import AutoscrollBlackIcon from "../assets/images/Autoscroll-black.svg";
import AutoscrollPurpleIcon from "../assets/images/Autoscroll-purple.svg";
import PauseBlackIcon from "../assets/images/Pause-black.svg";
import StopSquareBlackIcon from "../assets/images/Stop-square-black.svg";
import ArrowRightBlackIcon from "../assets/images/Arrow-right-black.svg";
import ResizeHandle from "../assets/images/ResizeHandle.svg";
import ResizeArrows from "../assets/images/ResizeArrows.svg";
import SpeakerDetectionBlackIcon from "../assets/images/Speaker-detection-black.svg";
import SpeakerDetectionPurpleIcon from "../assets/images/Speaker-detection-purple.svg";
import DownloadGreyIcon from "../assets/images/Download-grey.svg";
import CaptionWhiteIcon from "../assets/images/Caption-white.svg";

import { editorConstants, dbConstants } from "../assets/constants";
import {
  scrollToBottom,
  getArrayWithLimitedLength,
  convertRemToPixels,
  convertTimetoMilliseconds,
} from "../assets/helpers_legacy.js";
import { makeMPServerRequest } from "../assets/helpers";
import {
  getNextWhitespaceOrNotIndex,
  selectCurrentWord,
  getFilteredContentSection,
} from "../assets/editor_helpers.js";
import _ from "lodash";
import {
  createUpload,
  createMultipartUploadURL,
  uploadToS3,
  completeMultipartUpload,
} from "../assets/uploadFile.js";
import { downloadFile } from "../assets/downloadFile.js";

export default {
  extends: TextEditor,

  components: {
    EditorContent,
    RoundedSquareButton,
    FontFormatMenu,
    RoundedButton,
    EndSessionModal,
    IconButton,
    Stopwatch,
  },

  props: {
    db: Object,
    toggleChatOpen: Function,
    toggleSettingsOpen: Function,
    numTotalUnreadMessages: Number,
    username: String,
    setNumServiceProviders: Function,
    setNumStudents: Function,
    setNumOthers: Function,
    setUsers: Function,
    captionLanguage: Object,
    isInstantSession: Boolean,
    audioInputDevice: String,
    sttType: String,
    changeSttType: Function,
    bundledNoteTakingTask: Object,
  },

  computed: {
    editor() {
      return this.$store.state.editor;
    },
    editorUsers() {
      return this.editor ? this.editor.storage.collaborationCursor.users : [];
    },
  },

  data() {
    return {
      MAX_DELAY_STEP: editorConstants.MAX_DELAY_STEP,
      MAX_DELAY_MIN: editorConstants.MAX_DELAY_MIN,
      MAX_DELAY_MAX: editorConstants.MAX_DELAY_MAX,
      AUTOSPACING_TIME_INCREMENT: editorConstants.AUTOSPACING_TIME_INCREMENT,
      MIN_AUTOSPACING_TIME: editorConstants.MIN_AUTOSPACING_TIME,
      MAX_AUTOSPACING_TIME: editorConstants.MAX_AUTOSPACING_TIME,
      MAX_PAST_PARTIALS_TO_COMPARE: 5,

      ResizeHandle,
      ResizeArrows,

      indexdb: null,
      socket: null,
      device: null,

      fontColour: editorConstants.WHITE,
      fontBold: editorConstants.DEFAULT_FONT_BOLD,
      fontFamily: editorConstants.AVERTA,
      fontSize: editorConstants.DEFAULT_FONT_SIZE,
      lineHeight: editorConstants.DEFAULT_LINE_HEIGHT,
      letterSpacing: editorConstants.DEFAULT_LETTER_SPACING,

      autoScrollMode: true,
      shouldAutoScroll: this.autoScrollMode && this.isScrolledToBottom,
      isScrolledToBottom: true,
      manualSelectionChange: false,
      contentLength: null,
      shouldAddSpacer: false,
      selectionJustChanged: false,
      selectionJustChangedTimeout: null,
      autospacingTime: 5000,
      spacingTimer: null,
      isSTTRunning: false,
      detectedSpeakerChange: false,
      speakerChangeSensitivity: 0.4,
      pastPartialTranscript: "",
      stablePartialTranscript: "",
      pastPartialTranscripts: getArrayWithLimitedLength(7), // 7 should be more than enough to compare with to find a match

      maxDelay: 3,
      charactersAccumulated: 0,
      partials: false,
      wordNavigation: false,
      showEndSessionDialog: false,

      FontFormatIcon,
      PeaceSignIcon,
      ChatIcon,
      RecordingDotRedIcon,
      StopSquareRedIcon,
      LightbulbPurpleIcon,
      AutoscrollBlackIcon,
      AutoscrollPurpleIcon,
      DictionarySearchIcon,
      PauseBlackIcon,
      StopSquareBlackIcon,
      ArrowRightBlackIcon,
      SpeakerDetectionBlackIcon,
      SpeakerDetectionPurpleIcon,
      DownloadGreyIcon,
      CaptionWhiteIcon,

      streamStreaming: null,
      AudioContext: null,
      context: null,
      processor: null,
      input: null,
      globalStream: null,
      bufferSize: 2048,
      speaker: null,
      prevSpeaker: null,
      speakerChanged: false,
      deleteWrongSpeakerTag: false,
      justAddedSpeakerTag: false,
      isFinalText: false,
      numPartials: 0,

      mediaRecorder: null,
      audioCtx: null,
      recordedChunks: [],
      audioFileName: "",
      sttInProgress: false,
      deepgramKeepAlive: null,

      blob: null,

      editorConstants: editorConstants,

      draggingResizeHandle: false,
      language: this.captionLanguage.code,

      sessionCurrentName: this.$store.state.sessionDetails.title,
    };
  },

  created: function () {
    this.initPreferences();
  },

  watch: {
    editorUsers: function (users) {
      let numServiceProviders = 0;
      let numStudents = 0;
      let numOther = 0;

      // Fix for duplicate user bug with collaboration extension
      const uniqueUsers = users.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );

      this.setUsers(uniqueUsers);

      for (let i = 0; i < uniqueUsers.length; i++) {
        if (uniqueUsers[i].type === dbConstants.USER_TYPES.SERVICE_PROVIDER) {
          numServiceProviders++;
        } else if (uniqueUsers[i].type === dbConstants.USER_TYPES.STUDENT) {
          numStudents++;
        } else {
          numOther++;
        }
      }
      this.setNumServiceProviders(numServiceProviders);
      this.setNumStudents(numStudents);
      this.setNumOthers(numOther);
    },

    captionLanguage: function (language) {
      this.language = language.code;
    },

    sttType: function (newVal, oldVal) {
      if (this.isSTTRunning) {
        this.stopSTT(oldVal);
        this.startSTT();
      }
    },
  },

  mounted() {
    this.audioFileName = this.$route.params.id;
    this.audioCtx = new AudioContext();

    // When AOT enabled, show a helper popup when mouse reenters the window
    document.documentElement.addEventListener("mouseenter", () => {
      if (this.winTop) {
        this.showAOTPopup = true;
        clearTimeout(this.AOTPopupTimer);
        this.AOTPopupTimer = setTimeout(() => {
          this.showAOTPopup = false;
        }, 2000);
      }
    });
    let ref = this;

    const extensions = [Highlight];
    this.mounted(this.$store.state.sessionDetails.id, extensions, true, ref);

    if (this.isInstantSession) {
      setTimeout(() => {
        // Clear the instant session default initial content
        // this.editor.chain().focus().clearContent().run();
        this.$emit("readyToShow");
      }, 500);
    } else {
      this.$emit("readyToShow");
    }

    this.deepgramOnCloseCallback = this.deepgramOnCloseCallback.bind(this);
    this.deepgramKeepAliveCallback = this.deepgramKeepAliveCallback.bind(this);
  },

  beforeUnmount() {
    this.editor = null;
    this.stopSTT();
    this.deepgramSocket = null;
    this.mediaRecorder = null;
    this.speaker = null;
    this.numPartials = 0;
    this.$store.commit("destroyEditor");
    this.provider.destroy();
    this.beforeUnmount();
    if (this.deepgramKeepAlive) {
      clearInterval(this.deepgramKeepAlive);
    }
  },

  methods: {
    initPreferences() {
      const fontPreferences = localStorage.getItem(
        dbConstants.PREFERENCE_TABLE_NAME
      );

      if (!fontPreferences) {
        return;
      }

      if (fontPreferences[dbConstants.PREFERENCE_FONT_SIZE]) {
        this.fontSize = fontPreferences[dbConstants.PREFERENCE_FONT_SIZE];
      }

      if (fontPreferences[dbConstants.PREFERENCE_FONT_COLOUR]) {
        this.fontColour = fontPreferences[dbConstants.PREFERENCE_FONT_COLOUR];
      }

      if (fontPreferences[dbConstants.PREFERENCE_FONT_BOLD]) {
        this.fontBold = fontPreferences[dbConstants.PREFERENCE_FONT_BOLD];
      }

      if (fontPreferences[dbConstants.PREFERENCE_FONT_FAMILY]) {
        this.fontFamily = fontPreferences[dbConstants.PREFERENCE_FONT_FAMILY];
      }

      if (fontPreferences[dbConstants.PREFERENCE_LINE_HEIGHT]) {
        this.lineHeight = fontPreferences[dbConstants.PREFERENCE_LINE_HEIGHT];
      }

      if (fontPreferences[dbConstants.PREFERENCE_LETTER_SPACING]) {
        this.letterSpacing =
          fontPreferences[dbConstants.PREFERENCE_LETTER_SPACING];
      }

      if (fontPreferences[dbConstants.PREFERENCE_LANGUAGE]) {
        this.language = fontPreferences[dbConstants.PREFERENCE_LANGUAGE].code;
      }
    },

    async deepgramOnCloseCallback() {
      clearInterval(this.deepgramKeepAlive);
      if (this.deepgramSocket) {
        this.deepgramSocket.close();
      }
    },

    deepgramKeepAliveCallback() {
      this.deepgramSocket.send(JSON.stringify({ type: "KeepAlive" }));
    },

    /**
     * Text added by the editor is highlighted
     */
    handleInput(e) {
      console.log(e);
      this.editor.chain().focus().setHighlight().run();
      // const currSelHead = this.editor.view.state.selection.$head.pos;
      // const currSelAnchor = this.editor.view.state.selection.$anchor.pos;
      // if (currSelHead != currSelAnchor) {
      //   const transaction = this.editor.state.tr.insertText(e.data);
      //   this.editor.view.dispatch(transaction);
      // }
    },

    toggleOpenMenu(event) {
      this.$refs.ffm.toggle(event);
    },

    toggleAlternateSTTMenu(event) {
      this.$refs.altSTTMenu.toggle(event);
    },

    changeFontColour(hex) {
      this.fontColour = hex;

      localStorage.getItem(dbConstants.PREFERENCE_TABLE_NAME);

      localStorage.setItem(dbConstants.PREFERENCE_FONT_COLOUR, hex);
    },

    toggleFontBold() {
      this.fontBold = !this.fontBold;
      localStorage.setItem(dbConstants.PREFERENCE_FONT_BOLD, this.fontBold);
    },

    changeFontFamily(family) {
      this.fontFamily = family;
      localStorage.setItem(dbConstants.PREFERENCE_FONT_FAMILY, family);
    },

    changeFontSize(size) {
      this.fontSize = size;

      let preferences = localStorage.getItem(dbConstants.PREFERENCE_TABLE_NAME);
      preferences[dbConstants.PREFERENCE_FONT_SIZE] = size;
      localStorage.setItem(dbConstants.PREFERENCE_TABLE_NAME, preferences);
    },

    changeLineHeight(lineHeight) {
      this.lineHeight = Math.round(lineHeight * 10) / 10;
      localStorage.setItem(dbConstants.PREFERENCE_LINE_HEIGHT, lineHeight);
    },

    changeLetterSpacing(spacing) {
      this.letterSpacing = spacing;
      localStorage.setItem(dbConstants.PREFERENCE_LETTER_SPACING, spacing);
    },

    changeAutospacingTime(time) {
      this.autospacingTime = time;
    },

    changeMaxDelay(delay) {
      this.maxDelay = delay;
    },

    changeSpeakerChangeSensitivity(val) {
      this.speakerChangeSensitivity = val;
    },

    toggleSTT() {
      this.isSTTRunning ? this.stopSTT() : this.startSTT();
    },

    endSTT() {
      this.stopSTT();
      this.isSTTRunning = false;
      if (this.isInstantSession) {
        this.uploadRecording();
      }
      this.showEndSessionDialog = true;
    },

    toggleSpeakerDiarization() {
      const wasSTTRunning = this.isSTTRunning;

      if (wasSTTRunning) {
        this.stopSTT();
      }

      // Toggle between deepgram with diarization and deepgram w/o diarization
      this.changeSttType(
        this.sttType === editorConstants.STT_TYPES.DEEPGRAM_DIARIZATION
          ? editorConstants.STT_TYPES.DEEPGRAM
          : editorConstants.STT_TYPES.DEEPGRAM_DIARIZATION
      );

      if (wasSTTRunning) {
        this.$nextTick(function () {
          this.startSTT();
        });
      }
    },

    startSTT() {
      if (this.deepgramKeepAlive) {
        clearInterval(this.deepgramKeepAlive);
      }
      this.$refs.editorStopwatch.start();
      if (this.sttType.includes(editorConstants.STT_TYPES.DEEPGRAM)) {
        this.startDeepgram();
      } else if (this.sttType === editorConstants.STT_TYPES.GOOGLE) {
        this.streamAudioToGoogle();
      }
      this.isSTTRunning = true;
      this.sttInProgress = true;
    },

    stopSTT(specifiedType) {
      if (this.$refs.editorStopwatch.isRunning) {
        this.$refs.editorStopwatch.stop();
      }
      if (specifiedType) {
        if (specifiedType === editorConstants.STT_TYPES.GOOGLE) {
          this.stopGoogle();
        } else if (specifiedType.includes(editorConstants.STT_TYPES.DEEPGRAM)) {
          this.stopDeepgram();
        }
      } else if (this.sttType === editorConstants.STT_TYPES.GOOGLE) {
        this.stopGoogle();
      } else if (this.sttType.includes(editorConstants.STT_TYPES.DEEPGRAM)) {
        this.stopDeepgram();
      }
    },

    handleTranscriptData(data) {
      this.isFinaltext = false;
      let dataFinal;
      let transcript;
      let stability;
      let curSpeaker;
      // let speakerDidntChange = false;
      if (this.sttType === editorConstants.STT_TYPES.GOOGLE) {
        dataFinal = undefined || data.results[0].isFinal;
        transcript = data.results[0].alternatives[0].transcript;
        stability = data.results[0].stability;
        curSpeaker = undefined;
      } else if (this.sttType.includes(editorConstants.STT_TYPES.DEEPGRAM)) {
        dataFinal = undefined || JSON.parse(data).is_final;
        transcript = JSON.parse(data).channel.alternatives[0].transcript;
        curSpeaker = JSON.parse(data).channel.alternatives[0].words[0].speaker;
        this.numPartials++;
        if (this.numPartials < 4) {
          // Only check if speaker changed on first 3 partials
          if (curSpeaker != undefined) {
            if (curSpeaker != this.speaker) {
              // detected speaker has changed within the current partial
              if (curSpeaker === this.prevSpeaker) {
                /** detected speaker has changed back to the previous speaker so remove previously
                 *  added new speaker tag
                 */
                this.deleteWrongSpeakerTag = true;
                this.dontAddNewSpeakerTag = true;
              }

              if (this.justAddedSpeakerTag) {
                this.deleteWrongSpeakerTag = true;
                this.justAddedSpeakerTag = false;
              }
              this.speaker = curSpeaker;
              this.speakerChanged = true;
            } else {
              this.speakerChanged = false;
            }
          }
        } else {
          this.speakerChanged = false;
        }

        if (dataFinal) {
          stability = 0;
        } else {
          stability = 1;
        }
        if (JSON.parse(data).speech_final) {
          this.numPartials = 0;
          this.isFinalText = true;
        } else {
          this.isFinalText = false;
        }
      }

      // Only want to show stable results to minimize flickering
      if (stability < 0.8) {
        if (dataFinal) {
          if (this.pastPartialTranscripts.length === 0) {
            this.addPartialTranscript(transcript);
          } else {
            // compare the last 4 words
            // if all matching, do nothing
            // else,
            // loop through lastPastTranscriptWords[]
            // search lastTranscriptWords[] for lastPastTranscriptWords[i]
            // on first find, delete all from lastPastTranscriptWords[i+1] onwards
            // add lastTranscriptWords[j+1] onwards
            const transcriptWords = transcript.split(" ");
            const pastPartialWords = this.pastPartialTranscripts[0].split(" ");
            const lastWordsTranscript = transcriptWords.slice(-4);
            const lastWordsPastPartial = pastPartialWords.slice(-4);

            if (!_.isEqual(lastWordsTranscript, lastWordsPastPartial)) {
              let foundMatch = false;
              for (let i = lastWordsPastPartial.length - 1; i >= 0; i--) {
                const index = lastWordsTranscript.indexOf(
                  lastWordsPastPartial[i]
                );
                if (index != -1) {
                  let toDelete = 0;
                  for (let j = i + 1; j < lastWordsPastPartial.length; j++) {
                    toDelete += lastWordsPastPartial[j].length + 1; // +1 for space
                  }
                  let textToAdd = "";
                  for (let j = index + 1; j < lastWordsTranscript.length; j++) {
                    textToAdd += " " + lastWordsTranscript[j];
                  }
                  if (textToAdd.length > 0) {
                    if (toDelete > 0) {
                      this.deleteFromEnd(toDelete);
                    }
                    this.addPartialTranscript(textToAdd);
                  }
                  foundMatch = true;
                  break;
                }
              }
              if (!foundMatch) {
                // Just add all the words

                // If fewer than 4 words, delete all first
                if (
                  transcriptWords.length <= 4 &&
                  pastPartialWords.length <= 4
                ) {
                  this.deleteFromEnd(this.pastPartialTranscripts[0].length);
                }

                let textToAdd = "";
                for (let i = 0; i < lastWordsTranscript.length; i++) {
                  textToAdd += " " + lastWordsTranscript[i];
                }
                this.addPartialTranscript(textToAdd);
              }
            }
          }
          let { size } = this.editor.view.state.doc.content;

          const transaction = this.editor.state.tr.insertText(" ", size - 1);
          if (this.editor.view.dispatch) this.editor.view.dispatch(transaction);
          this.previousStablePartialTranscript = "";
          this.stablePartialTranscript = "";
          this.pastPartialTranscripts.length = 0;
          this.numPartials = 0;
          this.prevSpeaker = this.speaker;
          this.justAddedSpeakerTag = false;
        }
        return;
      }

      let textToAdd = "";

      if (this.pastPartialTranscripts.length > 0) {
        // No need to track subsequent duplicates
        if (transcript === this.pastPartialTranscripts[0]) {
          return;
        }
      } else {
        textToAdd = transcript;
      }

      let foundMatch = false;
      let insertMissedSpeakerTagBefore = null;

      if (!this.speakerChanged) {
        /* Go through the past partials until we find one that matches both the current
        incoming partial and the one we put most recently into the editor so that
        we can determine what has changed and only add that
       */
        for (let i = 0; i < this.pastPartialTranscripts.length; i++) {
          if (
            transcript.includes(this.pastPartialTranscripts[i]) &&
            this.pastPartialTranscripts[0].includes(
              this.pastPartialTranscripts[i]
            )
          ) {
            let toDelete =
              this.pastPartialTranscripts[0].length -
              this.pastPartialTranscripts[i].length;

            insertMissedSpeakerTagBefore =
              this.pastPartialTranscripts[0].length;

            this.deleteFromEnd(toDelete);

            textToAdd = transcript.replace(this.pastPartialTranscripts[i], "");
            foundMatch = true;
            // this.charactersAccumulated += textToAdd.length - toDelete;
            this.charactersAccumulated -= toDelete;

            break;
          }
        }
      }

      /* If we went through all the past partials and didn't find a match we can assume a
       change happened at the beginning of the partial so just swap it all out */
      if (
        (!foundMatch || this.speakerChanged) &&
        this.pastPartialTranscripts.length > 0
      ) {
        let toDelete = this.pastPartialTranscripts[0].length;

        if (this.deleteWrongSpeakerTag) {
          toDelete += 11; // to delete the previously added incorrect Speaker X tag
          console.log("delete old tag, don't add new tag");
        }

        this.deleteFromEnd(toDelete);
        this.charactersAccumulated -= toDelete;
        textToAdd = transcript;
        insertMissedSpeakerTagBefore = 0;
      }

      if (
        this.pastPartialTranscripts.length > this.MAX_PAST_PARTIALS_TO_COMPARE
      ) {
        this.pastPartialTranscripts.pop();
      }
      this.pastPartialTranscripts.unshift(transcript);

      this.addPartialTranscript(textToAdd, insertMissedSpeakerTagBefore);

      this.deleteWrongSpeakerTag = false;
      this.dontAddNewSpeakerTag = false;

      this.charactersAccumulated += textToAdd.length;
      if (
        this.charactersAccumulated >
        editorConstants.CHARACTERS_ACCUMULATED_THRESHOLD
      ) {
        // this.shouldAddSpacer = true;
        // }
      }
    },

    stopDeepgram() {
      if (this.mediaRecorder && this.mediaRecorder.state === "recording") {
        this.mediaRecorder.pause();
      }

      this.isSTTRunning = false;
      // this.deepgramSocket = null;
      // this.speaker = null;
      // this.numPartials = 0;
    },

    async micAndSystemAudio() {
      let micMediaStream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: { deviceId: { exact: "default" }, echoCancellation: true },
      });
      let desktopAudioStream = await navigator.mediaDevices.getUserMedia({
        audio: process.platform === "win32" && {
          mandatory: {
            chromeMediaSource: "desktop",
            echoCancellation: true,
          },
        },
        video: {
          mandatory: {
            chromeMediaSource: "desktop",
          },
        },
      });

      let audioCtxStreams = [
        micMediaStream,
        new MediaStream(desktopAudioStream.getAudioTracks()),
      ];
      const audioDestination = this.audioCtx.createMediaStreamDestination();
      audioCtxStreams
        .filter((t) => t.getAudioTracks().length > 0)
        .forEach((t) => {
          this.audioCtx.createMediaStreamSource(t).connect(audioDestination);
        });
      const streams = [...audioDestination.stream.getAudioTracks()];
      const combinedStreams = new MediaStream(streams);
      this.mediaRecorder = new MediaRecorder(combinedStreams, {
        mimeType: "audio/webm",
      });
    },

    async micAudioOnly() {
      await navigator.mediaDevices
        .getUserMedia({
          video: false,
          audio: { echoCancellation: true },
        })
        .then((stream) => {
          let options = {};
          if (MediaRecorder.isTypeSupported("audio/webm")) {
            options = { mimeType: "audio/webm", audioBitsPerSecond: 128000 };
          } else if (MediaRecorder.isTypeSupported("video/mp4")) {
            options = { mimeType: "video/mp4", videoBitsPerSecond: 100000 };
          } else {
            console.error("No suitable mimetype found for this device");
          }

          this.mediaRecorder = new MediaRecorder(stream, options);
        });
    },

    async systemAudioOnly() {
      await navigator.mediaDevices
        .getUserMedia({
          audio: process.platform === "win32" && {
            mandatory: {
              chromeMediaSource: "desktop",
              echoCancellation: true,
            },
          },
          video: {
            mandatory: {
              chromeMediaSource: "desktop",
            },
          },
        })
        .then((stream) => {
          this.mediaRecorder = new MediaRecorder(stream, {
            mimeType: "audio/webm",
          });
        });
    },

    async startDeepgram() {
      if (this.mediaRecorder && this.mediaRecorder.state === "paused") {
        this.mediaRecorder.resume();
      } else {
        if (process.platform === "win32") {
          if (
            this.audioInputDevice === editorConstants.INPUT_DEVICES.SYSTEM_AUDIO
          ) {
            await this.systemAudioOnly();
          } else if (
            this.audioInputDevice ===
            editorConstants.INPUT_DEVICES.DEFAULT_MICROPHONE
          ) {
            await this.micAudioOnly();
          } else {
            await this.micAndSystemAudio();
          }
        } else {
          await this.micAudioOnly();
        }

        const getTempKey = await makeMPServerRequest(
          "self/temporary-deepgram-key",
          "GET",
          "json",
          null
        );

        const queryParams = `?diarize=${
          this.sttType === editorConstants.STT_TYPES.DEEPGRAM_DIARIZATION
        }&language=${
          this.language
        }&interim_results=true&punctuate=true&smart_format=true`;

        this.deepgramSocket = new WebSocket(
          `wss://api.deepgram.com/v1/listen${queryParams}`,
          ["token", getTempKey.data.temporaryKey]
        );

        if (this.deepgramKeepAlive) {
          clearInterval(this.deepgramKeepAlive);
        }

        this.deepgramKeepAlive = setInterval(
          this.deepgramKeepAliveCallback,
          10 * 1000
        );

        this.mediaRecorder.onstop = () => {
          this.blob = new Blob(this.recordedChunks, {
            type: "audio/webm; codecs=opus",
          });
        };

        this.deepgramSocket.addEventListener("open", () => {
          this.mediaRecorder.addEventListener(
            "dataavailable",
            async (event) => {
              if (
                event.data.size > 0 &&
                this.deepgramSocket &&
                this.deepgramSocket.readyState === 1
              ) {
                this.deepgramSocket.send(event.data);
                // If this captioning session is part of a "bundled service" we need to record the audio for the upcoming note taking session
                this.recordedChunks.push(event.data);
              }
            }
          );
          this.mediaRecorder.start(1000); // 1000 is the timeslice value. MUST be 1000 for DG to accept streamed data

          this.deepgramSocket.addEventListener(
            "close",
            this.deepgramOnCloseCallback
          );
        });

        this.deepgramSocket.addEventListener("message", (message) => {
          const received = JSON.parse(message.data);
          const transcript = received.channel
            ? received.channel.alternatives[0].transcript
            : "";
          if (transcript) {
            this.handleTranscriptData(message.data);
          }
        });
      }
    },

    selectLastWordInContent() {
      const dispatch = this.editor.view.dispatch;
      const state = this.editor.state;
      let content = getFilteredContentSection(this.editor);

      let lastWordLastIndex = content.length + 1;

      let lastWordFirstIndex =
        getNextWhitespaceOrNotIndex(
          content,
          lastWordLastIndex - 2,
          true,
          true
        ) + 2;

      const selection = new TextSelection(
        state.doc.resolve(lastWordLastIndex),
        state.doc.resolve(lastWordFirstIndex)
      );
      this.manualSelectionChange = false;
      let transaction = state.tr.setSelection(selection);
      if (dispatch) dispatch(transaction.scrollIntoView());
    },

    addPartialTranscript(toInsert) {
      let currSelHead = this.editor.view.state.selection.$head.pos;
      let currSelAnchor = this.editor.view.state.selection.$anchor.pos;

      EditorView.prototype.updateState = function updateState(state) {
        if (!this.docView) return; // This prevents the matchesNode error on hot reloads
        this.updateStateInner(state, this.state.plugins != state.plugins);
      };

      let { size } = this.editor.view.state.doc.content;
      const emptyDoc = this.editor.state.doc.textContent.length === 0;
      let transaction;

      this.editor.chain().focus().unsetHighlight().run();

      if (
        this.speakerChanged &&
        !this.dontAddNewSpeakerTag &&
        this.sttType === editorConstants.STT_TYPES.DEEPGRAM_DIARIZATION
      ) {
        this.editor.commands.insertContentAt(emptyDoc ? size - 1 : size, [
          {
            type: "paragraph",
            content: [
              {
                type: "text",
                text: "Speaker " + (this.speaker + 1),
              },
              {
                type: "hardBreak",
              },
              {
                type: "text",
                text: toInsert.trim(),
              },
            ],
          },
        ]);

        this.justAddedSpeakerTag = true;
      } else {
        this.editor.commands.insertContentAt(
          this.shouldAddSpacer ? size : size - 1,
          this.shouldAddSpacer ? toInsert.trim() : toInsert
        );
      }

      if (this.shouldAddSpacer) {
        this.shouldAddSpacer = false;
        this.charactersAccumulated = 0;
      }

      // if (this.editor.view.dispatch) this.editor.view.dispatch(transaction);

      if (this.autoScrollMode) {
        if (this.$refs.editor) scrollToBottom(this.$refs.editor.$el);
      }
      // Restore selection
      const selection = new TextSelection(
        this.editor.view.state.doc.resolve(currSelAnchor),
        this.editor.view.state.doc.resolve(currSelHead)
      );
      transaction = this.editor.state.tr.setSelection(selection);
      if (this.editor.view.dispatch) this.editor.view.dispatch(transaction);
    },

    // Delete length from end of transcript
    deleteFromEnd(length) {
      let { size } = this.editor.view.state.doc.content;

      if (length > 0) {
        const startIndex = size - 1 - length < 0 ? 0 : size - 1 - length;
        const transaction = this.editor.state.tr.delete(startIndex, size - 1);
        if (this.editor.view.dispatch) this.editor.view.dispatch(transaction);
      }
    },

    enterAutoScrollMode() {
      this.autoScrollMode = true;
      // Next line is needed because the line after it triggers the selection change event handler
      this.manualSelectionChange = false;
      this.editor.chain().focus().run();
      this.selectLastWordInContent();
    },

    leaveAutoScrollMode() {
      this.autoScrollMode = false;
      this.editor.chain().focus().run();
    },

    toggleAutoScrollMode() {
      this.autoScrollMode && this.isScrolledToBottom
        ? this.leaveAutoScrollMode()
        : this.enterAutoScrollMode();
    },

    insertCustomTag(tag) {
      const NEWLINE_TAGS = [
        editorConstants.INSTRUCTOR_TAG,
        editorConstants.STUDENT_TAG,
      ];
      console.log(tag);
      if (NEWLINE_TAGS.includes(tag)) {
        this.editor
          .chain()
          .focus()
          .keyboardShortcut("Enter")
          .insertContent(tag + "\n")
          .run();
      } else {
        this.editor.chain().focus().insertContent(tag).run();
      }
      return true;
    },

    selectNextWord() {
      // This line should be added to all keyboard navigation altering methods to allow disabling them
      if (!this.wordNavigation) return false;
      const dispatch = this.editor.view.dispatch;
      const state = this.editor.state;
      let currIndex = Math.max(
        state.selection.$head.pos,
        state.selection.$anchor.pos
      );

      const content = getFilteredContentSection(this.editor);

      let nextWordFirstIndex = null;
      let nextWordLastIndex = null;

      if (editorConstants.WHITESPACES.includes(content.charAt(currIndex - 1))) {
        nextWordFirstIndex =
          getNextWhitespaceOrNotIndex(content, currIndex - 1, false, false) + 1;
        nextWordLastIndex =
          getNextWhitespaceOrNotIndex(
            content,
            nextWordFirstIndex,
            false,
            true
          ) + 1;
      } else {
        let indices = selectCurrentWord(
          this.editor,
          state,
          this.wordNavigation
        );
        nextWordFirstIndex = indices.first;
        nextWordLastIndex = indices.last;
      }

      const selection = new TextSelection(
        state.doc.resolve(nextWordFirstIndex),
        state.doc.resolve(nextWordLastIndex)
      );

      let transaction = state.tr.setSelection(selection);
      if (dispatch) dispatch(transaction.scrollIntoView());
      return true;
    },

    selectPreviousWord() {
      // This line should be added to all keyboard navigation altering methods to allow disabling them
      if (!this.wordNavigation) return false;
      const dispatch = this.editor.view.dispatch;
      const state = this.editor.state;
      const content = getFilteredContentSection(this.editor);

      let currIndex = Math.min(
        state.selection.$head.pos,
        state.selection.$anchor.pos
      );

      let prevWordLastIndex = null;
      let prevWordFirstIndex = null;

      // Prev char is a whitespace
      if (editorConstants.WHITESPACES.includes(content.charAt(currIndex - 2))) {
        prevWordLastIndex = getNextWhitespaceOrNotIndex(
          content,
          currIndex - 2,
          true,
          false
        );
        prevWordFirstIndex = getNextWhitespaceOrNotIndex(
          content,
          prevWordLastIndex,
          true,
          true
        );
        prevWordLastIndex = prevWordLastIndex + 2;
        prevWordFirstIndex = prevWordFirstIndex + 2;
      } else {
        let indices = selectCurrentWord(
          this.editor,
          state,
          this.wordNavigation
        );
        prevWordLastIndex = indices.first;
        prevWordFirstIndex = indices.last;
      }

      const selection = new TextSelection(
        state.doc.resolve(prevWordLastIndex),
        state.doc.resolve(prevWordFirstIndex)
      );

      let transaction = state.tr.setSelection(selection);
      if (dispatch) dispatch(transaction.scrollIntoView());

      return true;
    },

    addNextWordToSelection() {
      // This line should be added to all keyboard navigation altering methods to allow disabling them
      if (!this.wordNavigation) return false;
      const dispatch = this.editor.view.dispatch;
      const state = this.editor.state;
      const content = getFilteredContentSection(this.editor);

      let currStartIndex = Math.min(
        state.selection.$head.pos,
        state.selection.$anchor.pos
      );

      let currEndIndex = Math.max(
        state.selection.$head.pos,
        state.selection.$anchor.pos
      );

      let nextWordLastIndex;
      let nextWordFirstIndex;

      if (
        !editorConstants.WHITESPACES.includes(content.charAt(currEndIndex - 1))
      ) {
        currEndIndex =
          getNextWhitespaceOrNotIndex(content, currEndIndex, false, true) + 1;
      }
      nextWordFirstIndex =
        getNextWhitespaceOrNotIndex(content, currEndIndex, false, false) + 1;
      nextWordLastIndex =
        getNextWhitespaceOrNotIndex(content, nextWordFirstIndex, false, true) +
        1;

      const selection = new TextSelection(
        state.doc.resolve(currStartIndex),
        state.doc.resolve(nextWordLastIndex)
      );

      let transaction = state.tr.setSelection(selection);
      if (dispatch) dispatch(transaction.scrollIntoView());

      return true;
    },

    removeLastWordFromSelection() {
      // This line should be added to all keyboard navigation altering methods to allow disabling them
      if (!this.wordNavigation) return false;
      const dispatch = this.editor.view.dispatch;
      const state = this.editor.state;
      const content = getFilteredContentSection(this.editor);

      let currStartIndex = Math.min(
        state.selection.$head.pos,
        state.selection.$anchor.pos
      );

      let currEndIndex = Math.max(
        state.selection.$head.pos,
        state.selection.$anchor.pos
      );

      let lastWordFirstIndex = getNextWhitespaceOrNotIndex(
        content,
        currEndIndex - 2,
        true,
        true
      );

      let prevWordLastIndex = getNextWhitespaceOrNotIndex(
        content,
        lastWordFirstIndex,
        true,
        false
      );

      lastWordFirstIndex = lastWordFirstIndex + 2;
      prevWordLastIndex = prevWordLastIndex + 2;

      const selection = new TextSelection(
        state.doc.resolve(currStartIndex),
        state.doc.resolve(prevWordLastIndex)
      );

      let transaction = state.tr.setSelection(selection);
      if (dispatch) dispatch(transaction.scrollIntoView());

      return true;
    },

    selectCurrentWordAfterDefaultAction() {
      // This line should be added to all keyboard navigation altering methods to allow disabling them
      if (!this.wordNavigation) return false;
      const dispatch = this.editor.view.dispatch;
      const state = this.editor.state;
      let middleCursorPos = Math.floor(
        (state.selection.$head.pos + state.selection.$anchor.pos) / 2
      );

      const selection = new TextSelection(
        state.doc.resolve(middleCursorPos),
        state.doc.resolve(middleCursorPos)
      );

      let transaction = state.tr.setSelection(selection);

      if (dispatch) dispatch(transaction.scrollIntoView());

      // Set timeout so this code runs after the default action
      setTimeout(() => {
        let view = this.editor.view;
        let dispatch = view.dispatch;
        let state = view.state;

        let indices = selectCurrentWord(
          this.editor,
          state,
          this.wordNavigation
        );
        let nextWordFirstIndex = indices.first;
        let nextWordLastIndex = indices.last;

        if (nextWordLastIndex - nextWordFirstIndex === 1) {
          return;
        }

        const selection = new TextSelection(
          state.doc.resolve(nextWordFirstIndex),
          state.doc.resolve(nextWordLastIndex)
        );

        let transaction = state.tr.setSelection(selection);
        if (dispatch) {
          dispatch(transaction.scrollIntoView());
        }

        this.selectionJustChanged = true;
        clearTimeout(this.selectionJustChangedTimeout);

        this.selectionJustChangedTimeout = setTimeout(() => {
          // 300ms should be enough for the scroll event function to run
          this.selectionJustChanged = false;
        }, 300);
      }, 1);

      // False means do the default action
      return false;
    },
    /*** End of custom keybind methods ***/

    microphoneProcess(e) {
      var left = e.inputBuffer.getChannelData(0);
      // var left16 = convertFloat32ToInt16(left); // old 32 to 16 function
      var left16 = this.downsampleBuffer(left, 44100, 16000);
      this.socketIO.emit("binaryData", left16);
    },

    downsampleBuffer(buffer, sampleRate, outSampleRate) {
      if (outSampleRate == sampleRate) {
        return buffer;
      }
      if (outSampleRate > sampleRate) {
        throw "downsampling rate show be smaller than original sample rate";
      }
      let sampleRateRatio = sampleRate / outSampleRate;
      let newLength = Math.round(buffer.length / sampleRateRatio);
      let result = new Int16Array(newLength);
      let offsetResult = 0;
      let offsetBuffer = 0;
      while (offsetResult < result.length) {
        let nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
        let accum = 0,
          count = 0;
        for (
          let i = offsetBuffer;
          i < nextOffsetBuffer && i < buffer.length;
          i++
        ) {
          accum += buffer[i];
          count++;
        }

        result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
        offsetResult++;
        offsetBuffer = nextOffsetBuffer;
      }
      return result.buffer;
    },

    convertFloat32ToInt16(buffer) {
      let l = buffer.length;
      let buf = new Int16Array(l / 3);

      while (l--) {
        if (l % 3 == 0) {
          buf[l / 3] = buffer[l] * 0xffff;
        }
      }
      return buf.buffer;
    },

    // Methods for resizing the editor
    initResize() {
      window.addEventListener("mousemove", this.Resize, false);
      window.addEventListener("mouseup", this.stopResize, false);
      this.draggingResizeHandle = true;
    },
    //resize the element
    Resize(e) {
      const editor = document.querySelector(".editor-content");

      // cursor posiiton minus top of editor plus half the height of the resize handle
      editor.style.height =
        e.clientY -
        (editor.offsetTop + this.$refs.editorContentWrapper.offsetTop + 48) +
        convertRemToPixels(4 / 2) +
        "px";
    },
    //on mouseup remove windows functions mousemove & mouseup
    stopResize() {
      window.removeEventListener("mousemove", this.Resize, false);
      window.removeEventListener("mouseup", this.stopResize, false);
      this.draggingResizeHandle = false;
    },

    async stopRecorder() {
      this.mediaRecorder.stop();
      await new Promise((resolve) => setTimeout(resolve, 500));
    },

    async uploadRecording() {
      await this.stopRecorder();
      let recordingDuration = convertTimetoMilliseconds(
        this.$refs.editorStopwatch.times
      );
      let recordingDurationHMS = this.$refs.editorStopwatch.time;

      const uploadId = await createUpload({
        sessionId: this.$store.state.sessionDetails.id,
        recordingDuration,
        multipart: true,
        fileExt: "webm",
        mimeType: "audio/webm",
        uploadType: "mp-instant-session"
      });
      const multipartUploadURL = await createMultipartUploadURL({uploadId: uploadId});
      const parts = await uploadToS3(multipartUploadURL, this.blob);
      await completeMultipartUpload({uploadId, parts}).then(() => {
        localStorage.setItem("latestRecordingUploadId", uploadId);
        localStorage.setItem(
          "latestRecordingTitle",
          this.$store.state.sessionDetails.title
        );
        localStorage.setItem("latestRecordingDurationMS", recordingDuration);
        localStorage.setItem(
          "latestRecordingDurationHMS",
          recordingDurationHMS
        );
      });
    },
    saveToComputer() {
      const docHTML = this.editor.getHTML();
      let typeSuffix = "InstantSession";
      downloadFile(docHTML, this.sessionCurrentName, typeSuffix);
    },
  },
};
</script>

<style lang="scss">
$button-bar-elements-spacing: 1.6rem;
$top-bar-height: 6.4rem;
$button-bar-height: 5.6rem;
$text-formatting-bar-height: 4.5rem;
$header-height: 3.2rem;
$resize-handle-height: 3.6rem;
$border: 0.1rem solid map-get($component-colours, border-light-faint);
$panel-header-height: 3.2rem;

.editor {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 37rem;
  justify-content: space-between;

  .top-bar {
    height: $top-bar-height;
    padding: 0 2.4rem;
    border-bottom: $border;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .booking-name {
      @include section-header-3;
    }

    .section {
      display: flex;
      align-items: center;
      position: relative;
      top: 0;
      right: 0;
    }

    .p-inputswitch .p-inputswitch-slider:before {
      background-image: url("../assets/images/Pin-purple.svg");
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
      background-image: url("../assets/images/Pin-purple.svg");
    }
  }

  .editor-type-header {
    @include section-header-3;
    border-bottom: $border;
    height: $panel-header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 1.6rem;

    .editor-type-header-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.2rem;
      font-weight: bold;
      letter-spacing: 0.015rem;
      color: map-get($colours, grey-1);

      img {
        width: 1.6rem;
        height: 1.6rem;
        margin-top: -0.2rem;
        margin-right: 0.4rem;
      }
    }
  }

  .text-formatting-bar-wrapper {
    padding: 1.6rem 1.6rem 0;
    z-index: 1;
  }

  .editor-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 4.8rem;
    max-height: calc(100vh - #{$button-bar-height} - #{$header-height});

    .editor-content {
      scroll-behavior: smooth;
      overflow: auto;
      margin: 1rem 1rem 0rem 0;
      height: 100%;
      width: 100%;
      min-height: 4.8rem;

      .ProseMirror {
        height: calc(100% - 2em);
        outline: none;
        padding: 0 2.4rem 0 2.4rem;

        a {
          color: map-get($component-colours, font-colour-link-editor);
        }

        mark {
          background-color: map-get($component-colours, background-primary);
          color: map-get($component-colours, font-colour-edited);
        }

        span {
          background-color: map-get(
            $component-colours,
            background-primary
          ) !important;
        }
      }
    }

    .resize-handle-wrapper {
      cursor: ns-resize;
      position: relative;
      width: calc(
        100% - 1.6rem * 2
      ); // subtract the margins on either side of the editor
      // bottom: 0;
      height: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;
      z-index: 2;

      &:hover {
        .resize-arrows-image {
          opacity: 0;
        }

        .resize-handle-image {
          opacity: 1;
        }

        .resize-handle {
          background-color: map-get($component-colours, border-light-solid);
        }
      }

      .resize-arrows-image {
        height: 1.5rem;
        opacity: 1;
        transition: opacity 0.15s;
      }

      .resize-handle-image {
        opacity: 0;
        transition: opacity 0.15s;
        position: absolute;
        height: 2rem;
        filter: drop-shadow(0px 0.25rem 0.25rem rgba(0, 0, 0, 0.2));

        &.dragging {
          filter: drop-shadow(0px 0.25rem 0.25rem rgba(0, 0, 0, 0.2))
            brightness(70%);
        }
      }

      .resize-handle {
        width: 100%;
        height: 0.1rem;
        background-color: map-get($component-colours, border-light);
        position: absolute;
        transition: background-color 0.15s;
      }
    }

    .gradient {
      bottom: calc(2 * 1rem); // total height of resize handle including margins
      // bottom: 0;
      width: calc(100% - 2rem);
    }
  }

  .button-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem;
    padding-top: 0;

    .left-section {
      display: flex;
      align-items: center;

      > *:not(:first-child) {
        margin-left: $button-bar-elements-spacing;
      }

      .sliders-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 2rem;

        > *:not(:last-child) {
          margin-bottom: 1rem;
        }

        .slider {
          display: flex;
          flex-direction: column;
          width: 24rem;

          .slider-label {
            margin-bottom: 1rem;
            margin-top: -0.5rem;
            font-size: 1.4rem;

            .slider-value {
              font-weight: bold;
            }
          }
        }

        @media only screen and (max-width: 750px) {
          display: none;
        }
      }
    }

    .right-section {
      display: flex;

      > *:not(:first-child) {
        margin-left: $button-bar-elements-spacing;
      }

      .recording-button {
        padding: 0 2.4rem;
        height: 4rem;
        font-size: 1.4rem;
        font-weight: bold;
        background-color: map-get($colours, white);
        border-radius: 0.8rem;
        display: flex;
        align-items: center;

        .start-button {
          font-size: inherit;
          font-weight: inherit;
          height: 100%;
          width: 100%;
          background-color: inherit;
          padding: 0;
          display: flex;
          align-items: center;

          img {
            margin-right: 0.8rem;
          }
        }
      }

      .pause-end-timer-button {
        height: 4rem;
        background: map-get($colours, white);
        border-radius: 0.8rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;

        .timer {
          @include caption;
          color: map-get($colours, grey-5);
          padding: 0 0.4rem 0 1.6rem;
        }

        .divider {
          width: 0.1rem;
          background-color: map-get($colours, grey-3);
          height: 40%;
        }

        .end-button {
          padding: 0 1.6rem;
        }

        .pause-button,
        .end-button {
          padding: 0 1.6rem;
          font-weight: bold;
          height: 100%;
        }
      }
    }

    .switches {
      display: flex;

      > *:not(:first-child) {
        margin-left: $button-bar-elements-spacing;
      }

      .switch-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        .switch-label {
          font-size: 1.4rem;
          font-weight: 600;
          margin-bottom: 0.6rem;
        }

        .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
          background: map-get(
            $component-colours,
            autoscroll-switch-knob-colour-active
          ) !important;
        }

        .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
          background: map-get(
            $component-colours,
            autoscroll-switch-track-colour-active
          ) !important;
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
    color: white;
    white-space: nowrap;
    padding: 0.25rem 0.75rem;
  }
}
/* Give a remote user a caret */
.collaboration-cursor__caret {
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  word-break: normal;
  pointer-events: none;
  display: none;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  position: absolute;
  top: -1.4em;
  left: -1px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  user-select: none;
  color: #0d0d0d;
  padding: 0.1rem 0.3rem;
  border-radius: 3px 3px 3px 0;
  white-space: nowrap;
}

::selection {
  background-color: map-get(
    $component-colours,
    font-background-colour-highlighted-editor
  );
  color: map-get($component-colours, font-colour-highlighted);
}
::-moz-selection {
  color: map-get($component-colours, font-colour-highlighted);
  background-color: map-get(
    $component-colours,
    font-background-colour-highlighted-editor
  );
}

mark {
  &::selection {
    color: map-get($component-colours, font-colour-edited);
    background-color: map-get(
      $component-colours,
      font-background-colour-edited-highlighted
    );
  }
  &::-moz-selection {
    color: map-get($component-colours, font-colour-edited);
    background-color: map-get(
      $component-colours,
      font-background-colour-edited-highlighted
    );
  }
}

.editor__status {
  z-index: 2;
  bottom: 7.8rem;
  padding: 0.4rem 1rem 0;
  border-radius: 1rem 1rem 0 0;
  margin-left: 0.6rem;
  display: flex;
  position: absolute;
  background: map-get($component-colours, background-primary);
}

.p-overlaypanel.font-format-menu-panel {
  border-radius: 1.4rem;
  overflow: hidden;

  .p-overlaypanel-content {
    padding: 1rem;
    background-color: map-get($colours, accent-purple-4);

    > *:not(:first-child) {
      padding-top: 0.6rem;
    }

    > *:not(:last-child) {
      padding-bottom: 0.6rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .radio {
      padding-right: 0.5rem;
    }

    .menu-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
    }
  }
}
</style>
