<template>
  <Dialog
    :visible="props.visible"
    header="false"
    class="retry-recording-modal"
    :class="props.retryCount > RETRY_LIMIT ? 'retry-recording-modal-new-height' : ''"
    modal
  >
    <div
      class="close-wrapper"
    >
      <button
        @click="close"
        class="close"
      >
      <img
          :src="CloseGreyIcon"
          alt="Close menu"
        />
      </button>
    </div>

    <div
      class="retry-recording-modal-icon"
    >
      <img
      :src="ErrorOutline"
    />
    </div>
    
    
    <div
      class="retry-recording-modal-title"
    >
      Upload failed
    </div>

    <div
      class="retry-recording-modal-message"
    >
      We couldn’t upload your recording. Check your connection and try again.
    </div>
    <div
    v-if="props.retryCount > RETRY_LIMIT"
    class="retry-recording-modal-error-message retry-recording-modal-spacing"
    >
      {{ props.errorMessage }}
    </div>
    <div
      v-if="props.retryCount > RETRY_LIMIT"
      class="retry-recording-modal-message"
    >
      {{ "If you continue to experience issues with uploading, please reach out to our support team for assistance." }}
    </div>
    <div
      class="retry-recording-modal-button-wrapper"
    >
      <button
        @click="onClickRetry"
        class="retry-recording-modal-button"
      >
        Retry
      </button>
    </div>
    
  </Dialog>
</template>

<script setup lang="ts">
import { RETRY_LIMIT } from '../../assets/constants';

const CloseGreyIcon = require("../../assets/images/Close-grey.svg") as string;
const ErrorOutline = require("../../assets/images/ErrorOutlineRed.svg") as string;

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  setVisible: {
    type: Function,
    required: true,
  },
  retryCount: {
    type: Number,
    default: 0,
  },
  errorMessage: {
    type: String,
    default: '',
  }
})

const emit = defineEmits(["retryUpload"])

function close() {
  props.setVisible(false);
}

function onClickRetry() {
  emit("retryUpload");
}

</script>

<style lang="scss">
.retry-recording-modal {
  width: 34.2rem;
  height: 24.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .p-dialog-header {
    display: none;
  }
  
  .p-dialog-content {
    background-color: #f5dee2;
    border-radius: 0.8rem;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    flex-direction: column;
    align-items: center;
  }

  .close-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 1.6rem;
    padding-top: 0.8rem;
    .close {
      background: transparent;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  .retry-recording-modal-icon {
    display: flex;
    width: 100%;
    justify-content: center;

    img {
      width: 3.2rem;
      height: 3.2rem;
    }
  }

  .retry-recording-modal-title {
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: $font-accent;
    font-size: 2.4rem;

    margin: 1.6rem 0;
  }

  .retry-recording-modal-message {
    display: flex;
    width: auto;
    justify-content: center;
    font-family: $font-default;
    font-size: 1.6rem;
    padding: 0 3.2rem;
    text-align: center;
  }

  .retry-recording-modal-error-message {
    display: flex;
    width: auto;
    justify-content: center;
    font-family: $font-default;
    font-size: 1.6rem;
    padding: 0 3.2rem;
    text-align: center;
    color: #b00020;
  }

  .retry-recording-modal-button-wrapper {
    display: flex;
    width: auto;
    justify-content: center;
    margin-top: 3.2rem;

    .retry-recording-modal-button {
      width: 6.9rem;
      height: 3.2rem;
      border: solid 1px #19191919;
      background-color: map-get($colours, accent-purple);
      color: #f5dee2;
      border-radius: 0.8rem;
    }
  }

  .retry-recording-modal-spacing {
    margin: 1.2rem 0;
  }
}
.retry-recording-modal-new-height {
  height: 35.8rem;
}
</style>