/* 
    Uploaded file helper function used in:
    Recorder.vue 
    Upload.vue 
    Editor.vue (for Pro users to upload recorded audio from instant sessions)

    The functionality for all the above require uploading to MP server (the uploaded file actually is uploaded to S3,
        but all the MP server calls scaffold the necessary entities for a session)
    
    
    The uploadAudio method:
    creates in mp-server:
    -session record
    -upload record
    -multipart upload URL

    Then PUTs BLOB/Audio to S3
*/

import EventEmitter from "events";
export const eventEmitter = new EventEmitter();

// create a session record in mp-server
export const createSession = async ({recordingTitle}) => {
  const myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
  );
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    title: recordingTitle,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    process.env.VUE_APP_MP_SERVER_API_URL + "self/sessions",
    requestOptions
  );
  const data = await response.json();
  return data.data.id;
};

// create an upload record in mp-server to retrieve uploadId to set up multipart upload URL in S3
export const createUpload = async ({
  sessionId,
  recordingDuration,
  multipart,
  fileExt,
  mimeType,
  uploadType,
  uploadMetaDataObject,
  fileName,
  fileSize,
}) => {
  const myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
  );
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    sessionId: sessionId ?? uploadMetaDataObject.sessionId,
    fileExtension: fileExt,
    type: uploadType,
    mimeType: mimeType,
    duration: recordingDuration,
    multipart: multipart,
    title: fileName,
    fileSize: fileSize,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    process.env.VUE_APP_MP_SERVER_API_URL + "self/uploads",
    requestOptions
  );

  const data = await response.json();
  return data.data.id;
};

// create URL for an S3 multipart upload, we use this to PUT BLOB to S3
export const createMultipartUploadURL = async ({uploadId, uploadMetaDataObject}) => {
  const myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
  );
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    partNumber: 1,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    process.env.VUE_APP_MP_SERVER_API_URL +
      `self/uploads/${uploadId ?? uploadMetaDataObject.uploadId}/create-multipart-upload-url`,
    requestOptions
  );
  const data = await response.json();
  return data.data;
};

export const uploadToS3 = async (signedURL, audioBlob) => {
  const response = await fetch(signedURL, {
    method: "PUT",
    body: audioBlob,
    headers: new Headers({
      "Content-Type": "audio/webm",
    }),
  });
  const etag = response.headers.get("ETag");

  const parts = [
    {
      partNumber: 1,
      eTag: etag.replace(/['"]+/g, ""),
    },
  ];
  return parts;
};

export const completeMultipartUpload = async ({uploadId, parts, uploadMetaDataObject}) => {
  const myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
  );
  myHeaders.append("Content-Type", "application/json");

  const raw = parts ? JSON.stringify({
    parts: parts
  }) : JSON.stringify({
    parts: uploadMetaDataObject.completedUploadParts,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    process.env.VUE_APP_MP_SERVER_API_URL +
      `self/uploads/${uploadId ?? uploadMetaDataObject.uploadId}/complete-multipart-upload`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
};

export const uploadAudio = async (
  recordingTitle,
  recordingDuration,
  recordingDurationHMS,
  audioBlob,
  uploadType
) => {
  const sessionId = await createSession({recordingTitle});
  const uploadId = await createUpload(
    sessionId,
    recordingDuration,
    true,
    "webm",
    "audio/webm",
    uploadType
  );
  const multipartUploadURL = await createMultipartUploadURL(uploadId);
  const parts = await uploadToS3(multipartUploadURL, audioBlob);
  await completeMultipartUpload(uploadId, parts).then(
    () => {
      localStorage.setItem("latestRecordingUploadId", uploadId);
      localStorage.setItem("latestRecordingTitle", recordingTitle);
      localStorage.setItem("latestRecordingDurationMS", recordingDuration);
      localStorage.setItem("latestRecordingDurationHMS", recordingDurationHMS);
    }
  );
};

export const uploadDocument = async (
  fileName,
  mimeType,
  file,
  uploadType,
  sessionId
) => {
  eventEmitter.emit("uploadInProgress", true);

  const fileExtension = fileName.split(".").pop();
  eventEmitter.emit("updateProgressMessage", "Getting file ready...");

  let newSessionId;
  if (!sessionId) {
    newSessionId = await createSession({recordingTitle: fileName});
  } else {
    newSessionId = sessionId;
  }

  const uploadId = await createUpload({
    sessionId: newSessionId,
    recordingDuration: null,
    multipart: false,
    fileExt: fileExtension,
    mimeType: mimeType,
    uploadType: uploadType,
    fileName,
    fileSize: Math.floor(file.size / 1000) // convert bytes to kb
  });

  await postDocumentToS3(uploadId, file, fileName);
  eventEmitter.emit("updateProgressMessage", "Creating upload information...");
  eventEmitter.emit("uploadInProgress", false);
  eventEmitter.emit("uploadComplete");
};

export const postDocumentToS3 = async (uploadId, file, fileName) => {
  let myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
  );
  myHeaders.append("Content-Type", "application/json");
  let raw = JSON.stringify({
    fileName: fileName,
  });

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  let response = await fetch(
    process.env.VUE_APP_MP_SERVER_API_URL +
      `self/uploads/${uploadId}/create-presigned-upload-url`,
    requestOptions
  );

  const responseData = await response.json();

  if (responseData.status !== "success") {
    console.log(responseData.status);
    return;
  }

  const { presignedPostUrl } = responseData.data;
  const formData = new FormData();
  Object.keys(presignedPostUrl.fields).forEach((key) => {
    formData.append(key, presignedPostUrl.fields[key]);
  });
  formData.append("file", file);

  myHeaders = new Headers();
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("Access-Control-Allow-Credentials", true);

  requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
  };

  response = await fetch(presignedPostUrl.url, requestOptions);

  if (response.status !== 204) {
    console.log(responseData.status);
    return;
  }

  myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
  );
  myHeaders.append("Content-Type", "application/json");

  raw = JSON.stringify({
    s3Key: presignedPostUrl.fields.key,
    s3Bucket: presignedPostUrl.fields.bucket,
    status: "upload-complete",
  });

  requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  response = await fetch(
    process.env.VUE_APP_MP_SERVER_API_URL + `self/uploads/${uploadId}`,
    requestOptions
  );

  return response;
};