<template>
  <MessageScreen>
    <div class="loading-screen" v-if="errorLoading">
      <span>{{ errorLoadingMessage }}</span>
      <RoundedButton text="Go back" shadow @click="goBack" class="bottom" />
    </div>
    <div class="loading-screen" v-else>
      <span>Loading...</span>
      <ProgressSpinner class="spinner bottom" />
    </div>
  </MessageScreen>
</template>

<script>
import MessageScreen from "../components/MessageScreen.vue";
import RoundedButton from "../components/RoundedButton.vue";
import { dbConstants } from "../assets/constants";
import { toTitleCase } from "../assets/helpers_legacy";

export default {
  components: {
    MessageScreen,
    RoundedButton,
  },

  data() {
    return {
      errorLoading: false,
      errorLoadingMessage: "",
      bookingType: "",
      params: {
        id: this.$store.state.sessionDetails.id,
      },
    };
  },

  async mounted() {
    if (
      this.$store.state.currentUser.role.toLowerCase().trim() === "student" ||
      this.$store.state.currentUser.role.toLowerCase().trim() === "normal" ||
      this.$store.state.currentUser.role.toLowerCase().trim() === "personal" ||
      this.$store.state.currentUser.role.toLowerCase().trim() ===
        "supervisor" ||
      this.$store.state.currentUser.role.toLowerCase().trim() === "org user" ||
      this.$store.state.currentUser.role.toLowerCase().trim() === "owner"
    ) {
      const parsedData = this.$store.state.sessionDetails;

      const getHeaders = new Headers();
      getHeaders.append("content-type", "application/json");
      getHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );
      const getRequestOptions = {
        method: "GET",
        headers: getHeaders,
        redirect: "follow",
      };

      const mediaUploadData = parsedData.uploads.find(
        (upload) => upload.type !== "mp-supplementary"
      );


      if (
        !parsedData.uploads ||
        mediaUploadData.type === "mp-instant-session"
      ) {
        // .uploads should be undefined only for instant sessions without a recording
        // Not sure if there's a better way to determine this atm
        this.$store.commit("setSessionDetails", {
          ...this.$store.state.sessionDetails,
          isInstant: true,
          mediaUploadData: mediaUploadData,
        });

        this.$router.push({
          name: "Captioner",
        });
      } else if (mediaUploadData.type === "mp-document") {
        // Get a presigned url for the document from mp-server
        fetch(
          process.env.VUE_APP_MP_SERVER_API_URL.concat(
            "self/uploads/",
            mediaUploadData.id + "/get-presigned-url"
          ),
          getRequestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            let data = JSON.parse(result);

            this.$store.commit("setSessionDetails", {
              ...this.$store.state.sessionDetails,
              documentUrl: data.data,
              sessionType: "document",
              mediaUploadData: mediaUploadData,
            });

            this.$router.push({
              name: "NoteTaker",
            });
          });
      } else if (mediaUploadData.type === "mp-video") {
        try {
          const responsePresignedUrl = await fetch(
            process.env.VUE_APP_MP_SERVER_API_URL.concat(
              "self/uploads/",
              mediaUploadData.id + "/get-presigned-url"
            ),
            getRequestOptions,
          );
          const responseAnthill = await fetch(
            process.env.VUE_APP_MP_SERVER_API_URL.concat(
              "self/uploads/",
              mediaUploadData.id,
              "/anthill"
            ),
            getRequestOptions,
          );

          if (responsePresignedUrl.ok && responseAnthill.ok) {
            const dataPresignedUrl = await responsePresignedUrl.json();
            if (dataPresignedUrl.status === "success") {
              this.$store.commit("setSessionDetails", {
                ...this.$store.state.sessionDetails,
                videoUrl: dataPresignedUrl.data,
                sessionType: "video",
                mediaUploadData: mediaUploadData,
              });
            } else {
              throw new Error(dataPresignedUrl.message);
            }

            const dataAnthill = await responseAnthill.json();

            if (dataAnthill.status === "success") {
              this.$store.commit("setSessionDetails", {
                ...this.$store.state.sessionDetails,
                uploadDetails: dataAnthill.data,
                sessionType: "video",
                mediaUploadData: mediaUploadData,
              });

              this.$router.push({
                name: "NoteTaker",
              });
            } else {
              throw new Error(dataAnthill.message);
            }
          } else {
            throw new Error(responseAnthill, responsePresignedUrl);
          }
        } catch (error) {
          console.log(error);
          this.errorLoadingMessage = "Session is not ready for viewing yet";
          this.errorLoading = true;
          return;
        }
      } else {
        if (!mediaUploadData.id) {
          // Session doesn't have Anthill status of "Done" yet
          this.errorLoadingMessage = "Session is not ready for viewing yet";
          this.errorLoading = true;
          return;
        }

        fetch(
          process.env.VUE_APP_MP_SERVER_API_URL.concat(
            "self/uploads/",
            mediaUploadData.id,
            "/anthill"
          ),
          getRequestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            let data = JSON.parse(result);

            this.$store.commit("setSessionDetails", {
              ...this.$store.state.sessionDetails,
              uploadDetails: data.data,
              sessionType: "recording",
              mediaUploadData: mediaUploadData,
            });
            this.$router.push({
              name: "NoteTaker",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        myHeaders.append(
          "x-hasura-admin-secret",
          process.env.VUE_APP_HASURA_ADMIN_SECRET
        );
        var graphql = JSON.stringify({
          query: `query GetBookingInfo($id: uuid!) {
                    calendar_tasks_by_pk(id: $id) {
                      start_time,
                      end_time,
                      tz,
                      name,
                      service_type,
                      labels,
                      notetaking_tasks {
                        id
                      }
                    }
                    booking_labels(where: {calendar_tasks: {id: {_eq: $id}}}) {
                      id
                      course_crn
                      course_name
                      booking_labels_keywords {
                        content
                        phrases
                      }
                    }
                  }
                `,
          variables: {
            id: this.$store.state.sessionDetails.id,
          },
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: graphql,
          redirect: "follow",
        };
        fetch(process.env.VUE_APP_HASURA_URL, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            let res = JSON.parse(result);
            const noBooking = "Booking not found";
            const table = "calendar_tasks_by_pk";
            const bookingLabels = "booking_labels";

            if (res.errors) {
              if (
                res.errors[0].message.includes(
                  "invalid input syntax for type uuid"
                )
              ) {
                reject(noBooking);
              } else {
                reject("Received error: " + res.errors[0].message);
              }
            } else if (res.data[table]) {
              if (res.data[table].notetaking_tasks.length > 0) {
                this.params.bundledNoteTakingTask =
                  res.data[table].notetaking_tasks[0];
              }
              this.bookingType = res.data[table].service_type;
              this.params.bookingName =
                (res.data[table].labels["Course Code"]
                  ? res.data[table].labels["Course Code"] + ": "
                  : "") + toTitleCase(res.data[table].name);

              let startDate = new Date(res.data[table].start_time);
              let endDate = new Date(res.data[table].end_time);

              let startTime = res.data[table].start_time.substr(11, 5);
              // Strip leading 0
              if (startTime[0] === "0") {
                startTime = startTime.substring(1);
              }

              let endTime = res.data[table].end_time.substr(11, 5);
              // Strip leading 0
              if (endTime[0] === "0") {
                endTime = endTime.substring(1);
              }

              this.params.bookingEndTime = endTime;

              if (
                res.data[bookingLabels][0].booking_labels_keywords &&
                res.data[bookingLabels][0].booking_labels_keywords.length > 0
              ) {
                this.params.bookingLabelsKeywords =
                  res.data[bookingLabels][0].booking_labels_keywords[0].content;
                this.params.bookingLabelsKeywordsDictionary =
                  JSON.stringify(
                    res.data[bookingLabels][0].booking_labels_keywords[0]
                      .phrases
                  ) || JSON.stringify({ phrases: [] });
              }
              this.params.bookingLabelId = res.data[bookingLabels][0].id;
              const split = res.data[table].tz.split("/");
              if (split.length === 2) {
                this.bookingTimezone = split[1];
              } else {
                this.bookingTimezone = res.data[table].tz;
              }
              this.params.bookingTimezone = this.bookingTimezone.replaceAll(
                "_",
                " "
              );
              // Remove class that disables animations on page load
              setTimeout(() => {
                this.disableTransitions = false;
              }, 500);

              // Booking type specific settings
              // Captioning booking
              //   if (serviceType === dbConstants.SERVICE_TYPE_CAPTIONING) {
              this.params.bookingStartTime = startTime;
              //   }
              // Note-taking booking
              //   else {
              this.params.turnaroundTime = (endDate - startDate) / 3.6e6;
              this.params.bookingEndDate = endDate.toLocaleString("default", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });

              resolve("Got booking info");
            } else {
              /**
               * Couldn't find booking in calendar_tasks table, so check notetaking_tasks table
               */
              var noteTakingGraphql = JSON.stringify({
                query: `query GetNoteTakingInfo($id: uuid!) {
                    notetaking_tasks_by_pk(id: $id) {
                      calendar_tasks_id
                      media_name
                      deadline
                      tz
                      notetaking_tasks_files {
                        anthill_task_id
                      }
                    }
                  }
                `,
                variables: {
                  id: this.$store.state.sessionDetails.id,
                },
              });

              requestOptions.body = noteTakingGraphql;

              fetch(process.env.VUE_APP_HASURA_URL, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                  let res = JSON.parse(result);

                  if (res.errors) {
                    if (
                      res.errors[0].message.includes(
                        "invalid input syntax for type uuid"
                      )
                    ) {
                      reject(noBooking);
                    } else {
                      reject("Received error: " + res.errors[0].message);
                    }
                  } else if (res.data.notetaking_tasks_by_pk) {
                    this.bookingType = dbConstants.SERVICE_TYPE_NOTETAKING;
                    this.params.bundledCaptioningId =
                      res.data.notetaking_tasks_by_pk.calendar_tasks_id;
                    this.params.mediaName =
                      res.data.notetaking_tasks_by_pk.media_name;
                    this.params.deadline =
                      res.data.notetaking_tasks_by_pk.deadline;
                    this.params.timezone = res.data.notetaking_tasks_by_pk.tz;
                    this.params.anthillTranscriptId = res.data
                      .notetaking_tasks_by_pk.notetaking_tasks_files[0]
                      ? res.data.notetaking_tasks_by_pk
                          .notetaking_tasks_files[0].anthill_task_id
                      : null;
                    resolve("Got notetaking booking info");
                  } else {
                    reject(noBooking);
                  }
                });
            }
          })
          .catch(() => {
            reject("Error retrieving booking information");
          });
      })
        .then(() => {
          this.$store.commit("setSessionDetails", {
            ...this.$store.state.sessionDetails,
            ...this.params,
          });
          if (this.bookingType === dbConstants.SERVICE_TYPE_CAPTIONING) {
            console.dir(this.params);
            this.$router.push({
              name: "Captioner",
            });
          } else if (this.bookingType === dbConstants.SERVICE_TYPE_NOTETAKING) {
            this.$router.push({
              name: "NoteTaker",
            });
          }
        })
        .catch((errorMsg) => {
          this.errorLoading = true;
          this.errorLoadingMessage = errorMsg;
        });
    }
  },

  methods: {
    goBack() {
      this.$router.push("/standby");
    },
  },
};
</script>

<style></style>
