<template>
  <Dialog
    :visible="visible"
    :modal="props.modal"
    :closable="props.closable"
    :header="props.header"
    :dismissableMask="props.dismissableMask"
    :class="['dialog-wrapper', smallerTitle && 'smaller-title']"
  >
    <slot></slot>
    <div class="modal-buttons">
      <Button
        @click="cancel"
        class="modal-button light-grey"
        :label="props.buttonTextCancel"
      ></Button>
      <Button
        @click="confirm"
        :class="['modal-button', isRedButton ? 'red' : 'light-purple']"
        :label="props.buttonTextConfirm"
      >
      </Button>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { ref, defineExpose } from "vue";
let visible = ref(false);
const props = defineProps<{
  modal: boolean;
  closable?: boolean;
  dismissableMask?: boolean;
  header: string;
  buttonTextCancel: string;
  buttonTextConfirm: string;
  isRedButton?: boolean;
  smallerTitle: boolean;
}>();

/* eslint-disable-next-line no-unused-vars */
let resolvePromise: ((_value: boolean) => void) | null = null;

const show = (): Promise<boolean> => {
  visible.value = true;

  return new Promise((resolve) => {
    resolvePromise = resolve;
  });
};

const confirm = () => {
  if (resolvePromise) {
    resolvePromise(true);
  }
  visible.value = false;
};

const cancel = () => {
  if (resolvePromise) {
    resolvePromise(false);
  }
  visible.value = false;
};

defineExpose({
  show,
});
</script>
<style lang="scss">
.dialog-wrapper {
  width: 44rem;

  .p-dialog-header {
    color: white;
    font-family: "PPWoodland";
    border-top-right-radius: 1.4rem;
    border-top-left-radius: 1.4rem;
    background-color: map-get($component-colours, background-primary);

    padding: 3rem;

    display: inline-flex;
    justify-content: center;

    .p-dialog-title {
      margin-top: 2.4rem;
      font-size: 3.4rem;
    }

    .p-dialog-header-icon {
      margin-left: 4rem;
      margin-bottom: 2.5rem;
      position: absolute;
      color: #bababa;
    }
  }

  .p-dialog-content {
    color: white;
    border-bottom-left-radius: 1.4rem;
    border-bottom-right-radius: 1.4rem;
    background-color: map-get($component-colours, background-primary);
    font-family: "Manrope";
    text-align: center;
    padding: 0rem 4rem 4rem 4rem;
    p {
      font-size: 1.4rem;
    }

    .modal-buttons {
      display: inline-block;
      margin-top: 4rem;

      button {
        .modal-button {
          height: 5.6rem;
          width: 16.8rem;
          border-radius: 0.8rem;
        }

        &.light-grey {
          background-color: #d1d1d1;
          color: #474747;
        }

        &.red {
          margin-left: 2.4rem;
          background-color: #b00020;
        }

        &.light-purple {
          margin-left: 2.4rem;
          background-color: #877594;
        }
      }
    }
  }
}

.smaller-title {
  .p-dialog-header .p-dialog-title {
    font-size: 2.4rem !important;
  }
}
</style>
